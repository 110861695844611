<h2 mat-dialog-title>
  {{ 'confirm' | translate | uppercase }}
</h2>

<div mat-dialog-content>
  <p [innerHTML]="_data.message | translate"></p>
</div>

<mat-card appearance="outlined" *ngIf="_data.body as body" class="custom-dialog background-grey">
    <div *ngIf="body?.report as report">
        <div>
          <b translate>{{ 'hours reported' | translate | titlecase }}: </b>
        </div>
        <span>{{ report?.hours }}</span>
        <div>
          <b translate>{{ 'comment' | translate | titlecase }}: </b>
        </div>
        <span>{{ report?.comment }}</span>
    </div>
    <div *ngIf="body?.extend as extend">
        <div>
          <b translate>{{ 'current date' | translate | titlecase }}: </b>
        </div>
        <span
        data-testid="date-end"
        [attr.data-testvalue]="extend.date_end | date: 'shortDate'"
        >{{ extend.date_end | date: undefined : undefined : locale | titlecase }}</span>
        <div>
          <b translate>{{ 'new suggested date' | translate | titlecase }}: </b>
        </div>
        <span 
        data-testid="suggested-date-end"
        [attr.data-testvalue]="extend.suggested_date_end | date: 'shortDate'"
        >{{ extend.suggested_date_end | date: undefined : undefined : locale | titlecase }}</span>
    </div>
    <div *ngIf="body?.needsSignature">
      <section class="acceptance-section">
        <mat-checkbox color="primary" [(ngModel)]="termsAccepted">{{ 'I accept terms and conditions' | translate }}</mat-checkbox>
      </section>
    </div>
</mat-card>

<div mat-dialog-actions>
    <ng-container *ngIf="_data.type === 'info'">
      <button mat-flat-button color="primary" [disabled]="_data.body?.needsSignature ? !termsAccepted : false" (click)="save()" cdkFocusInitial>
        <ng-container *ngIf="!_data.body?.report">{{ 'ok' | translate | titlecase }}</ng-container>
      </button>
    </ng-container>

    <ng-container *ngIf="_data.type === 'question'">
      <button mat-stroked-button color="primary" (click)="onNoClick()">
        <ng-container *ngIf="!_data.body?.report">{{ 'no' | translate | titlecase }}</ng-container>
        <ng-container *ngIf="!!_data.body?.report">{{ 'decline' | translate | titlecase }}</ng-container>
      </button>
      <button mat-flat-button color="primary" [disabled]="_data.body?.needsSignature ? !termsAccepted : false" (click)="save()" cdkFocusInitial>
        <ng-container *ngIf="!_data.body?.report">{{ 'yes' | translate | titlecase }}</ng-container>
        <ng-container *ngIf="!!_data.body?.report">{{ 'accept' | translate | titlecase }}</ng-container>
      </button>
    </ng-container>
</div>