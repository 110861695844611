import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'periodNumberIntoDates'
})
export class PeriodNumberIntoDatesPipe implements PipeTransform {
  transform (period: string): Date {
    if (!period) return null

    const month = period.slice(0, 2)
    const year = '20' + period.slice(2, 4)

    return new Date(year + ', ' + month + ', 01')
  }
}
