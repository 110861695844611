<div class="layout-three-rows">

    <div class="layout-nav-1">
        <shared-main-logo></shared-main-logo>
        <div class="exit-corner">
            <button mat-icon-button color="grey" (click)="logout($event)">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>
    
    <div class="layout-content-1">
        <div class="wrapper-1 account-review-container">
            <h1 translate>Account Review in Progress</h1>
            <p translate>We are currently reviewing your account. This process can take up to 48 hours to complete. During this time, you will not have the ability to perform any actions within the platform.</p>
            <p translate>You can bypass this step if you have your personal number associated to you.
                <a href="#" (click)="goToCompleteSSN($event)">{{'validate personal number' | translate}}</a>
            </p>
            <p translate>If you have any questions or if you need immediate assistance, please contact us at <a href="mailto:support&#64;shoutly.com">support&#64;shoutly.com</a>.</p>
        </div>
    </div>
    <div class="layout-footer-1">
        <div class="wrapper">
            <div class="wrapper-2 stepper-footer"></div>
        </div>
    </div>
      
</div>