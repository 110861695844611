import { Component, Input, Output, EventEmitter } from '@angular/core'
import { TableFilterTag } from 'projects/shared-lib/src/lib/models/filters.model'

export interface ShoutlyFilterMapType {
  name: string
  icon: string
}

@Component({
  selector: 'app-selected-filters',
  templateUrl: './selected-filters.component.html',
  styleUrls: ['./selected-filters.component.scss']
})
export class SelectedFiltersComponent {
  @Input() filterTags: TableFilterTag[] = []
  @Output() updateTags: EventEmitter<TableFilterTag[]> = new EventEmitter<TableFilterTag[]>()

  // define properties of the tag depending on the tag.type
  private mapTypes: ShoutlyFilterMapType[] = [
    { name: 'org_id', icon: 'group' },
    { name: 'date', icon: 'date_range' },
    { name: 'year', icon: 'date_range' },
    { name: 'month', icon: 'date_range' },
    { name: 'from', icon: 'date_range' },
    { name: 'to', icon: 'date_range' },
    { name: 'status', icon: 'check_circle' },
    { name: 'orderby', icon: 'sort' },
    { name: 'sort', icon: 'sort' },
    { name: 'collab_status', icon: 'tune' },
    { name: 'search', icon: 'search' }
  ];

  getIcon (name: string) {
    const mapType = this.mapTypes.find(mapType => mapType.name === name)

    if (!mapType?.icon) {
      return 'miscellaneous_services'
    }
    return mapType.icon
  }

  removeFilterTag (tag: TableFilterTag) {
    const tags = this.filterTags
    const index = tags.findIndex(t => t.id === tag.id && t.type === tag.type)

    tags.splice(index, 1)
    this.onUpdateTags(tags)
  }

  removeAllFilters () {
    this.filterTags = []
    this.onUpdateTags(this.filterTags)
  }

  onUpdateTags (tags: TableFilterTag[]) {
    this.updateTags.emit(tags)
  }
}
