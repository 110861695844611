import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout'
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core'
import { Subject, map, takeUntil } from 'rxjs'
import { TableFiltersService } from '../../services/table-filters/table-filters.service'

@Component({
  selector: 'shared-table-filters-button',
  templateUrl: './table-filters-button.component.html',
  styleUrls: ['./table-filters-button.component.scss']
})
export class TableFiltersButtonComponent implements OnInit, OnChanges, OnDestroy {
  @Input() context: string = ''

  constructor (
    private tableFiltersService: TableFiltersService,
    private breakpointObserver: BreakpointObserver
  ) { }

  ngOnInit (): void {
    this.breakpointObserver.observe(Breakpoints.HandsetPortrait)
      .pipe(
        map(result => result.matches),
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: isHandset => {
          this.isHandset = isHandset
        }
      })
  }

  public showFiltersButton = false
  public isHandset = true
  private destroy$: Subject<void> = new Subject()

  ngOnChanges (changes: SimpleChanges): void {
    this.showFiltersButton = this.tableFiltersService.isContextValid(this.context)
  }

  ngOnDestroy () {
    this.destroy$.next()
    this.destroy$.complete()
  }

  svgAttrs = {
    width: '15',
    height: '15'
  }

  public showFiltersDialog (feature: string) {
    this.tableFiltersService.showFiltersDialog(feature)
  }
}
