import { Component, Input, OnInit } from '@angular/core'
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker'
import { ShoutlyGenericStatusResponse } from '../../../models/status.model'

export interface StatusChipConfig {
  slug: string
  label?: string
  caption?: string
  prefix?: string
  color?: 'red' | 'yellow' | 'orange' | 'green' | 'blue'
}

@Component({
  selector: 'shared-status-chip',
  templateUrl: './status-chip.component.html',
  styleUrls: ['./status-chip.component.scss']
})
export class StatusChipComponent {
  @Input() data: ShoutlyGenericStatusResponse
  @Input() isLoading: boolean = false
}
