import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, OnChanges, ChangeDetectorRef } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms'
import { OrganizationPayoutApiResponseV2, OrganizationPayoutApiResponseV2Paypal, OrganizationPayoutStoreV2 } from '../../models/organization.model'
import { PaypalService } from '../../services/paypal/paypal.service'
import { startWith, switchMap } from 'rxjs'

@Component({
  selector: 'shared-paypal-form',
  templateUrl: './paypal-form.component.html',
  styleUrls: ['./paypal-form.component.scss']
})
export class PaypalFormComponent implements OnInit, OnChanges {
  @Input() payout: OrganizationPayoutApiResponseV2
  @Input() validationErrors: ValidationErrors
  @Input() oauthEnabled = false // Enables the oauth flow, otherwise shows a form input
  @Output() output_form = new EventEmitter<UntypedFormGroup>()

  constructor(
    private fb: UntypedFormBuilder,
    private paypalService: PaypalService,
    private cdr: ChangeDetectorRef
  ) { }

  public isLoading = false
  public isConnected = false
  public responseError: string = null

  public linkStatus = {
    availableStatuses: [
      {
        value: -1,
        name: 'error'
      },
      {
        value: 0,
        name: 'UNLINKED'
      },
      {
        value: 1,
        name: 'LINKED'
      },
      {
        value: 2,
        name: 'PENDING_REFWINDOW'
      },
      {
        value: 3,
        name: 'PENDING_OTHER'
      }
    ],
    currentStatus: 'LINKED'
  }

  form = this.fb.group({
    paypal: this.fb.group({
      paypal_email: [{ value: '', disabled: false }, [Validators.required, Validators.email]]
    }),
    // type: ['Paypal']
  })


  ngOnInit(): void {
    this.patchValues(this.payout?.paypal)

    this.form.valueChanges
      .pipe(
        startWith(() => this.form)
      )
      .subscribe(data => this.emitForm(this.form))
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.paypal && changes.paypal.currentValue) {
      // Check if the current value of paypal is defined and has a paypal_email
      this.patchValues(changes.paypal.currentValue)
    }
    if (changes.validationErrors) {
      this.showErrorsOnForm(changes.validationErrors.currentValue)
    }
  }

  emitForm(form: UntypedFormGroup) {
    return this.output_form.emit(form)
  }

  public resetEmail() {
    const form = this.form

    form.get('paypal').get('paypal_email')
      .patchValue('')
  }

  showErrorsOnForm(validationErrors: ValidationErrors) {
    if (!validationErrors) return

    const form = this.form

    form.markAllAsTouched()

    Object.keys(validationErrors).forEach(prop => {
      const formControl = form.get(prop)
      if (formControl) {
        formControl.setErrors({
          serverError: validationErrors[prop]
        })
      }
    })
  }

  private patchValues(paypalData: OrganizationPayoutApiResponseV2Paypal) {
    const paypalEmail = paypalData && paypalData.paypal_email ? paypalData.paypal_email : ''
    this.form.get('paypal.paypal_email').patchValue(paypalEmail)
    this.isConnected = !!paypalEmail

    // Update link status based on the presence of PayPal email
    this.linkStatus.currentStatus = this.isConnected ? 'LINKED' : 'UNLINKED'
    this.cdr.detectChanges()
  }

  private getPaypalUserInfo(token) {
    this.isLoading = true
    
    return this.paypalService.getPaypalAccessTokenAndUserInfo(token)
      .pipe(
        // update organization
        switchMap((paypal_email: string) => {
          const currency = this.payout.currency
          const org_id = this.payout.org_id
          return this.paypalService.updateOrganizationsPaypal(paypal_email, currency, org_id)
        })
      )
      .subscribe({
        next: (data: OrganizationPayoutStoreV2) => {
          this.patchValues(data.paypal)
          this.linkStatus.currentStatus = 'LINKED' // Set to LINKED on successful data retrieval
        },
        error: (error) => {
          this.isLoading = false
          this.responseError = error.message
          this.linkStatus.currentStatus = 'ERROR' // Set to ERROR on data retrieval failure
          this.cdr.detectChanges()
        },
        complete: () => {
          this.isLoading = false
          this.cdr.detectChanges()
        }
      })
  }

  public async openOAuth() {
    this.isLoading = true
    this.linkStatus.currentStatus = 'PENDING_REFWINDOW' // Set when OAuth flow is initiated

    try {
      const result = await this.paypalService.initiateOAuthFlow()
      console.log('OAuth Flow Success:', result)

      if (result && result.token) {
        this.linkStatus.currentStatus = 'PENDING_OTHER' // Set after OAuth window closes
        await this.getPaypalUserInfo(result.token)
      } else {
        console.error('OAuth Flow Success but no token received')
        this.linkStatus.currentStatus = 'ERROR' // Set if OAuth succeeds but no token is received
      }
    } catch (error) {
      console.error('OAuth Flow Error:', error)
      this.linkStatus.currentStatus = 'ERROR' // Set on any error during the OAuth flow
    } finally {
      this.isLoading = false
      this.cdr.detectChanges()
    }
  }

}
