<div *ngIf="!org_billing" class="empty-slate">
    <div>
        <app-blank-slate-icon [svgPath]="'assets/UI/empty-screen/empty-transaction.svg'"></app-blank-slate-icon>
        <h3 translate>{{'Error' | translate }}</h3>
        <div>
            <span translate>{{'Failed to load settings. Try signing out and login again.'}}</span>
        </div>
    </div>
</div>

<div *ngIf="org_billing">

<form [formGroup]="form">
    <mat-card appearance="outlined">
        <mat-form-field>
            <mat-label>{{'payment term' | translate | titlecase}}</mat-label>
            <mat-select formControlName="payment_term" id="org_billing_payment_term">                
                <mat-option *ngFor="let n of numSequence(10); let i = index;" [value]="i + 1">
                    {{i + 1}} {{ i > 1 ? ('days' | translate) : ('day' | translate) }}
                </mat-option>
            </mat-select>
            <mat-error>{{form.get('payment_term').errors?.serverError}}</mat-error>
        </mat-form-field>

        <mat-form-field>
            <mat-label translate>{{'billing email' | translate | titlecase}}</mat-label>
            <input type="email" 
            matInput formControlName="email" 
            placeholder="your@email.com"
            id="org_billing_email">
            <mat-error>{{form.get('email').errors?.serverError}}</mat-error>
        </mat-form-field>
    </mat-card>

    <br>

    <mat-accordion class="shoutly-accordion-1">
        <mat-expansion-panel 
            hideToggle
            class="mat-elevation-z0 shoutly-accordion-1"
            [disabled]="form.disabled"
            [class.disabled]="form.disabled"
            id="button_select_org_billing_type_1"
            (click)="selectBillingType('pay-monthly')"
            [class.selected]="form.value.type === 'pay-monthly'"
        >
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <div class="panel-title-flex">
                        <span translate>pay-monthly</span>
                        <shared-preferred-chip *ngIf="org_billing?.type === 'pay-monthly'"></shared-preferred-chip>

                        <!-- <span class="material-icons-outlined">
                            {{form.get('type').value === 'pay-monthly' ? 'radio_button_checked': 'radio_button_unchecked'}}
                        </span> -->
                    </div>
                </mat-panel-title>
                <mat-panel-description>
                    <span translate>Pay for all collaborations each month on a specific date</span>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <div>
                <mat-form-field>
                    <mat-label translate>Billing day</mat-label>
                    <mat-select formControlName="payout_day" id="org_billing_payout_day">
                        <mat-option *ngFor="let n of numSequence(28); let i = index;" [value]="i + 1">
                            {{ 'Day' | translate }} {{i + 1}}
                        </mat-option>
                        <mat-option [value]="31">
                            {{ 'Last day of the month' | translate }}
                        </mat-option>
                    </mat-select>
                    <mat-error>{{form.get('payout_day').errors?.serverError}}</mat-error>
                </mat-form-field>
            </div>
        </mat-expansion-panel>

        <mat-expansion-panel 
            hideToggle 
            class="mat-elevation-z0"
            [disabled]="form.disabled"
            [class.disabled]="form.disabled"
            id="button_select_org_billing_type_2"
            (click)="selectBillingType('pay-as-you-go')"
            [class.selected]="form.value.type === 'pay-as-you-go'"
        >
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <div class="panel-title-flex">
                        <span translate>pay-as-you-go</span>
                        <shared-preferred-chip *ngIf="org_billing?.type === 'pay-as-you-go'"></shared-preferred-chip>

                        <!-- <span class="material-icons-outlined">
                            {{form.get('type').value === 'pay-as-you-go' ? 'radio_button_checked': 'radio_button_unchecked'}}
                        </span> -->
                    </div>
                </mat-panel-title>
                <mat-panel-description>
                    <span translate>Pay separately for each collaboration</span>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <span translate>Done, each collaboration will have its own billing date</span>
        </mat-expansion-panel>

        <mat-expansion-panel 
            hideToggle
            class="mat-elevation-z0"
            *ngIf="form.value.type == 'pay-in-advance'"
            id="button_select_org_billing_type_0"
        >
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <div class="panel-title-flex">
                        <span translate>pay-in-advance</span>
                        <shared-preferred-chip *ngIf="org_billing?.type === 'pay-in-advance'"></shared-preferred-chip>

                        <!-- <span class="material-icons-outlined">
                            {{form.get('type').value === 'pay-in-advance' ? 'radio_button_checked': 'radio_button_unchecked'}}
                        </span> -->
                    </div>
                </mat-panel-title>
                <mat-panel-description>
                    <span translate>Pay with balance in my account.</span>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <span translate>If you want to change this please contact support.</span>
        </mat-expansion-panel>

        <mat-expansion-panel 
            hideToggle
            class="mat-elevation-z0 shoutly-accordion-1"
            [disabled]="form.disabled"
            [class.disabled]="form.disabled"
            id="button_select_org_billing_type_1"
            (click)="selectBillingType('pay-weekly')"
            [class.selected]="form.value.type === 'pay-weekly'"
        >
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <div class="panel-title-flex">
                        <span translate>pay-weekly</span>
                        <shared-preferred-chip *ngIf="org_billing?.type === 'pay-weekly'"></shared-preferred-chip>

                        <!-- <span class="material-icons-outlined">
                            {{form.get('type').value === 'pay-weekly' ? 'radio_button_checked': 'radio_button_unchecked'}}
                        </span> -->
                    </div>
                </mat-panel-title>
                <mat-panel-description>
                    <span translate>Pay for all collaborations each week on a specific date</span>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <div>
                <mat-form-field>
                    <mat-label translate>Billing day</mat-label>
                    <mat-select formControlName="payout_day" id="org_billing_payout_day">
                        <mat-option *ngFor="let n of numSequence(7); let i = index;" [value]="i + 1">
                            {{ 'Day' | translate }} {{i + 1}}
                        </mat-option>
                    </mat-select>
                    <mat-error>{{form.get('payout_day').errors?.serverError}}</mat-error>
                </mat-form-field>
            </div>
        </mat-expansion-panel>

        <mat-expansion-panel 
            hideToggle
            class="mat-elevation-z0 shoutly-accordion-1"
            [disabled]="form.disabled"
            [class.disabled]="form.disabled"
            id="button_select_org_billing_type_1"
            (click)="selectBillingType('pay-monthly-weekly')"
            [class.selected]="form.value.type === 'pay-monthly-weekly'"
        >
            <mat-expansion-panel-header>

                <mat-panel-title>
                    <div class="panel-title-flex">
                        <span translate>pay-monthly-weekly</span>
                        <shared-preferred-chip *ngIf="org_billing?.type === 'pay-monthly-weekly'"></shared-preferred-chip>

                        <!-- <span class="material-icons-outlined">
                            {{form.get('type').value === 'pay-monthly-weekly' ? 'radio_button_checked': 'radio_button_unchecked'}}
                        </span> -->
                    </div>
                </mat-panel-title>
                <mat-panel-description>
                    <span translate>Pay for all collaborations each week on a specific date</span>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <div>
                <mat-form-field>
                    <mat-label translate>Billing day</mat-label>
                    <mat-select formControlName="payout_day" id="org_billing_payout_day">
                        <mat-option *ngFor="let n of numSequence(7); let i = index;" [value]="i + 1">
                            {{ 'Day' | translate }} {{i + 1}}
                        </mat-option>
                    </mat-select>
                    <mat-error>{{form.get('payout_day').errors?.serverError}}</mat-error>
                </mat-form-field>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</form>
</div>