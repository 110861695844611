<button
  mat-flat-button
  color="primary"
  [routerLink]="routerLink"
  [queryParams]="queryParams"
  [disabled]="isDisabled"
  class="shrinkable-button table-cta-button"
  [matTooltipDisabled]="!isHandset"
  [matTooltip]="label | translate | ucfirst"
  [class.handset]="isHandset"
  [matMenuTriggerFor]="menu" 
  #menuBtn="matMenuTrigger"
>
    <mat-icon *ngIf="iconName">{{ iconName }}</mat-icon>
    <mat-icon *ngIf="iconFromRegistry" [svgIcon]="iconFromRegistry"></mat-icon>
    <span *ngIf="!isHandset">{{ label | translate | uppercase }}</span>
</button>

<mat-menu #menu="matMenu">
  <ng-content></ng-content>
</mat-menu>