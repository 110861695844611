<table mat-table [dataSource]="dataSource" class="skeleton-container">
    <ng-container *ngFor="let column of displayedColumns" [matColumnDef]="column">
        <th mat-header-cell *matHeaderCellDef>
            <div class="item skeleton line"></div>
        </th>
        <td mat-cell *matCellDef="let element">
            <div class="item skeleton line" style="height: 1em"></div>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
