import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { Subject, debounceTime, takeUntil, tap } from 'rxjs'
import { TableFiltersService } from '../../services/table-filters/table-filters.service'
import { TableFilterTag } from '../../models/filters.model'

@Component({
  selector: 'shared-table-search',
  templateUrl: './table-search.component.html',
  styleUrls: ['./table-search.component.scss']
})
export class TableSearchComponent implements OnInit, OnDestroy {
  @Input() context: string = ''

  constructor(
    private tableFiltersService: TableFiltersService
  ) { }

  public isHandset: boolean = false
  private destroy$: Subject<void> = new Subject()
  public placeholder: string = ''

  ngOnInit(): void {

    if (this.context) {
      const searchTerms = this.tableFiltersService.fetchSearchableTerms(this.context)
      if (searchTerms && searchTerms.length > 0) {
        this.placeholder = `Search by ${searchTerms.join(', ')}`
      }
    }

    this.searchInput$
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(500),
        tap((searchInput: string) => this.onSearchEvent(searchInput))
      )
      .subscribe({
        next: () => {
          this.searchInput = ''
        }
      })
  }

  ngOnDestroy() {
    this.destroy$.next()
    this.destroy$.complete()
  }

  public searchInput: string
  public searchInput$ = new Subject<string>()

  public clear(): void {
    this.searchInput = ''
    this.searchInput$.next(this.searchInput)
  }

  public search(): void {
    if (!this.searchInput) return
    this.searchInput$.next(this.searchInput)
  }

  private onSearchEvent(event: string) {
    if (!event) return this.tableFiltersService.deleteTagByType('search')

    const tags: TableFilterTag = {
      id: event,
      name: event,
      type: 'search'
    }

    this.tableFiltersService.insertSearchTag(tags)
  }
}
