import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout'
import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { Params } from '@angular/router'
import { Subject, map, takeUntil } from 'rxjs'

@Component({
  selector: 'shared-table-cta',
  templateUrl: './table-cta.component.html',
  styleUrl: './table-cta.component.scss'
})
export class TableCtaComponent implements OnInit, OnDestroy{
  @Input() label: string = ''
  @Input() routerLink: string
  @Input() queryParams: Params
  @Input() isDisabled: boolean = false
  @Input() iconName?: string
  @Input() iconFromRegistry?: string

  public isHandset = true
  private destroy$: Subject<void> = new Subject()

  constructor (
    private breakpointObserver: BreakpointObserver
  ){}
  
  ngOnInit(): void {
    this.breakpointObserver.observe(Breakpoints.HandsetPortrait)
    .pipe(
      map(result => result.matches),
      takeUntil(this.destroy$)
    )
    .subscribe({
      next: isHandset => {
        this.isHandset = isHandset
      }
    })
  }

  ngOnDestroy (): void {
    this.destroy$.next()
    this.destroy$.complete()
  }
  
}
