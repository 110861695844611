<div *ngIf="isScriptLoaded" class="container">
    <div [attr.fs-cc]="'banner'" [attr.fs-cc-display]="'block'" class="cookie-banner banner">
        <div>
            <div class="secondary-text legal" translate>
                By clicking <b>“Allow All”</b>, you agree to the storing of cookies on your device to enhance site
                navigation, analyze site usage, and assist in our marketing efforts. View our <a class="link">Privacy
                    Policy</a> for more information.
            </div>
            <div class="buttons-wrapper">
                <button mat-flat-button [attr.fs-cc]="'open-preferences'" (click)="openDialog()">
                    <span>Preferences</span>
                    <mat-icon matSuffix>settings</mat-icon>
                </button>
                <button mat-flat-button color="primary" [attr.fs-cc]="'allow'">Allow All</button>
                <button mat-icon-button [attr.fs-cc]="'close'">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </div>
    </div>
</div>

<div [attr.fs-cc]="'manager'" class="manager">
    <button mat-fab color="primary" class="floating-cookie" (click)="openDialog()"
        aria-label="Cookie preferences button">
        <span class="material-icons-outlined">cookie</span>
    </button>
</div>

<section [attr.fs-cc]="'preferences'" class="preferences">
    <ng-template #preferencesPortal>

        <form id="cookie-preferences" name="wf-form-Cookie-Preferences" data-name="Cookie Preferences" method="get"
            aria-label="Cookie Preferences">

            <button class="close-button" mat-fab color="primary" [attr.fs-cc]="'close'"
                (click)="$event.stopPropagation()">
                <mat-icon>close</mat-icon>
            </button>

            <div class="content">
                <p class="secondary-text">When you visit websites, they may store or retrieve data in your browser.
                    This storage is often necessary for the basic functionality of the website. The storage may be used
                    for marketing, analytics, and personalization of the site, such as storing your preferences. Privacy
                    is important to us, so you have the option of disabling certain types of storage that may not be
                    necessary for the basic functioning of the website. Blocking categories may impact your experience
                    on the website.</p>

                <div class="elements">
                    <button mat-stroked-button color="primary" [attr.fs-cc]="'deny'" translate>Reject All</button>
                    <button mat-flat-button color="primary" [attr.fs-cc]="'allow'" translate>Allow All</button>
                </div>

                <h2 translate>Manage Consent Preferences by Category</h2>

                <div class="option">
                    <div class="toggle-wrapper">
                        <h3 translate>Essential</h3>
                        <div class="mat-body-1"><strong>Always Active</strong></div>
                    </div>
                    <div class="secondary-text">These items are required to enable basic website functionality.</div>
                </div>

                <div class="option">
                    <div class="toggle-wrapper">
                        <h3 translate>Marketing</h3>
                        <label class="w-checkbox">
                            <mat-checkbox color="primary" (click)="$event.stopPropagation()"
                                [attr.fs-cc-checkbox]="'marketing'" [attr.data-name]="'Marketing'" id="marketing"
                                name="marketing"></mat-checkbox>
                        </label>
                    </div>
                    <p class="secondary-text" translate>These items are used to deliver advertising that is more
                        relevant
                        to you and your interests. They may also be used to limit the number of times you see an
                        advertisement and
                        measure the effectiveness of advertising campaigns. Advertising networks usually place them with
                        the
                        website operator’s permission.</p>
                </div>

                <div class="option">
                    <div class="toggle-wrapper">
                        <h3 translate>Personalization</h3>
                        <label class="w-checkbox">
                            <mat-checkbox color="primary" (click)="$event.stopPropagation()"
                                [attr.fs-cc-checkbox]="'personalization'" [attr.data-name]="'Personalization'"
                                id="personalization" name="personalization"></mat-checkbox>
                        </label>
                    </div>
                    <p class="secondary-text">These items allow the website to remember choices you make (such as your
                        user name, language, or the region you are in) and provide enhanced, more personal features. For
                        example, a website may provide you with local weather reports or traffic news by storing data
                        about
                        your current location.</p>
                </div>

                <div class="option">
                    <div class="toggle-wrapper">
                        <h3 translate>Analytics</h3>
                        <label class="w-checkbox">
                            <mat-checkbox color="primary" (click)="$event.stopPropagation()"
                                [attr.fs-cc-checkbox]="'analytics'" [attr.data-name]="'Analytics'" id="analytics"
                                name="analytics"></mat-checkbox>
                        </label>
                    </div>
                    <p class="secondary-text">These items help the website operator understand how its website performs,
                        how visitors interact with the site, and whether there may be technical issues. This storage
                        type
                        usually doesn’t collect information that identifies a visitor.</p>
                </div>

                <div class="confirm-button-wrapper">
                    <button mat-flat-button color="primary" [attr.fs-cc]="'submit'" translate>Confirm my preferences and
                        close</button>
                </div>
            </div>
        </form>

        <div [attr.fs-cc]="'close'" class="overlay"></div>
    </ng-template>
</section>