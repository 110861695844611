import { Injectable } from '@angular/core'
import { JwtHelperService } from '@auth0/angular-jwt'
import { ApiService } from '../api/api.service'
import { BehaviorSubject, Observable, from, fromEvent, interval, merge, of, debounceTime, throttleTime, tap, filter, take, switchMap } from 'rxjs'
import { MatDialog } from '@angular/material/dialog'
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker'
import { Router } from '@angular/router'
import { APITokenRefreshResponse } from '../../models/token.model'
import { EncryptStorage } from 'encrypt-storage'
import { CommonConfirmDialogBoxComponent } from '../../dialogs/common-confirm-dialog-box/common-confirm-dialog-box.component'

type StorageType = 'localStorage' | 'sessionStorage'

export const TOKEN_CONFIG = {
  SECRET_KEY: '1234567890',
  BUFFER_IN_MINUTES: 30,
  INACTIVITY_IN_MINUTES: 30,
  ACTIVITY_CHECK_INTERVAL_SECONDS: 2,
  POPUP_TIMEOUT_SECONDS: 30,
  STORAGE_PREFIX: 'jwt',
  STORAGE_TYPE: 'sessionStorage' as StorageType
}

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  constructor (
    private api: ApiService,
    private jwtHelper: JwtHelperService,
    private dialog: MatDialog,
    private router: Router
  ) {
    this.encryptedStorage = new EncryptStorage(TOKEN_CONFIG.SECRET_KEY, {
      prefix: TOKEN_CONFIG.STORAGE_PREFIX,
      storageType: TOKEN_CONFIG.STORAGE_TYPE
      });
  }

  private buffer_in_minutes = 58 // Time before expiration that enables refresh
  private activityEvents$ = new BehaviorSubject<boolean>(true)
  public encryptedStorage: EncryptStorage

  private storageGetItem<T> (key: string): T | null {
    const res = this.encryptedStorage.getItem<T>(key)
    return res
  }

  private storageSetItem (key: string, value: any): void {
    this.encryptedStorage.setItem(key, value)
  }

  private storageRemoveItem (key: string): void {
    this.encryptedStorage.removeItem(key)
  }

  public setJwtToken (token: string) {
    this.storageSetItem('jwt_token', token)
  }

  public refreshToken (): Observable<APITokenRefreshResponse> {
    return this.api.refreshToken()
  }

  public removeToken () {
    return this.storageRemoveItem('jwt_token')
  }
  
  public getToken () {
    const token = this.storageGetItem('jwt_token')
    return token
  }

  public hasToken () {
    return this.storageGetItem('jwt_token') !== null
  }

  public isTokenExpired (token) {
    return this.jwtHelper.isTokenExpired(token)
  }

  public hasValidToken () {
    // get token
    const token = this.storageGetItem<string>('jwt_token')

    // check if token is expired
    const result = !this.jwtHelper.isTokenExpired(token)

    return result
  }

  /**
 * @return Promise<boolean> Need to refresh the token
 * @param token JWT token
 * @param buffer_in_minutes Time before expiration that enables refresh
 */
  private async refreshNeeded (token): Promise<boolean> {
    const buffer_in_minutes = this.buffer_in_minutes
    const buffer_in_seconds = buffer_in_minutes ? buffer_in_minutes * 60 : 0
    const isTokenExpiredWithBuffer = await this.jwtHelper.isTokenExpired(token, buffer_in_seconds)
    return isTokenExpiredWithBuffer
  }
  
  initActivityListener(): void {
    const events = ['mousemove', 'mousedown', 'keydown', 'wheel'];
    const activityObservable = merge(
      ...events.map(eventName => fromEvent(document, eventName))
    ).pipe(
      throttleTime(TOKEN_CONFIG.ACTIVITY_CHECK_INTERVAL_SECONDS * 1000),
      tap(() => this.activityEvents$.next(true))
    );

    activityObservable
      .pipe(
        filter(() => this.hasValidToken()),
        switchMap(() => from(this.refreshNeeded(this.getToken()!))),
        switchMap(refreshNeeded => refreshNeeded ? this.refreshToken() : of(null)),
        filter(data => data !== null)
      )
      .subscribe(data => this.setJwtToken(data!.token));

    this.activityEvents$
      .pipe(
        debounceTime(TOKEN_CONFIG.INACTIVITY_IN_MINUTES * 60 * 1000),
        filter(active => active)
      )
      .subscribe(() => {
        if (this.hasValidToken()) {
          this.showInactivityDialog();
        }
      });
  }

  private showInactivityDialog(): void {
    const title = 'Are you still there?';
    let remainingTime = TOKEN_CONFIG.POPUP_TIMEOUT_SECONDS;

    const updateMessage = () => `The session is going to expire. Do you want to stay logged in? (${remainingTime}s)`;

    const dialogRef = this.dialog.open(CommonConfirmDialogBoxComponent, {
      width: '300px',
      data: { title, message: updateMessage() }
    });

    const timer$ = interval(1000).pipe(take(TOKEN_CONFIG.POPUP_TIMEOUT_SECONDS)).subscribe(() => {
      remainingTime -= 1;
      dialogRef.componentInstance.data.message = updateMessage();

      if (remainingTime <= 0) {
        dialogRef.close(false);
      }
    });

    dialogRef.afterClosed()
      .pipe(
        tap(() => timer$.unsubscribe())
      )
      .subscribe(result => {
        if (result) {
          this.activityEvents$.next(true);
        } else {
          this.router.navigate(['/auth/logout']);
        }
      });
  }
}
