import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core'
import { FormBuilder, FormControl, FormGroup } from '@angular/forms'

interface FormOption {
  key: string
  value: string
  icon: string
  disabled?: boolean // Optional property to allow dynamic disabling
}

@Component({
  selector: 'shared-form-select',
  templateUrl: './form-select.component.html',
  styleUrl: './form-select.component.scss'
})

export class FormSelectComponent implements OnInit, OnChanges {
  @Input() patchValue: string | null = null
  @Input() disableOptions: { [key: string]: boolean } = {}
  @Input() isError: boolean = false
  @Output() valueChange = new EventEmitter<string>()

  form: FormGroup

  public formList: FormOption[] = [
    {
      key: 'Business',
      value: 'Business',
      icon: 'business',
    },
    {
      key: 'Private',
      value: 'Private',
      icon: 'account_circle',
    }
  ];

  constructor(
    private fb: FormBuilder
  ) {
    this.form = this.fb.group({
      selectOption: new FormControl({ value: '', disabled: false }) // Initialize with no value
    })
  }

  ngOnInit(): void {
    if (this.patchValue) {
      this.form.patchValue({ selectOption: this.patchValue })
    }

    this.form.get('selectOption')?.valueChanges
      .subscribe(value => {
        this.valueChange.emit(value)
      })

    // Initial check for disable state
    this.updateControlState()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.disableOptions || changes.patchValue) {
      this.updateControlState()
    }
  }

  private updateControlState(): void {
    const selectControl = this.form.get('selectOption')
    if (selectControl) {
      // Check for disabling based on the disableOptions input
      const currentValue = selectControl.value
      if (this.disableOptions[currentValue]) {
        selectControl.disable({ emitEvent: false })
      } else {
        selectControl.enable({ emitEvent: false })
      }
    }
  }

  public trackByFn(index: number, item: FormOption): string {
    return item.key
  }
}