<div class="container" class="input-file-box" [class.disabled]="files.length >= maxFiles">
	<div 
	*ngIf="files.length < maxFiles" 
	appDragndrop 
	(click)="fileDropRef.click()" 
	(fileDropped)="onFileDropped($event)">
		<input type="file" [hidden]="true" #fileDropRef id="fileDropRef" multiple (change)="fileBrowseHandler($event.target)" [accept]="validExtensionsComma"/>
		<div>
			{{ 'Click here or drag files to attach' | translate }} 
			<mat-icon>attachment</mat-icon>
			<br>
			<span class="secondary-text">{{ 'Max' | translate }} {{ maxFiles }} {{ 'files' | translate }} of {{ maxSizePerFile }}mb</span>
			<br>
			<span class="secondary-text">{{ 'Allowed file types' | translate }}: {{ validExtensionsComma }}</span>
		</div>
	</div>
    <div class="max-reached" *ngIf="files.length >= maxFiles">
		<span class="secondary-text">{{ 'Maximum number of files reached' | translate }}</span>
	</div>	
</div>


<div class="files-list" *ngIf="files.length">
	<div class="single-file" *ngFor="let file of files; let i = index">
        <div class="file-icon">
            <mat-icon>attachment</mat-icon>
        </div>
		<div class="info">
			<h4 class="name">
				{{ file?.name }}
			</h4>
			<p class="size">
				{{ formatBytes(file?.size) }}
			</p>
            <mat-progress-bar *ngIf="!file.file" mode="determinate" [value]="file?.progress"></mat-progress-bar>
		</div>

		<div class="actions" *ngIf="file.file">
			<button mat-icon-button (click)="downloadFile(i)">
				<span class="material-icons-outlined">
					download
				</span>
			</button>
			<button mat-icon-button (click)="deleteFile(i)">
				<span class="material-icons-outlined">
					delete
				</span>
			</button>
		</div>
	</div>
</div>