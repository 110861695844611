import { NgModule } from '@angular/core'
import { TableCtaComponent } from '../components/table-cta/table-cta.component'
import { SelectedFiltersComponent } from '../components/table-filters/components/selected-filters/selected-filters.component'
import { TableSearchComponent } from '../components/table-search/table-search.component'
import { TableFiltersButtonComponent } from '../components/table-filters-button/table-filters-button.component'
import { TranslateModule } from '@ngx-translate/core'
import { TableFiltersComponent } from '../components/table-filters/table-filters.component'
import { NgPipesModule } from 'ngx-pipes'
import { CommonModule } from '@angular/common'
import { MaterialModule } from '../material.module'
import { RouterModule } from '@angular/router'
import { FormsModule } from '@angular/forms'
import { SkeletonTableComponent } from '../ui/components/skeleton-table/skeleton-table.component'

@NgModule({
  declarations: [
    TableCtaComponent,
    SelectedFiltersComponent,
    TableSearchComponent,
    TableFiltersButtonComponent,
    TableFiltersComponent,
    SkeletonTableComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,         // Import RouterModule
    TranslateModule.forChild(),
    NgPipesModule,
    MaterialModule
  ],
  exports: [
    TableCtaComponent,
    SelectedFiltersComponent,
    TableSearchComponent,
    TableFiltersButtonComponent,
    TableFiltersComponent,
    SkeletonTableComponent
  ]
})
export class TablesModule { }
