import { Component, Input } from '@angular/core';
import { GenericUserInvitation } from '../../../models/user.model'

@Component({
  selector: 'shared-invitation-details',
  standalone: false,
  templateUrl: './invitation-details.component.html',
  styleUrl: './invitation-details.component.scss'
})
export class InvitationDetailsComponent {
  @Input() invitation: GenericUserInvitation
}
