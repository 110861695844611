import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfoboxComponent } from './components/infobox/infobox.component'
import { TranslateModule } from '@ngx-translate/core'
import { NgPipesModule } from 'ngx-pipes'
import { MaterialModule } from '../material.module'
import { BlankSlateIconComponent } from './components/blank-slate-icon/blank-slate-icon.component'
import { PreferredBadgeComponent } from '../components/preferred-chip/preferred-chip.component'
import { InlineSVGModule } from 'ng-inline-svg-2'
import { StatusChipComponent } from './components/status-chip/status-chip.component'
import { ExpansionArrowRotateComponent } from '../components/expansion-arrow-rotate/expansion-arrow-rotate.component'
import { DividerExtendedComponent } from './components/divider-extended/divider-extended.component'
import { MainLogoComponent } from '../components/main-logo/main-logo.component'
import { OrgPreviewHorizontalComponent } from './components/org-preview-horizontal/org-preview-horizontal.component'
import { OrgPreviewChartComponent } from './components/org-preview-chart/org-preview-chart.component'
import { ManualVerificationInfoComponent } from './components/manual-verification-info/manual-verification-info.component'
import { RelativeTimeComponent } from './components/relative-time/relative-time.component'
import { CommonAvatarComponent } from './components/common-avatar/common-avatar.component';
import { InvitationDetailsComponent } from './components/invitation-details/invitation-details.component';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA], // Add CUSTOM_ELEMENTS_SCHEMA to allow the usage of web components
  declarations: [
    InfoboxComponent,
    PreferredBadgeComponent,
    BlankSlateIconComponent,
    StatusChipComponent,
    ExpansionArrowRotateComponent,
    DividerExtendedComponent,
    MainLogoComponent,
    OrgPreviewHorizontalComponent,
    OrgPreviewChartComponent,
    ManualVerificationInfoComponent,
    RelativeTimeComponent,
    CommonAvatarComponent,
    InvitationDetailsComponent
  ],
  imports: [
    CommonModule,
    TranslateModule.forChild(),  // Ensure forChild() is used here
    NgPipesModule,
    MaterialModule,
    InlineSVGModule
  ],
  exports: [
    InlineSVGModule,
    InfoboxComponent,
    PreferredBadgeComponent,
    BlankSlateIconComponent,
    StatusChipComponent,
    ExpansionArrowRotateComponent,
    DividerExtendedComponent,
    MainLogoComponent,
    OrgPreviewHorizontalComponent,
    OrgPreviewChartComponent,
    ManualVerificationInfoComponent,
    RelativeTimeComponent,
    CommonAvatarComponent,
    InvitationDetailsComponent,

  ]
})
export class UiModule { }
