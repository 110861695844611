export enum ShoutlyGenericStatusResponseType {
  Error = 'error',
  Success = 'success',
  Warning = 'warning',
  Info = 'info'
}

export interface ShoutlyGenericStatusResponse {
  slug: string;
  type?: ShoutlyGenericStatusResponseType;  // Gör till valfri
  caption?: string;                          // Gör till valfri
  label?: string;                            // Gör till valfri
  prefix?: string;                           // Förblir valfri
}
