import { Component } from '@angular/core'
import { Router } from '@angular/router'

@Component({
  selector: 'shared-manual-verification-info',
  templateUrl: './manual-verification-info.component.html',
  styleUrls: ['./manual-verification-info.component.scss']
})
export class ManualVerificationInfoComponent {

  constructor(
    private router: Router
  ){}

  public goToCompleteSSN(event: MouseEvent): void {
    event.preventDefault()
    this.router.navigate(['/onboarding'], { queryParams: { completeSSN: true } })
  }

  public logout(event: MouseEvent): void {
    event.preventDefault()
    this.router.navigate(['/logout'])
  }
}
