import { Component, OnInit, Output, EventEmitter, Input, ViewChild, OnDestroy, OnChanges, SimpleChanges } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms'
import { MatSelect } from '@angular/material/select'
import { map, Observable, startWith, Subject, takeUntil } from 'rxjs'
import { PhoneNumberInput } from '../../models/phone.model'
import { CountryByKey, DataService } from '../../services/data/data.service'


const SAMPLE_PHONE_NUMBER = '467123456789'

@Component({
  selector: 'app-mobile-phone-input',
  templateUrl: './mobile-phone-input.component.html',
  styleUrls: ['./mobile-phone-input.component.scss']
})
export class MobilePhoneInputComponent implements OnInit, OnDestroy, OnChanges {
  @Output() onPhoneChanges = new EventEmitter<PhoneNumberInput>()
  @Output() onEnter = new EventEmitter<void>()
  @Input() mobileNumber: string = null
  @Input() isVerified: boolean = false
  @Input() disabled: boolean = false
  @Input() validationErrors: ValidationErrors
  @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect

  constructor (
    private dataService: DataService
  ) { }

  public numberPlaceholder: string = SAMPLE_PHONE_NUMBER
  public countries: CountryByKey[]
  public filteredPrefix$: Observable<CountryByKey[]> = null
  public assembliedPhoneNumber: string
  public prefixPhoneFilterCtrl: UntypedFormControl = new UntypedFormControl()
  protected _onDestroy = new Subject<void>()

  public phoneNumberForm = new UntypedFormGroup({
    prefixPhone: new UntypedFormControl('46', [Validators.required]),
    suffixPhone: new UntypedFormControl([], [Validators.required])
  })

  ngOnInit (): void {
    this.countries = this.dataService.getCountriesByPhone()

    this.filterOptions()

    this.handleDisabled(this.disabled)

    this.phoneNumberForm.valueChanges
      .pipe(
        map((mobileInput: PhoneNumberInput) =>
          this.getPhoneNumberInput(mobileInput)
        )
      )
      .subscribe({
        next: (mobileInput: PhoneNumberInput) =>
          this.onPhoneChanges.emit(mobileInput)
      })
  }

  private handleDisabled (disabled: boolean): void {
    if (disabled) this.phoneNumberForm.disable()
    else this.phoneNumberForm.enable()
  }

  ngOnChanges (changes: SimpleChanges): void {
    if (changes?.mobileNumber?.currentValue) this.initInputValue(changes.mobileNumber?.currentValue)
    if (changes?.validationErrors?.currentValue) {
      this.phoneNumberForm.markAllAsTouched()
      this.phoneNumberForm.get('prefixPhone').setErrors(changes.validationErrors?.currentValue.serverError)
      this.phoneNumberForm.get('suffixPhone').setErrors(changes.validationErrors?.currentValue.serverError)
    }
  }

  initInputValue (mobileNumber) {
    // remove all but numbers
    const sanittizedMobileNumber = mobileNumber?.replace(/\D/g, '')

    // get prefix and suffix
    const prefix = this.getPrefix(sanittizedMobileNumber)
    const suffix = sanittizedMobileNumber?.replace(prefix, '')

    // set form values
    this.phoneNumberForm.get('prefixPhone').patchValue(prefix)
    this.phoneNumberForm.get('suffixPhone').patchValue(suffix)
  }

  private getPrefix (mobileNumber = null): string {
    const countries: CountryByKey[] = this.dataService.getCountriesByPhone()

    const prefixes = countries.map(country => country.key)

    return prefixes.find(prefix => mobileNumber?.startsWith(prefix))
  }

  ngOnDestroy (): void {
    this._onDestroy.next()
    this._onDestroy.complete()
  }

  onSubmitPhone () {
    if (this.phoneNumberForm.valid) this.onEnter.emit()
  }

  filterOptions () {
    this.filteredPrefix$ = this.prefixPhoneFilterCtrl.valueChanges
      .pipe(
        startWith(''),
        takeUntil(this._onDestroy),
        map((search_string: string) => search_string ? this._filter(search_string.toString()) : this.countries?.slice())
      )
  }

  _filter (search_string: string): CountryByKey[] {
    const countries = this.dataService.getCountriesByPhone()
    return countries.filter(option => {
      const country_name = option.value.name.toLowerCase()
      const phone_prefix = option.key
      return country_name.toLowerCase().includes(search_string.toLowerCase()) || phone_prefix.startsWith(search_string)
    }
    )
  }

  private getPhoneNumberInput (mobileInput: PhoneNumberInput) {
    const value = mobileInput.prefixPhone + mobileInput.suffixPhone
    const isValid = value.length > 9 && value.length < 14
    return { ...mobileInput, isValid, value }
  }
}
