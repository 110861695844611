import { Component, Input } from '@angular/core';

@Component({
  selector: 'shared-common-avatar',
  standalone: false,
  templateUrl: './common-avatar.component.html',
  styleUrls: ['./common-avatar.component.scss']
})
export class CommonAvatarComponent {
  @Input () size: number = 25
  @Input () roundness: number = 100
  @Input () image: string

  
}
