<form [formGroup]="form" *ngIf="(user$ | async) as user">
    <div *ngIf="!transaction_id">
        <app-mobile-phone-input
            (onPhoneChanges)="patchPhoneControl($event)"
            (onEnter)="onSubmitPhone()"
            [validationErrors]="validationErrors"
            [isVerified]="user?.mobile && form.get('mobile').value === user?.mobile"
            [mobileNumber]="user?.mobile"
        ></app-mobile-phone-input>

        <ng-container *ngIf="form.get('mobile').valid && (user?.mobile !== form.get('mobile').value)">
            <button
            style="width: 100%;"
            *ngIf="sendOTPButtonConfig as conf"
            type="submit" 
            mat-stroked-button
            color="primary"
            [disabled]="conf.isDisabled"
            (click)="sendOTP()">
                <ng-container *ngIf="conf.isLoading">
                    {{ ('loading' | translate | titlecase) + '...' }}
                </ng-container>
                <ng-container *ngIf="!conf.isLoading">
                    {{ conf.disabledSecondsRemaining ? '(' + conf.disabledSecondsRemaining + 's)' : null }} {{ conf.label }}
                </ng-container>
            </button>
        </ng-container>
    </div>
    <div *ngIf="transaction_id && !enableRetry">
        <mat-form-field>
            <mat-label translate>Code</mat-label>
            <input
                type="text"
                id="user_mobile_otp"
                matInput
                formControlName="otp"
            >
            <mat-icon matSuffix
            [matTooltip]="('An SMS containing a number of 6 digits should have arrived to your phone: ' | translate) + form.get('mobile').value">
                help
            </mat-icon>
            <mat-error>{{ form.get('otp').errors?.serverError }}</mat-error>
        </mat-form-field>
        <ng-container *ngIf="!enableRetry">
            <button
            style="width: 100%;"
            *ngIf="validateOTPButtonConfig as conf"
            type="submit"
            mat-stroked-button
            color="primary"
            [disabled]="conf.isDisabled"
            (click)="validateOTP()">
                <ng-container *ngIf="conf.isLoading">
                    {{ ('loading' | translate | titlecase) + '...' }}
                </ng-container>
                <ng-container *ngIf="!conf.isLoading">
                    {{ conf.disabledSecondsRemaining ? '(' + conf.disabledSecondsRemaining + 's)' : null }} {{ conf.label }}
                </ng-container>
            </button>
        </ng-container>
    </div>
    <div *ngIf="enableRetry">
        <button
            style="width: 100%;"
            type="submit"
            mat-stroked-button
            color="primary"
            (click)="refreshComponent(user?.mobile)">
            {{ 'Refresh' | translate }}
        </button>
    </div>
</form>