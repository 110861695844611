// relative-time.component.ts
import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import '@github/relative-time-element'
import { combineLatest, Subject, takeUntil } from 'rxjs'
import { StoreService } from '../../../services/store/store.service'
import { LocaleService } from '../../../services/locale/locale.service'

@Component({
  selector: 'shared-relative-time',
  templateUrl: './relative-time.component.html',
  styleUrls: ['./relative-time.component.scss'],
})

export class RelativeTimeComponent implements OnInit, OnDestroy {
  @Input() datetime!: string // ISO 8601 string, required for relative-time element
  @Input() format: 'elapsed' | 'micro' | 'duration' | 'relative' | 'datetime' = 'relative';
  @Input() fallbackText!: string

  public lang: string = 'en';
  private destroy$: Subject<void> = new Subject();
  public showAlternative = false;
  public locale: string

  public toggleAlternative() {
    this.showAlternative = !this.showAlternative
  }

  constructor(
    private storeService: StoreService,
    private localeService: LocaleService
  ) { }

  ngOnInit(): void {
    combineLatest([
      this.storeService.user$,
      this.localeService.locale$
    ])
    .pipe(
      takeUntil(this.destroy$)
    )
    .subscribe({
      next: ([user, locale]) => {
        console.log( user.language, locale)
        this.lang = user.language
        this.locale = locale
      }
    })
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }
}