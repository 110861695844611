import { Inject, Injectable } from '@angular/core'
import { EncryptStorage } from 'encrypt-storage'

const SECRET_KEY_PLACEHOLDER = 'default-secret-key'

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  private SECRET_KEY: string
  private encryptedStorage: EncryptStorage

  constructor(@Inject('ENVIRONMENT') private environment: any) {
    this.SECRET_KEY = this.environment.storeEncryptionSecretKey || SECRET_KEY_PLACEHOLDER

    if (this.environment.production && (!this.SECRET_KEY || this.SECRET_KEY.length < 10)) {
      throw new Error('SECRET_KEY for encryption should be at least 10 chars')
    }

    this.encryptedStorage = new EncryptStorage(this.SECRET_KEY, {
      prefix: 'store',
      storageType: 'sessionStorage'
    })
  }

  public getItem<T>(key: string): T | null {
    if (this.environment.production) {
      return this.encryptedStorage.getItem<T>(key)
    } else {
      const item = sessionStorage.getItem(key)
      return item ? JSON.parse(item) : null
    }
  }

  public setItem(key: string, value: any): void {
    if (this.environment.production) {
      this.encryptedStorage.setItem(key, value)
    } else {
      sessionStorage.setItem(key, JSON.stringify(value))
    }
  }

  public removeItem(key: string): void {
    if (this.environment.production) {
      this.encryptedStorage.removeItem(key);
    } else {
      sessionStorage.removeItem(key);
    }
  }

  public clear(): void {
    if (this.environment.production) {
      this.encryptedStorage.clear()
    } else {
      sessionStorage.clear()
    }
  }
}