<h3 mat-dialog-title>{{ 'Add Bonus' | titlecase }}</h3>

<mat-dialog-content>
    <form [formGroup]="form">
        <mat-form-field>
            <mat-label>{{ 'Description' | titlecase }}</mat-label>
            <input type="text" matInput formControlName="description">
        </mat-form-field>

        <mat-form-field>
            <mat-label translate>Month and Year</mat-label>
            <input matInput [min]="data.date_start" [max]="data.date_end" [formControl]="monthAndYearControl">
            <input matInput [hidden]="true" [matDatepicker]="dp">
            <mat-hint *ngIf="monthAndYearControl.invalid">MM/YYYY</mat-hint>
            <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
            <mat-datepicker #dp max="data?.date_end" startView="year" (monthSelected)="setMonthAndYear($event, dp)">
            </mat-datepicker>
        </mat-form-field>

        <div class="bonus-type-selector">
            <mat-button-toggle-group (valueChange)="onBonusTypeChange()" class="mat-elevation-z0"
                [appearance]="'legacy'" formControlName="bonusType" aria-label="Bonus Type">
                <mat-button-toggle ngDefaultControl value="fixed" translate>Fixed Amount</mat-button-toggle>
                <mat-button-toggle ngDefaultControl value="hourly" translate>Hourly Based</mat-button-toggle>
            </mat-button-toggle-group>
        </div>
        <br>

        <mat-form-field>
            <mat-label>
                <span *ngIf="form.get('bonusType').value === 'fixed'">{{ 'Rate' | translate }} </span>
                <span *ngIf="form.get('bonusType').value === 'hourly'">{{ 'Hourly Rate' | translate }} </span>
                ({{data.currency | uppercase}})</mat-label>
            <input type="number" matInput formControlName="hourlyRate"
                [placeholder]="50 | currency: 'code': data.currency : '1.2-2'">
        </mat-form-field>

        <mat-form-field [hidden]="form.get('bonusType').value === 'fixed'">
            <mat-label>{{ 'Hours' | translate }}</mat-label>
            <input type="number" matInput formControlName="unitAmount" placeholder="10">
        </mat-form-field>

        <mat-form-field>
            <!-- disabled -->
            <mat-label>{{ 'Amount' | translate }} ({{data.currency | uppercase}})</mat-label>
            <input type="text" matInput formControlName="totalAmount"
                [placeholder]="500 | currency: 'code': data.currency : '1.2-2'">
        </mat-form-field>
    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-flat-button color="primary" [disabled]="form.invalid" (click)="save()">{{ 'Save' | titlecase }}</button>

    <button mat-stroked-button color="primary" (click)="cancel()">{{ 'Cancel' | titlecase }}</button>
</mat-dialog-actions>