import { Component, Input } from '@angular/core'
import { User } from '../../models/user.model'

@Component({
  selector: 'app-user-cabinet',
  templateUrl: './user-cabinet.component.html',
  styleUrls: ['./user-cabinet.component.scss']
})
export class UserCabinetComponent {
  @Input() user: User
}
