<mat-dialog-content>
  <h2 mat-dialog-title translate>Privacy Preference Center</h2>
  <p class="secondary-text" translate>
    When you visit websites, they may store or retrieve data in your browser...
  </p>

  <div class="elements">
    <button mat-stroked-button color="primary" (click)="onDenyAll()" translate>Reject All</button>
    <button mat-flat-button color="primary" (click)="onAllowAll()" translate>Allow All</button>
  </div>
  
  <h2 translate>Manage Consent Preferences by Category</h2>

  <div class="option">
    <div class="toggle-wrapper">
      <h3 translate>Essential</h3>

      <label class="w-checkbox">
        <mat-checkbox
        color="warn"
        [checked]="true"
        [disabled]="true"
        id="essential" name="essential"></mat-checkbox>
      </label>
    </div>
    <div class="secondary-text" translate>These items are required to enable basic website functionality.</div>
  </div>

  <!-- <div class="option">
    <div class="toggle-wrapper">
      <h3 translate>Marketing</h3>
      <label class="w-checkbox">
        <mat-checkbox color="warn" [(ngModel)]="preferences.marketing" id="marketing" name="marketing"></mat-checkbox>
      </label>
    </div>
    <p class="secondary-text" translate>These items are used to deliver advertising that is more relevant to you and your interests...</p>
  </div> -->

  <!-- <div class="option">
    <div class="toggle-wrapper">
      <h3 translate>Personalization</h3>
      <label class="w-checkbox">
        <mat-checkbox color="warn" [(ngModel)]="preferences.personalization" id="personalization" name="personalization"></mat-checkbox>
      </label>
    </div>
    <p class="secondary-text" translate>These items allow the website to remember choices you make...</p>
  </div> -->

  <div class="option">
    <div class="toggle-wrapper">
      <h3 translate>Analytics</h3>
      <label class="w-checkbox">
        <mat-checkbox color="warn" [(ngModel)]="preferences.analytics" id="analytics" name="analytics"></mat-checkbox>
      </label>
    </div>
    <p class="secondary-text" translate>These items help the website operator understand how its website performs...</p>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-flat-button color="primary" (click)="onSubmitPreferences()">Confirm and close</button>
</mat-dialog-actions>
