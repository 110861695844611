<h2 mat-dialog-title>{{ agreement.title }}</h2>

<mat-dialog-content
    class="agreement-dialog-content"
    appCustomScroll 
    #appScrollElement="appCustomScroll" 
    #scroll
>
    <div class="agreement-dialog-content-text"
        [innerHTML]="agreement.text">
    </div>
</mat-dialog-content>

<mat-dialog-actions>

    <button 
        mat-stroked-button
        color="primary" 
        [mat-dialog-close]="false" 
    >{{ agreement?.rejectButtonText || 'decline' | translate | titlecase }}</button>

    <!-- [disabled]="appScrollElement.disableBtn"  -->
    <button 
        mat-flat-button 
        color="primary" 
        [mat-dialog-close]="true" 
        [disabled]="appScrollElement.disableBtn"
    >{{ agreement?.agreeButtonText || 'accept' | translate | titlecase }}</button>

</mat-dialog-actions>