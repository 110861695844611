<div class="user-profile-nav" *ngIf="user">
    <div class="column user-avatar">
        <img [src]="user.avatar">
    </div>
    <div class="column user-info">
      <div class="user-name">{{ user.first_name | titlecase }}</div>
      <div class="user-position">{{ user.position || 'position' | titlecase }}</div>
    </div>
    <div class="column user-role">
      <div class="role">
        <span class="strong">{{ user.role.name }}</span>
      </div>
    </div>
    <div class="column arrow">
      <mat-icon>arrow_drop_down</mat-icon>
    </div>
  </div>