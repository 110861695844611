import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable, throwError } from 'rxjs'
import { catchError, tap } from 'rxjs/operators'
import { CostCenterResponse } from '../../models/cost-centers.model'
import { ApiService } from '../api/api.service'

@Injectable({
  providedIn: 'root'
})
export class CostCenterService {
  constructor (
    private apiService: ApiService
  ) { }

  public isUpdating$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)

  public costCenterList: CostCenterResponse[] = null

  public getAll (): Observable<CostCenterResponse[]> {
    this.isUpdating$.next(true)
    return this.apiService.getCostCenters()
      .pipe(
        tap(() => {
          this.isUpdating$.next(false)
        }),
        tap(res => {
          this.costCenterList = res
        }),
        catchError(response => {
          this.isUpdating$.next(false)
          return throwError(response)
        })
      )
  }

  public post (name): Observable<any> {
    this.isUpdating$.next(true)
    return this.apiService.postCostCenter(name)
      .pipe(
        tap(() => {
          this.isUpdating$.next(false)
        }),
        catchError(response => {
          this.isUpdating$.next(false)
          return throwError(response)
        })
      )
  }

  public delete (id) {
    this.isUpdating$.next(true)
    return this.apiService.deleteCostCenter(id)
      .pipe(
        tap(() => {
          this.isUpdating$.next(false)
        }),
        catchError(response => {
          this.isUpdating$.next(false)
          return throwError(response)
        })
      )
  }

  public update (id, name): Observable<CostCenterResponse> {
    this.isUpdating$.next(true)
    return this.apiService.updateCostCenter(id, name)
      .pipe(
        tap(() => {
          this.isUpdating$.next(false)
        }),
        catchError(response => {
          this.isUpdating$.next(false)
          return throwError(response)
        })
      )
  }
}
