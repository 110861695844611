
<ng-container *ngIf="qrString$ | async as qr; else loader">
    <mat-dialog-content>
        <qr-code class="qr-code"
        [value]="qr"
        [centerImageSrc]="'assets/logos/bankid_bitmap_qr.png'"
        elementType="img" size="300" errorCorrectionLevel="M"></qr-code>
    </mat-dialog-content>

    <div class="table-center">
        <button mat-flat-button color="primary" matSuffix (click)="openBankIdApp()">
            <span>{{!bankid_url ? ('loading' | translate | titlecase) + '...' : ('Launch BankID app' |
                translate)}} </span>
            <mat-icon>launch</mat-icon>
        </button>
    </div>

    <mat-dialog-actions>
        <button mat-stroked-button *ngIf="data.eidTransaction.status !== 'failed'" color="primary" [mat-dialog-close]="false">{{ 'cancel' | translate | titlecase }}</button>
        <button mat-flat-button *ngIf="data.eidTransaction.status === 'failed'" color="primary" [mat-dialog-close]="true">Retry</button>
    </mat-dialog-actions>
</ng-container>

<ng-template #loader>
    <div class="center">
        <mat-spinner diameter="50"></mat-spinner>
    </div>
</ng-template>