import { Directive, ElementRef, HostListener, NgZone } from '@angular/core'

@Directive({
  selector: '[appCustomScroll]',
  exportAs: 'appCustomScroll'
})
export class CustomScrollDirective {
  disableBtn: boolean = true
  top: number
  offSetHeight: number
  scrollHeight: number
  constructor (
    private elRef:ElementRef,
    private ngZone: NgZone
  ) {}

  // Update disableBtn inside NgZone.run():
  @HostListener('scroll') onScrollEvent (event: Event) {
    this.top = this.elRef.nativeElement.scrollTop
    this.offSetHeight = this.elRef.nativeElement.offsetHeight
    this.scrollHeight = this.elRef.nativeElement.scrollHeight
    this.ngZone.run(() => {
      if (this.top === 0) {
        this.disableBtn = true
      }
      if (this.top >= this.scrollHeight - this.offSetHeight - 5) {
        this.disableBtn = false
      }
    })
  }
}
