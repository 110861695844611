import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, catchError, map, of, switchMap } from 'rxjs'
import { LocaleService } from '../locale/locale.service'

export interface VersionFileModelResponse {
  buildHash: string
  npmVersion: string
  executionDate: string
}

export interface VersionFileModel extends VersionFileModelResponse {
  locale: string
}

@Injectable({
  providedIn: 'root'
})
export class VersionService {
  constructor(
    private http: HttpClient,
    private localeService: LocaleService
  ) { }


  private readVersionFile(): Observable<VersionFileModelResponse> {
    return this.http.get<VersionFileModelResponse>('/version-info.json')
      .pipe(
        catchError(() => of({ buildHash: '000', npmVersion: '000', executionDate: '01/01/00', locale: 'en' } as VersionFileModelResponse))
      )
  }

  public getVersion(): Observable<VersionFileModel> {
    return this.readVersionFile()
      .pipe(
        switchMap(response => this.localeService.locale$
          .pipe(
            map(locale => ({
              ...response,
              locale: locale // Add locale information to the response without altering the executionDate
            }) as VersionFileModel)
          ))
      )
    }
}
