import { Injectable } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { Router } from '@angular/router'
import { BehaviorSubject } from 'rxjs'

export interface shoutlyTitle{
  title: string
  isChildren: boolean
  parentUrl?: string
}

@Injectable({
  providedIn: 'root'
})
export class NavigationTitleService {
  constructor (
    private titleService: Title,
    private router: Router
  ) { }

  title$ = new BehaviorSubject<shoutlyTitle>({ title: 'Shoutly', isChildren: false, parentUrl: 'dashboard' })
  title = 'Shoutly'

  setTitle (string: string, isChildren = false, parentUrl = null): void {
    // go 2 levels up if parentUrl is not set
    if (!parentUrl) {
      parentUrl = this.router.url.split('/').slice(0, -2).join('/')
    }

    // add string without assembly
    this.title$.next({ title: string, isChildren, parentUrl })
    // set title for browser
    const titleForBrowserTab = string.toUpperCase() + ' | Shoutly' // this.ucCasePipe.transform(string) + ' | Shoutly'
    this.titleService.setTitle(titleForBrowserTab)
  }
}
