/* Deprecate? */

import { OrganizationBillingApiResponse } from './organization.model'

export interface Billing extends OrganizationBillingApiResponse{}

export interface InvoiceListRequestPageParams {
  page: number
  limit: number
  from?: string
  to?: string
  orderby?: string
  sort?: string
}
