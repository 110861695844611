import { ApiService } from 'projects/shared-lib/src/lib/services/api/api.service'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { HttpEvent } from '@angular/common/http'
import { UploadFileResponseAPI } from '../../models/file-upload.model'

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {
  constructor (
    private apiService: ApiService
  ) { }

  uploadFile (file: File, model?: string): Observable<HttpEvent<UploadFileResponseAPI>> {
    const formData = new FormData()

    if (file) formData.append('file', file)
    if (model) formData.append('model', model)

    return this.apiService.uploadFile(formData)
  }

  deleteFile (id: number) {
    return this.apiService.deleteFile(id)
  }
}
