<ng-container *ngIf="filterTags?.length">
    <div class="tags-bar">
        <mat-chip-listbox aria-label="filter tags">
            <ng-container *ngFor="let tag of filterTags">
                <mat-chip-option (removed)="removeFilterTag(tag)">
                    <img *ngIf="tag.avatar" matChipAvatar [src]="tag.avatar" alt="Filtered org">
                    <mat-chip-avatar *ngIf="!tag.avatar">
                        <span class="material-icons-outlined">
                            {{ getIcon(tag.type) }}
                        </span>
                    </mat-chip-avatar>
                    {{ tag.name.split('_').join(' ') | titlecase }}
                    <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip-option>
            </ng-container>
        </mat-chip-listbox>
    
        <div class="clear-tags-button" *ngIf="filterTags?.length">
            <a (click)="removeAllFilters()" translate>Clear all</a>
        </div>
    </div>
</ng-container>
    