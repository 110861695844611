import { Component, Input } from '@angular/core'

@Component({
  selector: 'shared-main-logo',
  templateUrl: './main-logo.component.html',
  styleUrls: ['./main-logo.component.scss']
})
export class MainLogoComponent {
  @Input() opened: boolean
  @Input() brand: string = null
  @Input() isPortrait = false
  @Input() isAltColor = false
  @Input() class = ''

  constructor () { }
}
