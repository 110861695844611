import { NgModule } from '@angular/core'
import { CommonModule, DatePipe, TitleCasePipe } from '@angular/common'
import { AbbreviateNumberPipe } from './abbreviate-number/abbreviate-number.pipe'
import { DateStringToTimestampPipe } from './date-string-to-timestamp/date-string-to-timestamp.pipe'
import { NgPipesModule } from 'ngx-pipes'
import { DashtailPipe } from './dashtail/dashtail.pipe'
import { EllipsisPipe } from './ellipsis/ellipsis.pipe'
import { PeriodNumberIntoDatesPipe } from './period-number-into-dates/period-number-into-dates.pipe'

@NgModule({
  declarations: [
    AbbreviateNumberPipe,
    DateStringToTimestampPipe,
    DashtailPipe,
    EllipsisPipe,
    PeriodNumberIntoDatesPipe,
  ],
  imports: [
    CommonModule,
    NgPipesModule
  ],
  providers: [
    DatePipe,
    TitleCasePipe
  ],
  exports: [
    NgPipesModule,
    AbbreviateNumberPipe,
    DateStringToTimestampPipe,
    DashtailPipe,
    EllipsisPipe,
    PeriodNumberIntoDatesPipe,
  ]
})
export class PipesModule { }
