import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { CollaborationItemCreateRequest } from '../../models/collaboration.model'
import { CollaborationsService } from '../../services/collaborations/collaborations.service'
import { DatePipe } from '@angular/common'

@Component({
  selector: 'shared-create-bonus-form',
  templateUrl: './create-bonus-form.component.html',
  styleUrls: ['./create-bonus-form.component.scss']
})
export class CreateBonusFormComponent {
  constructor(
    private collabService: CollaborationsService,
    public dialogRef: MatDialogRef<CreateBonusFormComponent>,
    private datepipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) public data: { collab_id: string, currency: string, date_start: string, date_end: string }
  ) { }

  public form: FormGroup
  public monthAndYearControl = new FormControl<string>('')

  ngOnInit() {
    this.form = new FormGroup({
      description: new FormControl('', [Validators.required]),
      bonusMonth: new FormControl('', [Validators.required]),
      bonusYear: new FormControl('', [Validators.required]),
      bonusType: new FormControl('fixed', [Validators.required]),
      hourlyRate: new FormControl('', [Validators.required, Validators.min(1)]),
      unitAmount: new FormControl('', [Validators.required, Validators.min(1)]),
      totalAmount: new FormControl({ value: null, disabled: true })
    })

    this.form.get('hourlyRate').valueChanges
      .subscribe(rate => {
        const units = this.form.get('unitAmount').value
        if (rate && units) {
          this.form.get('totalAmount').setValue((rate * units).toFixed(2))
        }
      })

    this.form.get('unitAmount').valueChanges
      .subscribe(units => {
        const rate = this.form.get('hourlyRate').value
        if (rate && units) {
          this.form.get('totalAmount').setValue((rate * units).toFixed(2))
        }
      })
  }

  save() {
    if (this.form.valid) {
      this.submitBonusForm()
    }
  }

  cancel() {
    this.dialogRef.close()
  }

  public onBonusTypeChange() {
    const bonusType = this.form.get('bonusType').value

    if (bonusType === 'fixed') {
      this.form.get('unitAmount').patchValue('1')
      this.form.get('unitAmount').disable()
    } else {
      this.form.get('unitAmount').patchValue('0')
      this.form.get('unitAmount').enable()
    }
  }

  public setMonthAndYear (event: string, dp) {
    const bonusMonth = this.datepipe.transform(event, 'M')
    const bonusYear = this.datepipe.transform(event, 'yyyy')

    if (bonusMonth && bonusYear) {
      this.form.patchValue({
        bonusMonth, bonusYear
      })

      this.monthAndYearControl.setValue(`${bonusMonth}/${bonusYear}`)
    } else {
      this.monthAndYearControl.setValue('')
    }

    dp.close()
  }

  private submitBonusForm() {
    const req: CollaborationItemCreateRequest = {
      collab_id: this.data.collab_id,
      month: this.form.get('bonusMonth').value,
      year: this.form.get('bonusYear').value,
      hours: this.form.get('unitAmount').value,
      rate: this.form.get('hourlyRate').value,
      currency: this.data?.currency,
      title: this.form.get('description').value
    }

    this.collabService.createCollabItem(req)
      .subscribe({
        next: (res) => {
          this.dialogRef.close(res)
        }
      })
  }
}
