<div class="infobox-status" *ngIf="showMessage" [ngClass]="status" [@slideUpAnimation]>
    <div class="icon left">
      <mat-icon fontSet="material-icons-outlined">report_problem</mat-icon>
    </div>
    <div class="text left">
      <ng-content></ng-content>
    </div>
    <div *ngIf="dismissable" class="dismiss right">
      <button (click)="onDismissClick()" mat-icon-button color="primary">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>