import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core'
import { FormControl, FormGroup, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms'
import {  OrganizationPayoutApiResponseV2Wise } from '../../models/organization.model'
import { LocationService, OpenStreeetMapNominatimGeocodingV1Response } from '../../services/location/location.service'
import { DataService } from '../../services/data/data.service'
import { ApiWiseFieldConfiguration, ApiWiseFieldConfigurationField } from '../../models/settings.model'
import { distinctUntilChanged } from 'rxjs'

@Component({
  selector: 'shared-bank-settings-form',
  templateUrl: './bank-settings-form.component.html',
  styleUrls: ['./bank-settings-form.component.scss']
})
export class BankSettingsFormComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() wise: OrganizationPayoutApiResponseV2Wise
  @Input() currency: string
  @Input() validationErrors: ValidationErrors
  @Output() output_form = new EventEmitter<UntypedFormGroup>()

  constructor(
    private dataService: DataService,
    private locationService: LocationService
  ) { }

  public countries: any[] = []
  public isFetching = false
  public settingsFields: ApiWiseFieldConfiguration[] = []
  public form: FormGroup

  public get currentTransferTypeFields(): {
    [key: string]: ApiWiseFieldConfigurationField
  } {
    const transferType = this.form.get('wise').get('transfer_type').value

    return (
      this.settingsFields
        .find((field) => field.name === transferType)
        ?.fields.reduce((acc, field) => {
          acc[field.key] = field
          return acc
        }, {}) || {}
    )
  }

  ngOnInit(): void {
    this.getCountries()
  
    // Providing default values for wise.bank_country and wise.transfer_type
    const defaultBankCountry = this.wise?.bank_country ?? '' // Replace '' with a sensible default if needed
    const defaultTransferType = this.wise?.transfer_type ?? '' // Replace '' with a sensible default if needed
  
    this.form = new FormGroup({
      wise: new FormGroup({
        bank_country: new FormControl(defaultBankCountry),
        transfer_type: new FormControl(defaultTransferType)
      }),
      // type: new FormControl('', Validators.required)
    })
  }

  ngAfterViewInit(): void {

    this.form.valueChanges
      .pipe(
        distinctUntilChanged()
      )
      .subscribe(() => this.emitForm(this.form))

    if (this.validationErrors) this.showErrorsOnForm(this.validationErrors)

    if (this.wise?.bank_country) this.fetchRequirements(this.wise.bank_country)

    this.patchValues()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.validationErrors?.currentValue) {
      this.showErrorsOnForm(changes.validationErrors.currentValue)
    }

    if (this.wise?.bank_country &&
      changes.currency?.currentValue !== changes.currency?.previousValue) {
      this.fetchRequirements(this.wise.bank_country)
    }
  }

  private emitForm(form: FormGroup) {
    return this.output_form.emit(form)
  }

  private patchValues() {
    const data = this.wise

    if (!data) return

    const patchData = data

    const wise = this.form.get('wise') as FormGroup

    Object.entries(patchData).forEach(([key, value]) => {
      if (!value) return
      if (!wise.contains(key)) {
        wise.addControl(key, new FormControl(value))
      } else {
        wise.get(key).patchValue(value)
      }
    })
  }

  public showErrorsOnForm(validationErrors: ValidationErrors) {

    if(!this.form) return

    const wiseFormGroup = this.form.get('wise') as FormGroup

    Object.keys(validationErrors).forEach((prop) => {
      // Split the property based on '.' to get the actual form control name.
      const controlName = prop.split('.').pop()

      // Get the form control using the extracted name.
      const formControl = wiseFormGroup.get(controlName)

      if (formControl) {
        formControl.setErrors({
          serverError: validationErrors[prop]
        })
      }
    })
  }

  public setValidators(config: ApiWiseFieldConfigurationField) {
    const wise = this.form.get('wise') as FormGroup
    const required: boolean = config.required
    const minLength: number = config.minLength
    const maxLength: number = config.maxLength
    const pattern: string = config.validationRegexp

    const validators = []

    if (required) validators.push(Validators.required)
    if (minLength) validators.push(Validators.minLength(minLength))
    if (maxLength) validators.push(Validators.maxLength(maxLength))
    if (pattern) validators.push(Validators.pattern(pattern))

    wise.get(config.key).setValidators(validators)
    wise.get(config.key).updateValueAndValidity()
  }

  private toggleControls(config: ApiWiseFieldConfigurationField[]) {
    if (!config) return
    const form = this.form.get('wise') as FormGroup

    config.forEach((element) => {
      if (!element.required) {
        form.removeControl(element.key)
        return
      }

      form.addControl(element.key, new FormControl('', Validators.required))
      this.setValidators(element)
    })
  }

  public onBankCountryChange(countryKey: string) {
    this.fetchRequirements(countryKey)
  }

  public fetchRequirements(countryKey: string) {
    const currency = this.currency
    const bank_country = countryKey

    if (currency && bank_country) {
      this.isFetching = true
      this.dataService
        .requestWiseRequirements(currency, bank_country)
        .subscribe({
          next: (requirements: ApiWiseFieldConfiguration[]) => {
            this.isFetching = false
            this.handleRequirementsSuccess(requirements)
          },
          error: () => {
            this.isFetching = false
          }
        })
    }
  }

  public transferTypeChanges(transferType: string) {
    if (!transferType) return
    const config = this.settingsFields.find(
      (field) => field.name === transferType
    )?.fields
    this.toggleControls(config)
  }

  public handleRequirementsSuccess(requirements: ApiWiseFieldConfiguration[]) {
    this.settingsFields = requirements
    const wise = this.form.get('wise') as FormGroup
    if (this.settingsFields.length === 1) {
      wise.get('transfer_type').setValue(this.settingsFields[0].name)
    }

    if (!this.settingsFields.length) {
      wise.get('transfer_type').setErrors({ noTransferType: true })
      wise.get('transfer_type').markAsTouched()
    }

    const transferType = wise.get('transfer_type').value
    this.transferTypeChanges(transferType)
  }

  public getCountries() {
    this.countries = this.dataService
      .getCountriesByName()
  }

  public getGeoLocation(event) {
    this.locationService.getGeoLocation()
      .subscribe((info: OpenStreeetMapNominatimGeocodingV1Response) => this.patchLocationInfo(info))
    event.stopPropagation()
  }

  private patchLocationInfo(info: OpenStreeetMapNominatimGeocodingV1Response) {
    const wise = this.form.get('wise') as FormGroup
    wise.patchValue({
      city: info.address?.city ? info.address.city : info.address?.municipality,
      address1: info.address?.road,
      postcode: info.address?.postcode
    })
  }
}
