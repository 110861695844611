<div *ngIf="isLoading">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>

<div *ngIf="!isLoading">
    <ng-container *ngIf="!oauthEnabled">
        <form [formGroup]="form">
            <div formGroupName="paypal">
                <mat-form-field>
                    <input
                    matInput
                    type="text"
                    formControlName="paypal_email"/>
                </mat-form-field>
            </div>
        </form>
    </ng-container>

    <ng-container *ngIf="oauthEnabled">
        <div [ngSwitch]="linkStatus.currentStatus">
            <!-- Connected Status -->
            <ng-container *ngSwitchCase="'LINKED'">
                <div class="paypal-connect-status connection-status" style="color: darkgreen;">
                    Connected: {{ form.value?.paypal?.paypal_email }}
                </div>
            </ng-container>
    
            <!-- Unlinked Status -->
            <ng-container *ngSwitchCase="'UNLINKED'">
                <div class="paypal-connect-status connection-status" style="color: darkred;">
                    Not Connected
                </div>
                <button type="button" mat-stroked-button color="primary" (click)="openOAuth()">
                    Connect with PayPal
                    <mat-icon>link</mat-icon>
                </button>
            </ng-container>
    
            <!-- Pending RefWindow Status -->
            <ng-container *ngSwitchCase="'PENDING_REFWINDOW'">
                <div class="paypal-connect-status connection-status" style="color: darkorange;">
                    Connecting to PayPal... Please complete the authentication in the new window.
                </div>
            </ng-container>
    
            <!-- Pending Other Status -->
            <ng-container *ngSwitchCase="'PENDING_OTHER'">
                <div class="paypal-connect-status connection-status" style="color: darkblue;">
                    Processing... Please wait.
                </div>
            </ng-container>
    
            <!-- Error Status -->
            <ng-container *ngSwitchCase="'ERROR'">
                <div class="paypal-connect-status connection-status" style="color: red;">
                    Error: {{ responseError | titlecase }}
                </div>
            </ng-container>
    
            <!-- Default case when none of the above statuses match -->
            <ng-container *ngSwitchDefault>
                <button type="button" mat-stroked-button color="primary" (click)="openOAuth()">
                    Connect with PayPal
                    <mat-icon>link</mat-icon>
                </button>
            </ng-container>
        </div>
    
        <!-- Display this section if there's a specific response error outside the PayPal linking flow -->
        <div *ngIf="responseError && linkStatus.currentStatus !== 'ERROR'" class="paypal-connect-status connection-status" style="color: red;">
            Error: {{ responseError | titlecase }}
        </div>
    </ng-container>


</div>
