<li class="partner-item" >
    <div class="partner-item-group">
        <div class="partner-avatar">
            <!-- <img src="{{result?.avatar}}" class="avatar"/> -->
            <shared-common-avatar [size]="33" [image]="result?.avatar">
            </shared-common-avatar>
        </div>
    </div>
    <div class="partner-item-group">
        <div class="name">
            {{result?.name || 'Shoutly User'}}
        </div>
        <div class="workfields" *ngIf="result?.workfields">
            <div class="workfield">{{ result?.workfields[0] }}</div>
            <div class="workfield" *ngIf="result?.workfields.length > 1">{{ result?.workfields.length -1 }} <span translate>more</span></div>
        </div>
    </div>
    <div class="partner-item-group" *ngIf="currency">
        <span class="secondary-text" translate>{{ 'total' | translate | titlecase }}:</span><br>
        <span class="total">{{ result?.total | currency: currency:'code' }} </span>
    </div>
    <div class="partner-item-group" *ngIf="!currency">
        <span class="secondary-text" translate>{{ 'collaborations' | translate | titlecase }}:</span><br>
        <span class="total">{{ result?.total }} </span>
    </div>
  </li>