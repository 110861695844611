import { Component, Input } from '@angular/core'

@Component({
  selector: 'shared-expansion-arrow-rotate',
  templateUrl: './expansion-arrow-rotate.component.html',
  styleUrls: ['./expansion-arrow-rotate.component.scss']
})
export class ExpansionArrowRotateComponent {
  @Input() open = false
}
