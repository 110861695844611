<h3 mat-dialog-title>{{ data.title | titlecase}}</h3>
<span class="secondary-text">{{ data.subtitle }}</span>
<div mat-dialog-content class="mat-body">
    <mat-form-field>
        <mat-label translate>Select</mat-label>
        <input 
            matInput 
            [formControl]="dateControl" 
            required
        >
        <input 
            matInput
            [hidden]="true"
            [min]="data?.minDate"
            [max]="data?.maxDate"
            [matDatepicker]="picker"
        >
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker 
            touchUi 
            #picker
            startView="year"
            (monthSelected)="setMonthAndYear($event, picker)"></mat-datepicker>
    </mat-form-field>
</div>

<div mat-dialog-actions>
    <button mat-stroked-button color="primary" [mat-dialog-close]="false">{{'cancel' | translate | uppercase}}</button>
    <button mat-flat-button color="primary" cdkFocusInitial [mat-dialog-close]="outputPeriod" [disabled]="dateControl.invalid">{{'report' | translate | uppercase}}</button>
</div>