import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class BrowserInfoService {

  constructor(){}

  public getUserLanguage(): string {
    return navigator.language || 'en-US'
  }

  public getUserTimeZone(): string {
    return Intl.DateTimeFormat().resolvedOptions().timeZone
  }

  public getScreenResolution(): string {
    return `${window.screen.width}x${window.screen.height}`
  }

  public getUserAgent(): string {
    return navigator.userAgent
  }

  public getPlatform(): string {
    const userAgent = navigator.userAgent.toLowerCase()
    if (userAgent.includes('android')) return 'Android'
    if (userAgent.includes('iphone') || userAgent.includes('ipad') || userAgent.includes('ipod')) return 'iOS'
    if (userAgent.includes('win')) return 'Windows'
    if (userAgent.includes('mac') && !userAgent.includes('iphone') && !userAgent.includes('ipad') && !userAgent.includes('ipod')) return 'MacOS'
    if (userAgent.includes('linux')) return 'Linux'
    return 'Unknown'
  }

  public prefersDarkMode(): boolean {
    return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
  }

  public getUserInfo(): any {
    return {
      language: this.getUserLanguage(),
      timeZone: this.getUserTimeZone(),
      screenResolution: this.getScreenResolution(),
      userAgent: this.getUserAgent(),
      platform: this.getPlatform(),
      darkMode: this.prefersDarkMode(),
    }
  }
}