import { NgModule, ModuleWithProviders, InjectionToken } from '@angular/core'
import { HTTP_INTERCEPTORS } from '@angular/common/http'
import { MaterialModule } from './material.module'
import { CommonModule } from '@angular/common'
import { AuthInterceptor } from './interceptors/auth.interceptor'
import { HttpErrorInterceptor } from './interceptors/http-error.interceptor'
import { SettingsService } from './services/settings/settings.service'
import { UiModule } from './ui/ui.module'
import { RouterModule } from '@angular/router'
import { ReCaptchaV3Service, RECAPTCHA_V3_SITE_KEY as ORIGINAL_RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha-2'
import { DialogsModule } from './dialogs/dialogs.module'
import { TablesModule } from './tables/tables.module'
import { FormsModule } from './forms/forms.module'
import { PipesModule } from './pipes/pipes.module'

// Define the Injection Token directly in the module file
export const RECAPTCHA_V3_SITE_KEY = new InjectionToken<string>('recaptchaV3SiteKey')

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    UiModule,
    RouterModule,
    RecaptchaV3Module,
    DialogsModule,
    TablesModule,
    FormsModule,
    PipesModule,
  ],
  providers: [
    SettingsService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
  ],
  exports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    UiModule,
    DialogsModule,
    TablesModule,
    PipesModule,
    FormsModule
  ]
})
export class SharedLibModule {
  static forRoot(environment): ModuleWithProviders<SharedLibModule> {
    return {
      ngModule: SharedLibModule,
      providers: [
        { provide: 'ENVIRONMENT', useValue: environment },
        { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.recaptcha?.siteKey },
        { provide: ORIGINAL_RECAPTCHA_V3_SITE_KEY, useExisting: RECAPTCHA_V3_SITE_KEY },
        ReCaptchaV3Service
      ]
    }
  }
}
