<form [formGroup]="form">
    <div formGroupName="swish">
        <app-mobile-phone-input
            id="payout_swish_number"
            (onPhoneChanges)="updatePhoneNumber($event.value)"
            [disabled]="!personalNumber"
            [mobileNumber]="swish?.swish_number"
            [validationErrors]="validationErrors ? { serverError: validationErrors['swish.swish_number'] } : null"
        ></app-mobile-phone-input>
    </div>
    <span *ngIf="!personalNumber" translate>
        Please add a Swedish Personal Number before activating Swish. You can do it under personal settings.
    </span>
    <!-- <shared-ssn-form *ngIf="!hasUserPersonalNumber"></shared-ssn-form> -->
</form>