import { Injectable } from '@angular/core'
import { BehaviorSubject, Subject } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class ExternalSaveButtonHandlerService {
  constructor () {}

  public saveButtonSubject: Subject<void> = new Subject<void>()
  public isDisabledSaveButtonSubject: Subject<boolean> = new BehaviorSubject<boolean>(true)
  public previousButtonSubject: Subject<void> = new Subject<void>()
}
