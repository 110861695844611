<div class="invitation-container" *ngIf="invitation">
    <h3>{{ 'Invited from:' | translate | ucfirst }}</h3>
    <div class="invitation-card">
        <shared-common-avatar *ngIf="invitation.ref_org?.avatar" [size]="33"
            [image]="invitation.ref_org?.avatar"></shared-common-avatar>
        <div class="invitation-details">
            <span class="name" translate>{{ invitation.ref_org?.name }}</span>
            <br>
            <span class="org-id">ID: {{ invitation.ref_org?.id }}</span>
        </div>
    </div>

    <h3>{{ 'Invited to:' | translate | ucfirst }}</h3>
    <div class="invitation-card">
        <shared-common-avatar *ngIf="invitation.ref_org?.avatar" [size]="33"
            [image]="invitation.ref_org?.avatar"></shared-common-avatar>
        <div class="invitation-details">
            <span class="name" translate>{{ invitation.invited?.name }}</span>
            <br>
            <span class="org-id"> Email: {{ invitation.email }}</span>
        </div>
    </div>

</div>