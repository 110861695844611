import { HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Params } from '@angular/router'
import { BehaviorSubject, switchMap, Observable, of, throwError } from 'rxjs'
import { catchError, map, tap } from 'rxjs/operators'
import { Department } from '../../models/departments.model'
import { DepartmentUser, InvitedUser } from '../../models/user.model'
import { ApiService } from '../api/api.service'
import { PaginatedResponse } from '../../models/paginated.model'
import { ApiRequestInvitedUser } from '../../models/api.model'
import { HelpersService } from '../helpers/helpers.service'

@Injectable({
  providedIn: 'root'
})
export class DepartmentsService {
  constructor (
    private apiService: ApiService,
    private helpersService: HelpersService
  ) { }

  private isUpdating$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)

  public departmentList: Department[] = null

  public getAll (): Observable<Department[]> {
    const requestParams = { limit: 100 }
    let params = new HttpParams()

    Object.entries(requestParams).forEach(([key, value]) => {
      params = params.append(key, value)
    })

    return this.apiService.getDepartments(params)
      .pipe(
        // fix for introducing pagination
        map(res => res.data),
        tap(res => {
          this.departmentList = res
        })
      )
  }

  public getDepartments (inputParams: Params): Observable<PaginatedResponse<Department[]>> {
    const params = this.helpersService.convertParamsIntoHttpParams(inputParams, ['org_id'])
    return this.apiService.getDepartments(params)
  }

  public getTeamMembers (inputParams: Params): Observable<PaginatedResponse<DepartmentUser[]>> {
    const params = this.helpersService.convertParamsIntoHttpParams(inputParams)
    return this.apiService.getOrganizationUsers(params)
  }

  public getInvitedMembers (inputParams): Observable<PaginatedResponse<InvitedUser[]>> {
    const params = this.helpersService.convertParamsIntoHttpParams(inputParams)
    return this.apiService.getInvitedUsersIntoCurrentOrganization(params)
  }

  public getAdminsOnOrganization () {
    return this.apiService.getAdminsOnOrganization()
      .pipe(
        map(users => users.map(user => {
          user.id = user.user_id
          return user
        }))
      )
  }

  public get (id): Observable<Department> {
    return this.apiService.getDepartment(id)
  }

  public post (name: string): Observable<Department> {
    let params = new HttpParams()
    params = params.append('name', name)

    this.isUpdating$.next(true)

    return this.apiService.postDepartment(params)
      .pipe(
        tap(() => {
          this.isUpdating$.next(false)
        }),
        catchError(response => {
          this.isUpdating$.next(false)
          return throwError(response)
        })
      )
  }

  public update (id: number, name: string): Observable<Department> {
    let params = new HttpParams()
    params = params.append('name', name)

    this.isUpdating$.next(true)
    return this.apiService.updateDepartment(id, params)
      .pipe(
        tap(() => {
          this.isUpdating$.next(false)
        }),
        catchError(response => {
          this.isUpdating$.next(false)
          return throwError(response)
        })
      )
  }

  // public delete (id): Observable<any> {
  //   console.error('remove this method   public delete (id)')
  //   return this.removeDepartment(id)
  // }

  public removeDepartment (id): Observable<any> {
    this.isUpdating$.next(true)
    return this.apiService.deleteDepartment(id)
      .pipe(
        tap(() => {
          this.isUpdating$.next(false)
        }),
        catchError(response => {
          this.isUpdating$.next(false)
          return throwError(response)
        })
      )
  }

  public updateUserRoleAndDepartment (user: { id: string, role: string, position: string, departments?: string[] }): Observable<any> {
    const updateRole = this.editExistingUserOnDepartment(user.id, user.role, user.position)
    const updateDpt = this.updateUserDepartment(user.id, user.departments)

    return updateRole
      .pipe(
        switchMap(() => updateDpt)
      )
  }

  private updateUserDepartment (id, departments): Observable<any> {
    if (!departments?.length) return of({})

    const userRequest = { user_id: id, department_id: departments }

    const params = this.helpersService.convertParamsIntoHttpParams(userRequest, ['department_id'])

    return this.apiService.updateUserDepartment(params)
      .pipe(
        tap(() => {
          this.isUpdating$.next(false)
        }),
        catchError(response => {
          this.isUpdating$.next(false)
          return throwError(response)
        }),
        map(() => {
          this.isUpdating$.next(false)
        })
      )
  }

  public inviteUserToDepartment (user) {
    this.isUpdating$.next(true)
    return this.apiService.inviteUserToDepartment(user)
      .pipe(
        tap(() => {
          this.isUpdating$.next(false)
        }),
        catchError(response => {
          this.isUpdating$.next(false)
          return throwError(response)
        })
      )
  }

  public editExistingUserOnDepartment (user_id, role: string, position: string) {
    let params = new HttpParams()
    params = params.append('role', role)
    params = params.append('position', position)

    this.isUpdating$.next(true)
    return this.apiService.updateUserRoleAndPositionForUser(user_id, params)
      .pipe(
        tap(() => {
          this.isUpdating$.next(false)
        }),
        catchError(response => {
          this.isUpdating$.next(false)
          return throwError(response)
        })
      )
  }

  public editInvitedUserOnDepartment (user: ApiRequestInvitedUser, token: string) {
    this.isUpdating$.next(true)
    return this.apiService.updateUserInvitedToDepartment(user, token)
      .pipe(
        tap(() => {
          this.isUpdating$.next(false)
        }),
        catchError(response => {
          this.isUpdating$.next(false)
          return throwError(response)
        })
      )
  }

  public deleteInvitedUserOnDepartment (token) {
    this.isUpdating$.next(true)
    return this.apiService.deleteUserInvitedToDepartment(token)
      .pipe(
        tap(() => {
          this.isUpdating$.next(false)
        }),
        catchError(response => {
          this.isUpdating$.next(false)
          return throwError(response)
        })
      )
  }

  public getIsUpdating$ (): Observable<boolean> {
    return this.isUpdating$
  }

  public getUserDepartments (user_id: string): Observable<Department[]> {
    return this.getAll()
      .pipe(
        map(departments => {
          const res = []
          departments.forEach(
            department => department.users.forEach(
              (user: DepartmentUser | InvitedUser) => user.id === user_id ? res.push(department) : null
            )
          )
          return res
        })
      )
  }

  public getStoredDepartments (): Department[] {
    return this.departmentList
  }

  public deleteRole (id: number): Observable<any> {
    return this.apiService.deleteRole(id)
  }
}
