<div class="status-wrapper" *ngIf="data">
    <div class="status" [class.loading]="isLoading" [ngClass]="data.type">
      <ng-container *ngIf="isLoading">
        <span>{{ 'loading' | translate | ucfirst }}</span>
      </ng-container>
      <ng-container *ngIf="!isLoading">
        <span class="label-text">{{ data.label | translate | ucfirst }}</span>
        <span *ngIf="data.caption as caption"
              [matTooltip]="caption | translate | ucfirst"
              class="label-caption-trigger info material-icons-outlined">info</span>
      </ng-container>
    </div>
  </div>