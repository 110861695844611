import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http'
import { Observable, throwError } from 'rxjs'
import { catchError } from 'rxjs/operators'
import { Injectable } from '@angular/core'
import { MatSnackBar } from '@angular/material/snack-bar'
import { Router } from '@angular/router'
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker'
import { SentryService } from '../services/sentry/sentry.service'

const SIGNED_OUT_ERRORS = [
  _('Token not provided'),
  _('Token not valid'),
  _('Token has expired'),
]

_('Too Many Attempts')
_('The given data was invalid')

const ERR_TYPE_SILENT = [
  'MissingOrgPayoutDetails'
]

const statusMessages = {
  401: { message: _('Unauthorized. Please login again.'), panelClass: 'error' },
  403: { message: _('Forbidden. Access is denied.'), panelClass: 'error' },
  404: { message: _('Not found.'), panelClass: 'error' },
  504: { message: _('Gateway Timeout. Please try again later.'), panelClass: 'error' },
  500: { message: _('Internal Server Error. Please try again later.'), panelClass: 'error' },
  default: { message: _('An unexpected error occurred.'), panelClass: 'error' }
}

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private router: Router, private snackBar: MatSnackBar, private sentryService: SentryService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
    .pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status !== 404) { // Skip 404 errors
          const errorMessage = error.error?.message || error.error?.err_mess || _('An unexpected error occurred.');
          if (SIGNED_OUT_ERRORS.includes(errorMessage)) {
            this.router.navigate(['/auth/logout'])
          } else if (!(error.error?.err_type && ERR_TYPE_SILENT.some(silentType => error.error?.err_type.includes(silentType)))) {
            const { message, panelClass } = statusMessages[error.status] || statusMessages.default;
            this.snackBar.open(message, null, { duration: 3000, panelClass: ['shoutly-snack-bar', panelClass] });
            this.sentryService.reportError(error, error.status >= 500 ? 'error' : 'warning');
          }
        }
        return throwError(() => error);
      })
    );
  }
}