import { FormControl, Validators } from '@angular/forms'
import { Component, Inject, OnInit } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { DialogData } from '../common-confirm-dialog-box/common-confirm-dialog-box.component'

export interface SingleInputDialogData extends DialogData {
  title: string
  patchValue?: string
}

@Component({
  selector: 'app-single-input-dialog',
  templateUrl: './single-input-dialog.component.html',
  styleUrls: ['./single-input-dialog.component.scss']
})
export class SingleInputDialogComponent implements OnInit {
  constructor (
    public dialogRef: MatDialogRef<SingleInputDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SingleInputDialogData
  ) {}

  ngOnInit (): void {
    this.input.patchValue(this.data?.patchValue)
  }

  input = new FormControl('', [Validators.required])
}
