import { Injectable } from '@angular/core'
import { Observable, map, tap } from 'rxjs'
import { ShoutlyNotification, ShoutlyNotificationValues } from '../../models/notifications.model'
import { ApiService } from '../api/api.service'
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker'

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  constructor (
    private apiService: ApiService
  ) { }

  notificationsDescription: ShoutlyNotificationValues[] = [
    {
      slug: 'collab_cancel_accepted',
      title: _('collaboration'),
      description: _('Collaboration cancellation has been accepted'),
      action: 'collaboration'
    },
    {
      slug: 'collab_accepted',
      title: _('collaboration'),
      description: _('Collaboration has been accepted'),
      action: 'collaboration'
    },
    {
      slug: 'collab_cancel_declined',
      title: _('collaboration'),
      description: _('Collaboration cancellation has been rejected'),
      action: 'collaboration'
    },
    {
      slug: 'collab_declined',
      title: _('collaboration'),
      description: _('Collaboration has been rejected'),
      action: 'collaboration'
    },
    {
      slug: 'collab_invite',
      title: _('collaboration'),
      description: _('New collaboration! Please review.'),
      action: 'collaboration'
    },
    {
      slug: 'dep_org_invite',
      title: _('department'),
      description: _('You got invited into an Organization'),
      action: 'department'
    },
    {
      slug: 'added_to_department',
      title: _('department'),
      description: _('You got added into Department'),
      action: 'department'
    },
    {
      slug: 'removed_from_department',
      title: _('department'),
      description: _('You got removed from Department'),
      action: 'department'
    }
  ]

  private getNotificationSlug (notification: ShoutlyNotification) {
    return this.notificationsDescription.find(x => x.slug === notification.data.slug)
  }

  public getNotifications (): Observable<ShoutlyNotification[]> {
    return this.apiService.getAllNotifications()
      .pipe(
        map(notifications => notifications.map(
          notification => {
            const value = this.getNotificationSlug(notification)
            return { ...notification, value }
          }
        )),
        tap(data => console.log('notif', data))
      )
  }

  public getNotification (uuid): Observable<ShoutlyNotification> {
    return this.apiService.getNotification(uuid)
      .pipe(
        map(
          notification => {
            const value = this.getNotificationSlug(notification)
            return { ...notification, value }
          }
        ))
  }
}
