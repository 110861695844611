import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class OAuthMonitorService {

  initiateOAuthFlow(url: string, baseUrl: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const newWindow = window.open(url, '_blank', 'width=600,height=500');
      if (!newWindow) return reject(new Error('Window open failed'))

      let flowState = 'INITIATED'

      const cleanup = () => {
        window.removeEventListener('message', messageHandler)
        clearInterval(windowChecker)
        if (newWindow && !newWindow.closed) newWindow.close()
      }

      const messageHandler = (event) => {
        console.log("Received message event:", event) // Log for debugging

        if (event.origin !== baseUrl) {
          console.error("Origin mismatch:", event.origin, "expected:", baseUrl)
        }

        if (event.data && event.data.type === 'oauth_success') {
          flowState = 'COMPLETED'
          cleanup()
          resolve(event.data)
        }
      }

      window.addEventListener('message', messageHandler)

      const windowChecker = setInterval(() => {
        if (newWindow.closed && flowState !== 'COMPLETED') {
          // Debounce the closure handling
          setTimeout(() => {
            console.log('flowstate: ', flowState)
            if (flowState !== 'COMPLETED') {
              flowState = 'CANCELLED';
              cleanup();
              reject(new Error('OAuth flow was cancelled by the user'));
            }
          }, 200) // Adjust debounce delay as needed
        }
      }, 500)
    })
  }
}


