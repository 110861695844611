import { SettingsGenericData } from '../../services/store/store.service'

export enum ProviderType {
  SmsOtp = "smsOtp",
  SeBankID = "seBankID",
  Email = "email",
  ExtBankID = "bankID" //is used in external project
}

export type OrgForm = 'Business' | 'Private'
export type OrgType = 'gigger' | 'employer' | 'agency'
export type SignType = 'register' | 'login'

export interface ShoutlyEidAddTransactionRequest{
  type: 'register' | 'login'
  provider: ProviderType //'smsOtp' | 'seBankID' | 'email' | 'bankID' 
  invite_token?: string
  org_type?: OrgType
  org_form?: OrgForm
  "g-recaptcha-response"?: string
}

export interface ShoutlyEidAddTransactionResponse{
  id: string
  accessUrl: string
}

export interface ShoutlyEidStartTransactionRequest{
  /** Swedish personal number */
  personalNumber?: string
  /** Valid MSISDN in international format, e.g. +441234567890. */
  msisdn?: string
  dkProvider?: 'seBankID' | 'fiTupas' | 'noBankID' | 'dkNemID' | 'verimi' | 'nlIDIN' | 'beItsme' | 'smsOtp' | 'onfido' | 'freja' | 'swisscom' | 'dkMitID' | 'dkNemIDOrMitID' | 'smartID'
  smartIdUserIdentifier?: {
    identityDocumentType: 'Passport' | 'IdCard' | 'PersonalNumber'
    /** Two-character ISO 3166-1 alpha-2 country code (for example EE, LT, LV, KZ). See Wikipedia. */
    country: string
    identifier: string
  }
}

export interface ShoutlyEidSeBankIdProviderInfo{
  /** Expires in 3 minutes (hard threshold set by BankID). If AutoStartToken is issued for a particular personal number, issing a new one before the current one has expired leads to an error. */
  autoStartToken: string
  processStatus?: 'pending' | 'failed' | 'complete'
  processStatusInfo?: 'outstandingTransaction' | 'noClient' | 'started' | 'userSign' | 'pendingOther' | 'expiredTransaction' | 'certificateErr' | 'userCancel' | 'cancelled' | 'startFailed' | 'failedOther'
  completionData?: {
    user: {
      personalNumber: string
      name: string
      givenName: string
      surname: string
    },
    device: {
      ipAddress: string
    },
    cert: {
      notBefore: string
      notAfter: string
    },
    signature: string
    ocspResponse: string
  }
}

export interface ShoutlyEidOTPProviderInfo{
  /** Valid MSISDN in international format, e.g. +441234567890. */
  msisdn: string
  /** Delivery flag. Could be absent depending on the end-user mobile provider and settings. */
  delivered?: boolean
}

export interface ShoutlyOwnCertBankIdProviderInfo {
  qrCode: string
  getLink: string
  completionData: any
  hintCode: string | 'outstandingTransaction'
}

export interface ShoutlyEidTransactionResponse{
  /** TransactionID */
  id: string
  /** An https:// URL. Additionally accepts http:// on testing environment to enable local development with http://localhost URLs. On completion (with Swedish BankID only when using our frontend, for all other providers always) the end-user will be redirected to this URL with the following query parameters: transaction_id, success either true or false */
  redirectUrl: string
  status: 'new' | 'started' | 'failed' | 'complete'
  provider: 'bankID' | 'seBankID' | 'smsOtp' // | 'onfido' | 'freja' | 'swisscom' | 'dkMitID' | 'dkNemIDOrMitID' | 'smartID' | 'fiTupas' | 'noBankID' | 'dkNemID' | 'verimi' | 'nlIDIN' | 'beItsme'

  method: 'auth' | 'sign'
  /** Parameters provided upon transaction creation. The respective field will be absent after the retention period has passed. */
  providerParameters: {
    /** Optional provider parameters set upon an auth transaction creation */
    auth?: []
  }
  /** Information from eID provider about started, completed and failed transactions */
  providerInfo: {
    seBankID?: ShoutlyEidSeBankIdProviderInfo
    smsOtpAuth?: ShoutlyEidOTPProviderInfo
    bankID?: ShoutlyOwnCertBankIdProviderInfo
  }
}

export interface ShoutlyEidSubmitSMSOtpRequest{
  oneTimeCode: number
}

export interface ShoutlyEidSubmitSMSOtpErrorResponse{
  oneTimeCodeValid: boolean
  attemptsLeft: number
}

export interface ShoutlyAuthResponseMessage{
  message: string
}

export interface AuthConfigParams{
  auth_method?: string
  org_type?: 'gigger' | 'employer' | 'agency'
  org_form?: string
  auth_type?: 'login' | 'signup'
  current_step?: number,
  invite_token?: string
}

export interface AuthParam{
  name: string
  viewName: string
  icon: string
  description: string
}

export interface AuthMethod extends AuthParam{}

export interface AuthType extends AuthParam{}

export interface AuthOrgType extends AuthParam{
  name: 'gigger' | 'employer' | 'agency'
}

export interface OnboardGenericData extends SettingsGenericData {}
