import { DatePipe } from '@angular/common'
import { Component, Inject, OnInit } from '@angular/core'
import { FormControl, Validators } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

export interface DatePickerDialogData {
  title: string
  subtitle: string
  minDate: Date
  maxDate: Date
}

@Component({
  selector: 'app-date-picker-dialog',
  templateUrl: './date-picker-dialog.component.html',
  styleUrls: ['./date-picker-dialog.component.scss']
})
export class DatePickerDialogComponent implements OnInit {
  constructor (
    private datepipe: DatePipe,
    public dialogRef: MatDialogRef<DatePickerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DatePickerDialogData
  ) { }

  dateControl = new FormControl('', [Validators.required])

  outputPeriod = {}

  ngOnInit (): void {
  }

  setMonthAndYear (event: string, datapicker) {
    const month = this.datepipe.transform(event, 'M')

    const year = this.datepipe.transform(event, 'yyyy')

    this.dateControl.setValue(`${month}/${year}`)

    this.outputPeriod = { month, year }

    datapicker.close()
  }
}
