<button
  mat-stroked-button
  color="primary"
  [disabled]="!showFiltersButton"
  class="shrinkable-button table-filter-button"
  [matTooltipDisabled]="!isHandset"
  [matTooltip]="'filter' | translate | ucfirst"
  [class.handset]="isHandset"
  (click)="showFiltersDialog(context)"
>
    <mat-icon svgIcon="ui_adjust"></mat-icon>
    <span *ngIf="!isHandset">{{ 'filter' | translate | uppercase }}</span>
</button>