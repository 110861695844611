import { Component, Input, OnChanges } from '@angular/core'

@Component({
  selector: 'app-skeleton-table',
  templateUrl: './skeleton-table.component.html',
  styleUrls: ['./skeleton-table.component.scss']
})
export class SkeletonTableComponent implements OnChanges {
  @Input() numberOfRows = 5
  @Input() numberOfColumns = 5

  dataSource = []
  displayedColumns = []

  ngOnChanges () {
    this.dataSource = Array(this.numberOfRows).fill(0).map((x, i) => i.toString())
    this.displayedColumns = Array(this.numberOfColumns).fill(0).map((x, i) => i.toString())
  }
}
