import { Injectable } from '@angular/core'
import { BreakpointObserver } from '@angular/cdk/layout'
import { Observable } from 'rxjs'
import { map, shareReplay } from 'rxjs/operators'
import { UiState, ViewportType } from '../../models/helpers.model'

@Injectable({
  providedIn: 'root'
})
export class UiService {
  private uiState$: Observable<UiState>

  constructor(private breakpointObserver: BreakpointObserver) {
    // Initialize uiState$ to reactively map viewport changes to ViewportType
    this.uiState$ = this.breakpointObserver
    .observe([
      // Adding custom breakpoints or using exact dimensions could be more precise
      '(max-width: 599px)', // Handset (typically for phones)
      '(min-width: 600px) and (max-width: 959px)', // Tablet
      '(min-width: 960px)' // Desktop
    ])
      .pipe(
        map(result => {
          if (result.breakpoints['(max-width: 599px)']) {
            return { viewportType: ViewportType.Mobile };
          } else if (result.breakpoints['(min-width: 600px) and (max-width: 959px)']) {
            return { viewportType: ViewportType.Tablet };
          } else {
            return { viewportType: ViewportType.Desktop };
          }
        }),
        shareReplay(1) // Cache the last emitted value for late subscribers
      )
  }

  // Public method to access the observable of UI state
  get uiChange$(): Observable<UiState> {
    return this.uiState$
  }
}
