import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core'
import { FormControl, FormGroup, UntypedFormControl, ValidationErrors, Validators } from '@angular/forms'
import { Subject, takeUntil } from 'rxjs'
import { OrganizationBillingApiResponse } from '../../models/organization.model'
import { FormErrorHandlerService } from '../../services/form-error-handler/form-error-handler.service'

@Component({
  selector: 'shared-billing-form',
  templateUrl: './billing-form.component.html',
  styleUrls: ['./billing-form.component.scss']
})
export class BillingFormComponent implements OnInit, OnDestroy, OnChanges {
  @Input() org_billing: OrganizationBillingApiResponse;
  @Input() validationErrors: ValidationErrors;
  @Output() formUpdate = new EventEmitter<FormGroup>();

  private destroy$ = new Subject<void>();
  
  public form = new FormGroup({
    type: new FormControl<string>('pay-as-you-go'),
    payout_day: new UntypedFormControl(null),
    email: new FormControl<string>('', [Validators.email]),
    payment_term: new FormControl<number>(10, [Validators.required])
  });

  constructor(
    private formErrorHandlerService: FormErrorHandlerService
  ) {}

  ngOnInit(): void {
    this.patchValues(this.org_billing);

    this.form.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.formUpdate.emit(this.form);
      });

    this.formUpdate.emit(this.form);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.validationErrors && changes.validationErrors.currentValue) {
      this.formErrorHandlerService.handleValidationErrors(this.form, changes.validationErrors.currentValue);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private patchValues(billing: Partial<OrganizationBillingApiResponse>) {
    if (!billing) return;
    this.form.patchValue(billing);
    this.handlePayInAdvance(billing.type);
  }

  private handlePayInAdvance(billingType: string) {
    if (billingType && billingType === 'pay-in-advance') {
      this.form.disable();
    }
  }

  public selectBillingType(type: string) {
    const data: Partial<OrganizationBillingApiResponse> = { type };
    this.form.patchValue(data);
  }

  // Function to return list of numbers from 0 to n-1
  public numSequence(n: number): Array<number> {
    return Array(n).fill(0).map((_, idx) => idx);
  }
}