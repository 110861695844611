import { switchMap } from 'rxjs/operators'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

export interface OpenStreeetMapNominatimGeocodingV1Response {
  address: {
    amenity: string
    city?: string
    city_district: string
    country: string
    country_code: string
    county: string
    municipality: string
    neighbourhood: string
    postcode: string
    road: string
    suburb: string
    state?: string
  }
  boundingbox: string[]
  display_name: string
  lat: string
  licence: string
  lon: string
  osm_id: number
  osm_type: string
  place_id: number
}

@Injectable({
  providedIn: 'root'
})
export class LocationService {
  constructor (
    private httpClient: HttpClient
  ) { }

  public getLocationInfoByCoordinates (lat: number, lng: number): Observable<OpenStreeetMapNominatimGeocodingV1Response> {
    const url = 'https://nominatim.openstreetmap.org/reverse.php?lat=' + lat + '&lon=' + lng + '&format=json'
    return this.httpClient.get<OpenStreeetMapNominatimGeocodingV1Response>(url)
  }

  public getGeoLocation () {
    return this.getLatitudeAndLongitude()
      .pipe(
        switchMap(position => this.getLocationInfoByCoordinates(position.coords.latitude, position.coords.longitude))
      )
  }

  private getLatitudeAndLongitude (): Observable<any> {
    const options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0
    }

    return new Observable(observer => {
      if (window.navigator && window.navigator.geolocation) {
        window.navigator.geolocation.getCurrentPosition(
          (position) => {
            observer.next(position)
            observer.complete()
          },
          (error) => observer.error(error),
          options
        )
      } else {
        observer.error('Unsupported Browser')
      }
    })
  }
}
