import { MatSnackBar } from '@angular/material/snack-bar'
import { Component, OnInit } from '@angular/core'
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker'
import { UntypedFormBuilder } from '@angular/forms'
import { Observable, tap } from 'rxjs'
import { HelpersService } from '../../services/helpers/helpers.service'
import { UserService } from '../../services/user/user.service'
import { AuthService } from '../../auth/services/auth.service'
import { StoreService } from '../../services/store/store.service'
import { User } from '../../models/user.model'

@Component({
  selector: 'shared-email-form',
  templateUrl: './email-form.component.html',
  styleUrls: ['./email-form.component.scss']
})
export class EmailFormComponent implements OnInit {
  constructor (
    private helpersService: HelpersService,
    private snackBar: MatSnackBar,
    private fb: UntypedFormBuilder,
    private userService: UserService,
    private authService: AuthService,
    private storeService: StoreService
  ) { }

  form = this.fb.group({
    email: ['']
  })

  user$: Observable<User>

  ngOnInit (): void {
    this.user$ = this.storeService.user$
      .pipe(
        tap(user => this.helpersService.patchValues(this.form, user))
      )
  }

  verifyEmailButtonConfig = this.helpersService.getButtonConfig(_('Verify email'))

  verifyEmail () {
    const email = this.form.get('email').value

    const config = this.verifyEmailButtonConfig

    config.isLoading = true

    // Disable input at click
    this.helpersService.setCountDown(60)
      .subscribe({
        next: (value) => {
          config.isDisabled = true
          config.disabledSecondsRemaining = value
        },
        complete: () => {
          config.isDisabled = false
          this.userService.getUser()
        }
      })

    return this.userService.sendVerificationEmail(email)
      .subscribe({
        next: () => {
          this.snackBar.open(_('Email sent'), null, { panelClass: ['shoutly-snack-bar', 'success'] })
          config.isLoading = false
        },
        error: () => this.snackBar.open(_('Error sending email'), null, { panelClass: ['shoutly-snack-bar', 'error'] })
      })
  }

  saveEmail () {
    const email = this.form.get('email').value

    return this.userService.updateUserData({ email })
      .subscribe()
  }

  resetPassword () {
    const email = this.form.get('email').value
    return this.authService.resetPassword(email)
      .subscribe({
        next: (data) => this.snackBar.open(_(data.message), null, { panelClass: ['shoutly-snack-bar', 'error'] })
      })
  }
}
