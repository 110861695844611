import { Component } from '@angular/core';

@Component({
  selector: 'shared-preferred-chip',
  templateUrl: './preferred-chip.component.html',
  styleUrls: ['./preferred-chip.component.scss']
})
export class PreferredBadgeComponent {

}
