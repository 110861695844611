import { MatSnackBar } from '@angular/material/snack-bar'
import { Component, OnInit } from '@angular/core'
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker'
import { UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms'
import { Observable, tap } from 'rxjs'
import { UserService } from '../../services/user/user.service'
import { StoreService } from '../../services/store/store.service'
import { HelpersService } from '../../services/helpers/helpers.service'
import { User } from '../../models/user.model'
import { PhoneNumberInput } from '../../models/phone.model'
import { ShoutlyEidSubmitSMSOtpErrorResponse, ShoutlyEidTransactionResponse } from '../../auth/models/auth.model'

@Component({
  selector: 'shared-mobile-form',
  templateUrl: './mobile-form.component.html',
  styleUrls: ['./mobile-form.component.scss']
})
export class MobileFormComponent implements OnInit {
  constructor (
    private userService: UserService,
    private storeService: StoreService,
    private snackBar: MatSnackBar,
    private helpersService: HelpersService
  ) { }

  user$: Observable<User>

  enableRetry = false

  phoneNumber: PhoneNumberInput

  validationErrors: ValidationErrors = null

  onSubmitPhone () {
    const mobile = this.phoneNumber.value

    const sendOTPButtonConfig = this.sendOTPButtonConfig

    sendOTPButtonConfig.isLoading = true

    return this.userService.eidMobileStartTransaction(mobile)
      .subscribe({
        next: (transaction: ShoutlyEidTransactionResponse) => {
          this.snackBar.open(_('SMS sent'), null, { panelClass: ['shoutly-snack-bar', 'success'] })
          sendOTPButtonConfig.isLoading = false
          this.transaction_id = transaction.id
        }
      })
  }

  public patchPhoneControl (phoneNumber: PhoneNumberInput) {
    this.form.get('mobile').patchValue(phoneNumber.value)
    if (!phoneNumber.isValid) this.form.get('mobile').setErrors({ incorrect: true })
  }

  ngOnInit (): void {
    this.user$ = this.storeService.user$
      .pipe(
        tap(user => this.refreshComponent(user.mobile))
      )
  }

  transaction_id = null

  form = new UntypedFormGroup({
    mobile: new UntypedFormControl(null, Validators.required),
    otp: new UntypedFormControl(null, [Validators.required, Validators.minLength(6), Validators.maxLength(6)])
  })

  sendOTPButtonConfig = this.helpersService.getButtonConfig(_('Send SMS'))

  validateOTPButtonConfig = this.helpersService.getButtonConfig(_('Validate'))

  sendOTP () {
    const mobile = this.form.get('mobile').value

    const sendOTPButtonConfig = this.sendOTPButtonConfig

    sendOTPButtonConfig.isLoading = true

    return this.userService.eidMobileStartTransaction(mobile)
      .subscribe({
        next: (transaction: ShoutlyEidTransactionResponse) => {
          this.snackBar.open(_('SMS sent'), null, { panelClass: ['shoutly-snack-bar', 'success'] })
          sendOTPButtonConfig.isLoading = false
          this.transaction_id = transaction.id
        }
      })
  }

  disableButtonCountdown (validateOTPButtonConfig, seconds = 3) {
    validateOTPButtonConfig.isDisabled = true
    // Disable input at click
    this.helpersService.setCountDown(seconds)
      .subscribe({
        next: (value) => {
          validateOTPButtonConfig.disabledSecondsRemaining = value
        },
        complete: () => {
          validateOTPButtonConfig.isDisabled = false
          validateOTPButtonConfig.isLoading = false
        }
      })
  }

  validateOTP () {
    const otp = this.form.get('otp')
    const transaction_id = this.transaction_id
    const validateOTPButtonConfig = this.validateOTPButtonConfig

    this.disableButtonCountdown(validateOTPButtonConfig)

    return this.userService.eidMobileCheckTransaction(transaction_id, otp.value)
      .subscribe({
        next: () => this.snackBar.open(_('Great! Mobile verified'), null, { panelClass: ['shoutly-snack-bar', 'success'] }),
        error: (err: ShoutlyEidSubmitSMSOtpErrorResponse) => {
          otp.setErrors({
            serverError: `${err.attemptsLeft} ` + _('attempts left')
          })

          if (err.attemptsLeft === 0) this.enableRetry = true
        },
        complete: () => this.refreshComponent(this.form.get('mobile').value)
      })
  }

  refreshComponent (mobile) {
    this.enableRetry = false
    this.validateOTPButtonConfig.isLoading = false
    this.transaction_id = null
    this.form.get('mobile').patchValue(mobile)
  }
}
