<div class="language-selector" [ngClass]="panelClass">
  <button mat-button color="warning" [matMenuTriggerFor]="langMenu" #menuTrigger>
    <div class="lang-item" *ngIf="user?.language as lang">
      <img *ngIf="!isLoading" width="20" class="lang-flag" [src]="'assets/UI/flags/' + lang + '.svg'">
      <mat-spinner *ngIf="isLoading" diameter="20"></mat-spinner>
      <shared-expansion-arrow-rotate [open]="trigger?.menuOpen"></shared-expansion-arrow-rotate>
    </div>
  </button>

  <mat-menu [yPosition]="position" xPosition="before" #langMenu="matMenu">
    <ng-container *ngIf="!isLoading">
      <div class="language-options" mat-menu-item *ngFor="let lang of ngxLangugages" (click)="selectLang(lang.slug)">
        <div class="lang-item">
          <img class="lang-flag" width="20" [src]='"assets/UI/flags/"+ lang.slug + ".svg"'>
          <span class="lang-text">{{ lang.name | titlecase }}</span>
        </div>
      </div>
    </ng-container>
  </mat-menu>
</div>