import { Component, Output, EventEmitter, Input } from '@angular/core'
import { AuthParam } from '../../auth/models/auth.model'

@Component({
  selector: 'shared-auth-provider-select',
  templateUrl: './auth-provider-select.component.html',
  styleUrls: ['./auth-provider-select.component.scss']
})
export class AuthProviderSelectComponent {
  @Output() selectedEvent = new EventEmitter<string>()
  @Input() providers: AuthParam[] = []
  @Input() selectedProvider: string = null

  changeAttrs = {
    width: '30',
    height: '30',
    stroke: 'red'
  }

  emitProvider (provider: string) {
    return this.selectedEvent.emit(provider)
  }
}
