/* Assemblied in top of https://www.npmjs.com/package/@finsweet/cookie-consent */
import { DOCUMENT } from '@angular/common'
import { AfterViewInit, Component, Inject, OnDestroy, Renderer2 } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { PrivacyPreferencesDialogComponent } from '../../dialogs/privacy-preferences-dialog/privacy-preferences-dialog.component'
@Component({
  selector: 'app-cookie-consent',
  templateUrl: './cookie-consent.component.html',
  styleUrls: ['./cookie-consent.component.scss']
})
export class CookieConsentComponent implements AfterViewInit, OnDestroy {
  isScriptLoaded = false;
  preferences = {
    marketing: false,
    personalization: false,
    analytics: false
  };
  private scriptElement: HTMLScriptElement | null = null;

  constructor(
    public dialog: MatDialog,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document
  ) { }

  ngAfterViewInit(): void {
    this.loadScript()
  }

  ngOnDestroy(): void {
    // Remove dynamically added script
    if (this.scriptElement) {
      this.renderer.removeChild(this.document.body, this.scriptElement)
    }

    // Any other clean-up logic, like unsubscribing from observables, can be added here
    console.log('CookieConsentComponent destroyed and cleaned up.')
  }

  private loadScript() {
    this.scriptElement = this.renderer.createElement('script')
    this.scriptElement.type = 'text/javascript'
    this.scriptElement.async = true
    this.scriptElement.src = 'https://cdn.jsdelivr.net/npm/@finsweet/cookie-consent@1/fs-cc.js'
    this.scriptElement.setAttribute('fs-cc-mode', 'opt-in')
    // this.scriptElement.setAttribute('fs-cc-debug', 'true');

    this.renderer.appendChild(this.document.body, this.scriptElement)

    this.scriptElement.onload = () => {
      this.isScriptLoaded = true
      this.loadPreferences()
    }

    this.scriptElement.onerror = () => {
      console.error('An issue occurred during cookie management load.')
    }
  }

  private loadPreferences() {
    window['FsCC'] = window['FsCC'] || []
    window['FsCC'].push((FsCC) => {
      const consents = FsCC.store.getConsents()
      this.preferences = {
        marketing: consents.marketing,
        personalization: consents.personalization,
        analytics: consents.analytics
      }
      if (this.preferences.analytics) {
        this.dispatchAnalyticsActivatedEvent()
      }
    })
  }

  public openDialog(): void {
    const dialogRef = this.dialog.open(PrivacyPreferencesDialogComponent, {
      width: '480px',
      data: { preferences: this.preferences }
    })

    dialogRef.afterClosed()
      .subscribe(result => {
        if (result) {
          this.handleDialogClose(result)
        }
      })
  }

  public handleDialogClose(result: any) {
    if (result.action === 'allow') {
      this.updateConsents({
        marketing: true,
        personalization: true,
        analytics: true
      })
    } else if (result.action === 'deny') {
      this.updateConsents({
        marketing: false,
        personalization: false,
        analytics: false
      })
    } else if (result.action === 'submit') {
      this.updateConsents(result.preferences)
    }
  }

  private updateConsents(consents: any) {
    this.preferences = consents // Update the local preferences

    window['FsCC'] = window['FsCC'] || []
    window['FsCC'].push((FsCC) => {
      FsCC.consentController.updateConsents(consents)
      // console.log('Consents updated:', consents)

      if (consents.analytics) {
        this.dispatchAnalyticsActivatedEvent()
      }
    })
  }

  private dispatchAnalyticsActivatedEvent() {
    const event = new CustomEvent('analytics-activated', {
      detail: { message: 'Analytics consent granted.' }
    })
    window.dispatchEvent(event)
    // console.log('Dispatched analytics-activated event')
  }
}
