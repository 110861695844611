import { Injectable } from '@angular/core'
import { combineLatest, distinctUntilChanged, map, Observable, startWith, Subject } from 'rxjs'
import { User } from '../../models/user.model'
import { Organization, BillingSettings, OrganizationPayoutApiResponseV2, OrganizationPayoutStoreV2, OrganizationBillingApiResponse } from '../../models/organization.model'
import { Billing } from '../../models/billing.model'
import { LocalStorageService } from '../local-storage/local-storage.service'
import { BrowserInfoService } from '../browser-info/browser-info.service'

export interface ButtonAllowanceRules {
  org_type: string
  role_slug: string
  org_ids?: number[]
}

export interface SettingsGenericData {
  user?: User
  org?: Organization
  org_payout?: OrganizationPayoutStoreV2 | null
  org_billing?: Billing | null
}

@Injectable({
  providedIn: 'root'
})
export class StoreService {

  constructor (
    private localStorageService: LocalStorageService,
    private browserInfoService: BrowserInfoService
  ) {}

  private userSubject$ = new Subject<User>()
  public get user$(): Observable<User> {
    return this.userSubject$.asObservable()
      .pipe(
        distinctUntilChanged(),
        startWith(this.getCachedUser()),
        map(user => user ? user : this.getDefaultUser())
      )
  }

  private organizationSubject$ = new Subject<Organization>()
  public get organization$(): Observable<Organization> {
    return this.organizationSubject$.asObservable()
      .pipe(
        distinctUntilChanged(),
        startWith(this.getCachedOrganization())
      )
  }
  private orgPayoutSubject$ = new Subject<OrganizationPayoutApiResponseV2>()
  public get orgPayout$(): Observable<OrganizationPayoutApiResponseV2> {
    return this.orgPayoutSubject$.asObservable()
      .pipe(
        distinctUntilChanged(),
        startWith(this.getCachedOrgPayout())
      )
  }
  private orgBillingSubject$ = new Subject<BillingSettings>()
  public get orgBilling$(): Observable<BillingSettings> {
    return this.orgBillingSubject$.asObservable()
      .pipe(
        distinctUntilChanged(),
        startWith(this.getCachedOrgBilling())
      )
  }

  private orgsMemberInSubject$ = new Subject<Organization[]>()
  public orgsMemberIn$ = this.orgsMemberInSubject$.asObservable()
    .pipe(
      distinctUntilChanged(),
      startWith(null)
    )


  public get currentOrgType (): string {
    return this.getCachedOrganization()?.type
  }

  public get sessionData$(): Observable<SettingsGenericData> {
    return combineLatest([
      this.user$,
      this.organization$,
      this.orgPayout$,
      this.orgBilling$
    ])
      .pipe(
        map(([user, org, org_payout, org_billing]) => {
          return { user, org, org_payout, org_billing }
        })
      )
  }

  public cacheUserData(data: User): void {
    this.localStorageService.setItem('user', data)
    this.userSubject$.next(data)
  }

  public cacheOrganization(data: Organization): void {
    this.localStorageService.setItem('org', data)
    this.organizationSubject$.next(data)
  }

  public cacheOrgPayout(data: OrganizationPayoutApiResponseV2): void {
    this.localStorageService.setItem('org_payout', data)
    this.orgPayoutSubject$.next(data)
  }

  public cacheOrgBilling(data: Billing): void {
    this.localStorageService.setItem('org_billing', data)
    this.orgBillingSubject$.next(data)
  }

  public cacheOrgsMemberIn(data: Organization[]) {
    this.orgsMemberInSubject$.next(data)
  }

  public clearUserCache(): void {
    this.localStorageService.removeItem('user')
    this.userSubject$.next(null)
  }
  
  public clearOrganizationCache(): void {
    this.localStorageService.removeItem('org')
    this.organizationSubject$.next(null)
  }
  
  public clearOrgPayoutCache(): void {
    this.localStorageService.removeItem('org_payout')
    this.orgPayoutSubject$.next(null)
  }
  
  public clearOrgBillingCache(): void {
    this.localStorageService.removeItem('org_billing')
    this.orgBillingSubject$.next(null)
  }

  public getCachedUser(): User {
    const user = this.localStorageService.getItem<User>('user')
    return user ? user : this.getDefaultUser()
  }

  public getCachedOrganization(): Organization {
    return this.localStorageService.getItem<Organization>('org')
  }

  public getCachedOrgPayout(): OrganizationPayoutApiResponseV2 {
    return this.localStorageService.getItem<OrganizationPayoutApiResponseV2>('org_payout')
  }

  public getCachedOrgBilling(): OrganizationBillingApiResponse {
    return this.localStorageService.getItem<OrganizationBillingApiResponse>('org_billing')
  }

  public getAllowanceRules(): Observable<ButtonAllowanceRules> {
    return combineLatest([this.user$, this.organization$])
      .pipe(
        map(([user, organization]) => {
          if (user && organization) {
            return {
              org_type: organization.type,
              role_slug: user.role.slug,
            }
          } else {
            return {
              org_type: 'gigger',
              role_slug: 'author',
            }
          }
        })
      )
  }

  public clearAllData(){
    // break all observables
    this.userSubject$.next(null)
    this.organizationSubject$.next(null)
    this.orgPayoutSubject$.next(null)
    this.orgBillingSubject$.next(null)
    this.orgsMemberInSubject$.next(null)
    
    return this.localStorageService.clear()
  }

  private getDefaultUser(): User {
    const detectedLanguage = this.browserInfoService.getUserLanguage().split('-')[0];
    const availableLanguages = ['en', 'es', 'sv'];
    const language = availableLanguages.includes(detectedLanguage) ? detectedLanguage : 'en';

    const DEFAULT_USER: User = {
      id: -1,
      first_name: null,
      last_name: null,
      email: null,
      language: language,
      created_at: null,
      updated_at: null,
      email_verified_at: null,
      current_org: -1,
      auth: null,
      name: null,
      avatar: null,
      personal_number: null,
      role: {
        id: -1,
        name: null,
        slug: null,
        created_at: null,
        updated_at: null
      },
      position: null,
      mobile: null,
      email_verified: null,
      guide_seen: null,
    };

    return DEFAULT_USER
  }
}
