<ng-container *ngIf="user">
    <form [formGroup]="form">
        <mat-form-field>
            <mat-label>{{'first name' | translate | titlecase}}</mat-label>
            <input type="text" id="user_first_name" matInput formControlName="first_name" placeholder="John" required>
            <mat-error>{{form.get('first_name').errors?.serverError}}</mat-error>
        </mat-form-field>
        <mat-form-field>
            <mat-label>{{'last name' | translate | titlecase}}</mat-label>
            <input type="text" id="user_last_name" matInput formControlName="last_name" placeholder="Doe" required>
            <mat-error>{{form.get('last_name').errors?.serverError}}</mat-error>
        </mat-form-field>
    </form>
</ng-container>