<ng-container *ngIf="entity">
  <form [formGroup]="form">

    <div class="ssn-wrapper clickable" *ngIf="displayablePersonalNumber as value"
    (click)="toggleVisibility()">
      <div class="ssn-value">
        {{value}}
      </div>
      <mat-icon 
      [matTooltip]="'Your personal number is verified.' | translate"
      class="ssn-status-icon">verified</mat-icon>
    </div>


    <ng-container *ngIf="sendOTPButtonConfig as conf">
      <button *ngIf="!form.get('personal_number').value" type="submit" mat-stroked-button color="primary"
        [disabled]="isButtonDisabled" (click)="validate()" matPrefix>
        <mat-icon svgIcon="auth_bank_id">
        </mat-icon>
        <ng-container *ngIf="conf.isLoading">
          {{ ('loading' | translate | titlecase) + '...' }}
        </ng-container>
        <ng-container *ngIf="!conf.isLoading">
          {{ conf.disabledSecondsRemaining ? '(' + conf.disabledSecondsRemaining + 's)' : null }} {{ conf.label |
          translate }}
        </ng-container>

      </button>

      <button *ngIf="!!form.get('personal_number').value" type="button" mat-flat-button (click)="validate()">
        <ng-container *ngIf="conf.isLoading">
          {{ ('loading' | translate | titlecase) + '...' }}
        </ng-container>
        <ng-container *ngIf="!conf.isLoading">
          {{ conf.disabledSecondsRemaining ? '(' + conf.disabledSecondsRemaining + 's)' : null }} {{ 'validate again' | translate | ucfirst }}
        </ng-container>
      </button>
    </ng-container>
  </form>
</ng-container>