import { MatDialog } from '@angular/material/dialog'
import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { filter } from 'rxjs'
import { DialogFiltersForTablesComponent } from '../dialog-filters-for-tables/dialog-filters-for-tables.component'
import { TableFiltersService } from '../../services/table-filters/table-filters.service'
import { TableFilterTag } from '../../models/filters.model'

@Component({
  selector: 'shared-table-filters-button-tags',
  templateUrl: './table-filters.component.html',
  styleUrls: ['./table-filters.component.scss']
})
export class TableFiltersComponent implements OnInit, OnDestroy {
  @Input() filterTypes: string[] = []
  @Input() orderByValues: string[] = []

  public filterTags: TableFilterTag[] = null

  constructor (
    private dialog: MatDialog,
    private tableFiltersService: TableFiltersService
  ) {}

  ngOnDestroy (): void {
    // clean filter tags
    this.tableFiltersService.updateTags([])
  }

  ngOnInit (): void {
    this.tableFiltersService.filtersObservable
      .subscribe({
        next: filterTags => { this.handleFiltersUpdate(filterTags) }
      })
  }

  handleFiltersUpdate (tags: TableFilterTag[]) {
    this.filterTags = tags
  }

  showFiltersDialog (): void {
    const filterTypes = this.filterTypes
    const dialogRef = this.dialog.open(DialogFiltersForTablesComponent, {
      data: {
        filterTypes,
        filterTags: this.filterTags,
        orderByValues: this.orderByValues
      }
    })

    dialogRef.afterClosed()
      .pipe(filter(filterOptions => filterOptions))
      .subscribe({
        next: (filterOptions) => this.updateFilterTags(filterOptions)
      })
  }

  public updateFilterTags (filterTags: TableFilterTag[]) {
    this.tableFiltersService.updateTags(filterTags)
  }
}
