// This will output the timestamp equivalent of the date string in seconds.
// Input example: 2023-02-01T23:00:00.000000Z
// Output example: 1654329600 (seconds)

import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'dateToTimestamp'
})
export class DateStringToTimestampPipe implements PipeTransform {
  transform (value: string): number {
    return new Date(value).getTime() / 1000
  }
}
