import { Injectable } from '@angular/core'
import { map, Observable, of, take } from 'rxjs'
import { ApiService } from '../api/api.service'
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker'
import { countries as COUNTRIES_LIST, Country } from 'countries-list'
import { AuthMethod } from '../../auth/models/auth.model'
import { TranslateService } from '@ngx-translate/core'
import { ApiWiseFieldConfiguration, ApiWiseFieldConfigurationField, ApiWiseFieldConfigurationWrapper } from '../../models/settings.model'
import { HttpParams } from '@angular/common/http'

export interface CountryByKey {
  key: string
  value: {
    capital: string
    continent: string
    currency: string
    emoji: string
    emojiU: string
    languages: string[]
    name: string
    native: string
    phone: string
    value: string
  }
}

export interface BaseItem {
  value: string
  viewValue: string
}

export interface BaseCurrency extends BaseItem { }

export interface ShoutlyRolesData {
  id?: string,
  slug: string,
  description: string,
  icon?: string
}

const AUTH_METHODS: AuthMethod[] = [
  {
    name: 'bankid',
    viewName: 'BankID',
    icon: 'assets/logos/bankid_vector_rgb.svg',
    description: _('Scan a QR code with your phone or launch Swedish BankID App')
  },
  {
    name: 'otp',
    viewName: 'One-Time SMS',
    icon: 'assets/UI/auth-provider-otp.svg',
    description: _('Request a 6-digit code and login with phone number')
  },
  {
    name: 'email',
    viewName: 'Email',
    icon: 'assets/UI/auth-provider-email.svg',
    description: _('Login with your email')
  }
]

@Injectable({
  providedIn: 'root'
})
export class DataService {
  constructor(
    private apiService: ApiService,
    private translateService: TranslateService
  ) { }

  feeList: any = {
    paid_by_gigger: 'Gigger',
    paid_by_employer: 'Employer',
    paid_together: 'Together'
  }

  countries: CountryByKey[]

  private knownCurrencies: BaseCurrency[] = [
    { value: 'AUD', viewValue: _('Australian Dollar') },
    { value: 'BRL', viewValue: _('Brazilian Real') },
    { value: 'CAD', viewValue: _('Canadian Dollar') },
    { value: 'CHF', viewValue: _('Swiss Franc') },
    { value: 'CNY', viewValue: _('Chinese Yuan') },
    { value: 'CZK', viewValue: _('Czech Koruna') },
    { value: 'DKK', viewValue: _('Danish Krone') },
    { value: 'EUR', viewValue: _('Euro') },
    { value: 'GBP', viewValue: _('Pound Sterling') },
    { value: 'HKD', viewValue: _('Hong Kong Dollar') },
    { value: 'HUF', viewValue: _('Hungarian Forint') },
    { value: 'IDR', viewValue: _('Indonesian Rupiah') },
    { value: 'INR', viewValue: _('Indian Rupee') },
    { value: 'JPY', viewValue: _('Japanese Yen') },
    { value: 'KRW', viewValue: _('South Korean Won') },
    { value: 'MAD', viewValue: _('Moroccan Dirham') },
    { value: 'MXN', viewValue: _('Mexican Peso') },
    { value: 'NOK', viewValue: _('Norwegian Krone') },
    { value: 'NZD', viewValue: _('New Zealand Dollar') },
    { value: 'PLN', viewValue: _('Polish Zloty') },
    { value: 'RUB', viewValue: _('Russian Ruble') },
    { value: 'SEK', viewValue: _('Swedish Krona') },
    { value: 'SGD', viewValue: _('Singapore Dollar') },
    { value: 'THB', viewValue: _('Thai Baht') },
    { value: 'TRY', viewValue: _('Turkish Lira') },
    { value: 'USD', viewValue: _('US Dollar') },
    { value: 'ZAR', viewValue: _('South African Rand') }
  ]


  timelineStatuses: { value: string }[] = [
    { value: _('timeline_report_received') },
    { value: _('timeline_report_attested') },
    { value: _('timeline_report_declined') },
    { value: _('timeline_transaction_upfront_created') },
    { value: _('timeline_transaction_postpaid_created') },
    { value: _('timeline_bill_generated') },
    { value: _('timeline_bill_paid') },
    { value: _('timeline_selfinvoice_paid') },
    { value: _('timeline_eta_received') },
    { value: _('timeline_incoming_payment_waiting') },
    { value: _('timeline_waiting_recipient_input_to_proceed') },
    { value: _('timeline_processing') },
    { value: _('timeline_funds_converted') },
    { value: _('timeline_outgoing_payment_sent') },
    { value: _('timeline_cancelled') },
    { value: _('timeline_funds_refunded') },
    { value: _('timeline_bounced_back') },
    { value: _('timeline_charged_back') },
    { value: _('timeline_unknown') },
    { value: _('timeline_denied') },
    { value: _('timeline_bill_will_be_paid') },
    { value: _('timeline_wallet_payout_date') },
    { value: _('timeline_success') },
    { value: _('timeline_paid') },
    { value: _('timeline_debited') }
  ]

  eidTransac: any = [
    { value: _('eid_process_status_outstandingTransaction') },
    { value: _('eid_process_status_noClient') },
    { value: _('eid_process_status_started') },
    { value: _('eid_process_status_userSign') },
    { value: _('eid_process_status_pendingOther') },
    { value: _('eid_process_status_expiredTransaction') },
    { value: _('eid_process_status_certificateErr') },
    { value: _('eid_process_status_userCancel') },
    { value: _('eid_process_status_cancelled') },
    { value: _('eid_process_status_startFailed') },
    { value: _('eid_process_status_failedOther') }
  ]

  private countryStateList: {
    name: string,
    abbreviation: string,
    states: {
      name: string,
      abbreviation: string
    }[]
  }[] = [{
    name: 'United States',
    abbreviation: 'US',
    states: [
      {
        name: 'Alabama',
        abbreviation: 'AL'
      },
      {
        name: 'Alaska',
        abbreviation: 'AK'
      },
      {
        name: 'American Samoa',
        abbreviation: 'AS'
      },
      {
        name: 'Arizona',
        abbreviation: 'AZ'
      },
      {
        name: 'Arkansas',
        abbreviation: 'AR'
      },
      {
        name: 'California',
        abbreviation: 'CA'
      },
      {
        name: 'Colorado',
        abbreviation: 'CO'
      },
      {
        name: 'Connecticut',
        abbreviation: 'CT'
      },
      {
        name: 'Delaware',
        abbreviation: 'DE'
      },
      {
        name: 'District Of Columbia',
        abbreviation: 'DC'
      },
      {
        name: 'Federated States Of Micronesia',
        abbreviation: 'FM'
      },
      {
        name: 'Florida',
        abbreviation: 'FL'
      },
      {
        name: 'Georgia',
        abbreviation: 'GA'
      },
      {
        name: 'Guam',
        abbreviation: 'GU'
      },
      {
        name: 'Hawaii',
        abbreviation: 'HI'
      },
      {
        name: 'Idaho',
        abbreviation: 'ID'
      },
      {
        name: 'Illinois',
        abbreviation: 'IL'
      },
      {
        name: 'Indiana',
        abbreviation: 'IN'
      },
      {
        name: 'Iowa',
        abbreviation: 'IA'
      },
      {
        name: 'Kansas',
        abbreviation: 'KS'
      },
      {
        name: 'Kentucky',
        abbreviation: 'KY'
      },
      {
        name: 'Louisiana',
        abbreviation: 'LA'
      },
      {
        name: 'Maine',
        abbreviation: 'ME'
      },
      {
        name: 'Marshall Islands',
        abbreviation: 'MH'
      },
      {
        name: 'Maryland',
        abbreviation: 'MD'
      },
      {
        name: 'Massachusetts',
        abbreviation: 'MA'
      },
      {
        name: 'Michigan',
        abbreviation: 'MI'
      },
      {
        name: 'Minnesota',
        abbreviation: 'MN'
      },
      {
        name: 'Mississippi',
        abbreviation: 'MS'
      },
      {
        name: 'Missouri',
        abbreviation: 'MO'
      },
      {
        name: 'Montana',
        abbreviation: 'MT'
      },
      {
        name: 'Nebraska',
        abbreviation: 'NE'
      },
      {
        name: 'Nevada',
        abbreviation: 'NV'
      },
      {
        name: 'New Hampshire',
        abbreviation: 'NH'
      },
      {
        name: 'New Jersey',
        abbreviation: 'NJ'
      },
      {
        name: 'New Mexico',
        abbreviation: 'NM'
      },
      {
        name: 'New York',
        abbreviation: 'NY'
      },
      {
        name: 'North Carolina',
        abbreviation: 'NC'
      },
      {
        name: 'North Dakota',
        abbreviation: 'ND'
      },
      {
        name: 'Northern Mariana Islands',
        abbreviation: 'MP'
      },
      {
        name: 'Ohio',
        abbreviation: 'OH'
      },
      {
        name: 'Oklahoma',
        abbreviation: 'OK'
      },
      {
        name: 'Oregon',
        abbreviation: 'OR'
      },
      {
        name: 'Palau',
        abbreviation: 'PW'
      },
      {
        name: 'Pennsylvania',
        abbreviation: 'PA'
      },
      {
        name: 'Puerto Rico',
        abbreviation: 'PR'
      },
      {
        name: 'Rhode Island',
        abbreviation: 'RI'
      },
      {
        name: 'South Carolina',
        abbreviation: 'SC'
      },
      {
        name: 'South Dakota',
        abbreviation: 'SD'
      },
      {
        name: 'Tennessee',
        abbreviation: 'TN'
      },
      {
        name: 'Texas',
        abbreviation: 'TX'
      },
      {
        name: 'Utah',
        abbreviation: 'UT'
      },
      {
        name: 'Vermont',
        abbreviation: 'VT'
      },
      {
        name: 'Virgin Islands',
        abbreviation: 'VI'
      },
      {
        name: 'Virginia',
        abbreviation: 'VA'
      },
      {
        name: 'Washington',
        abbreviation: 'WA'
      },
      {
        name: 'West Virginia',
        abbreviation: 'WV'
      },
      {
        name: 'Wisconsin',
        abbreviation: 'WI'
      },
      {
        name: 'Wyoming',
        abbreviation: 'WY'
      }
    ]
  },
  {
    name: 'Canada',
    abbreviation: 'CA',
    states: [
      {
        name: 'Alberta',
        abbreviation: 'AB'
      },
      {
        name: 'British Columbia',
        abbreviation: 'BC'
      },
      {
        name: 'Manitoba',
        abbreviation: 'MB'
      },
      {
        name: 'New Brunswick',
        abbreviation: 'NB'
      },
      {
        name: 'Newfoundland and Labrador',
        abbreviation: 'NL'
      },
      {
        name: 'Northwest Territories',
        abbreviation: 'NT'
      },
      {
        name: 'Nova Scotia',
        abbreviation: 'NS'
      },
      {
        name: 'Nunavut',
        abbreviation: 'NU'
      },
      {
        name: 'Ontario',
        abbreviation: 'ON'
      },
      {
        name: 'Prince Edward Island',
        abbreviation: 'PE'
      },
      {
        name: 'Quebec',
        abbreviation: 'QC'
      },
      {
        name: 'Saskatchewan',
        abbreviation: 'SK'
      },
      {
        name: 'Yukon Territory',
        abbreviation: 'YT'
      }
    ]
  }]

  public getCountryState(country: string): BaseItem[] {
    const countryObject = this.countryStateList.find(x => x.abbreviation === country)

    const res: BaseItem[] = countryObject?.states.map(x => {
      return {
        value: x.abbreviation,
        viewValue: x.name
      }
    })

    if (!countryObject) {
      return []
    }

    return res
  }

  public localeLanguageMap = [
    {
      locale: 'en-US',
      code: 'en',
      name: 'English'
    },
    {
      locale: 'es-419',
      code: 'es',
      name: 'Español'
    },
    {
      locale: 'sv-SE',
      code: 'sv',
      name: 'Svenska'
    }
  ]

  private expensesCategories: BaseItem[] = [
    {
      viewValue: 'tips_and_updates',
      value: _('Representation')
    },
    {
      viewValue: 'print',
      value: _('Office supplies')
    },
    {
      viewValue: 'hotel',
      value: _('Accommodation')
    },
    {
      viewValue: 'airplane_ticket',
      value: _('Travel expenses')
    },
    {
      viewValue: 'more',
      value: _('Other expenses')
    },
    {
      viewValue: 'cell_wifi',
      value: _('Mobile internet')
    }
  ]

  public getAuthMethods() {
    return AUTH_METHODS
  }

  public getFee(value: string) {
    return this.feeList[value]
  }

  public fetchCountries(): Observable<any[]> {
    const res: CountryByKey[] = this.getCountriesByName()
    return of(res)
  }

  /** Field configurations */

  public requestWiseRequirements(currency: string, country: string): Observable<ApiWiseFieldConfiguration[]> {
    let params = new HttpParams()
    params = params.append('currency', currency)
    params = params.append('bank_country', country)

    const commonFieldProperties = {
      name: null,
      type: null,
      refreshRequirementsOnChange: null,
      displayFormat: null,
      example: null,
      minLength: null,
      maxLength: null,
      validationRegexp: null,
      validationAsync: null,
      valuesAllowed: null
    }

    return this.apiService.getWiseRequirements(params)
      .pipe(
        map((data: ApiWiseFieldConfiguration[]) => {
          data.map((item: ApiWiseFieldConfiguration) => {
            const fields: ApiWiseFieldConfigurationField[] = item.fields

            const addFieldIfNotExist = (key: string) => {
              if (!fields.find(x => x.key === key)) {
                fields.push({
                  key,
                  required: true,
                  ...commonFieldProperties
                })
              }
            }

            addFieldIfNotExist('address1')
            addFieldIfNotExist('city')
            addFieldIfNotExist('postcode')

            return item
          })
          return data
        }
        ),
        map((data: ApiWiseFieldConfiguration[]) => {
          // fields.required must be ALWAYS true
          data.map((item: ApiWiseFieldConfiguration) => {
            const fields: ApiWiseFieldConfigurationField[] = item.fields
            for (const field of fields) {
              field.required = true
            }
            return item
          })
          return data
        }),
        map((data: Partial<ApiWiseFieldConfiguration[]>) => {
          // complete missing fields
          const allFields = [
            'legal_type',
            'clearing',
            'bank_country',
            'city',
            'address1',
            'postcode',
            'state',
            'us_routing_number',
            'iban',
            'bic',
            'uk_sort_code',
            'account',
            'account_type',
            'bank_code',
            'institution_number',
            'transit_number',
            'clabe',
            'id_number',
            'ifsc'
          ]
          data.map((item: ApiWiseFieldConfiguration) => {
            const fields: ApiWiseFieldConfigurationField[] = item.fields
            for (const field of allFields) {
              if (!fields.find(x => x.key === field)) {
                fields.push({
                  key: field,
                  required: false,
                  ...commonFieldProperties
                })
              }
            }
            return item
          })
          return data
        }),
        map((data: ApiWiseFieldConfiguration[]) => {
          // if the state is required, add valuesAllowed Data
          data.map((item: ApiWiseFieldConfiguration) => {
            const fields: ApiWiseFieldConfigurationField[] = item.fields
            const stateField = fields.find(x => x.key === 'state')
            // add data ONLY if the state is required AND the data is not already there
            if (stateField.required === true && stateField.valuesAllowed === null) {
              stateField.valuesAllowed = this.getCountryState(country)
                .map(x => {
                  return { key: x.value, name: x.viewValue }
                })
            }
            return item
          })
          return data
        })
      )
  }

  public requestRequirementsAsRequiredBooleans(currency, bank_country): Observable<ApiWiseFieldConfigurationWrapper> {
    return this.requestWiseRequirements(currency, bank_country)
      .pipe(
        map((data: ApiWiseFieldConfiguration[]) => {
          const result = {}
          for (const element of data) {
            const name = element.name
            const fields = element.fields
            result[name] = []
            for (const field of fields) {
              result[name].push({ [field.key]: !!field.required })
            }
          }
          return result
        })
      )
  }

  /* Converts countries object from provider into an array */
  public getCountriesByPhone(): CountryByKey[] {
    const res: CountryByKey[] = []
    // Map object
    for (const [key, val] of Object.entries(COUNTRIES_LIST)) {
      const country: Country = { ...val }

      res.push({ key: country.phone, value: { ...country, value: key } })
    }

    this.countries = this.createCountryByMultiplePhonePrefix(res)

    return this.countries
  }

  /* Create new Country object for comma separated phones */
  private createCountryByMultiplePhonePrefix(countries: CountryByKey[]): CountryByKey[] {
    // if key has comma, separate entrys
    countries.forEach((country, index) => {
      // create new entry for each phone prefix
      if (country.key.includes(',')) {
        country.value?.phone?.split(',').forEach((prefix: string) =>
          countries.push({ key: prefix, value: { ...country.value, phone: prefix } })
        )

        // remove original entry
        countries.splice(index, 1)
      }
    })

    return countries
  }

  /* Sorts */

  private sortCountriesByName(country) {
    return country.sort((a, b) => (a.key > b.key) ? 1 : (b.key > a.key ? -1 : 0))
  }

  /* Converts countries object from provider into an array */
  public getCountriesByName() {
    let res: CountryByKey[] = []

    // Map object
    for (const [key, val] of Object.entries(COUNTRIES_LIST)) {
      const country: Country = { ...val }
      res.push({ key: country.name, value: { ...country, value: key } })
    }

    // Sort result
    res = this.sortCountriesByName(res)

    this.countries = res

    return res
  }

  public getCurrencies(): Observable<BaseCurrency[]> {
    const currencies = this.knownCurrencies
    return of(currencies).pipe(take(1))
  }

  private getLocaleByCode(code: string): string {
    return this.localeLanguageMap.find(el => el.code === code)?.locale
  }

  public getLocale(): string {
    const lang = this.translateService.currentLang
    return this.getLocaleByCode(lang)
  }

  public getExpensesCategories(): Observable<BaseItem[]> {
    return of(this.expensesCategories)
  }

  public getPayoutMethods(currency: string): Observable<string[]> {
    if (!currency) throw new Error('Currency is required')
    return this.apiService.getPayoutMethods(currency)
  }

  private rolesData: ShoutlyRolesData[] = [
    {
      id: '1',
      slug: 'owner',
      description: _('Creator and administrator of the organization. Can create and delete departments. Can do everything else.')
    },
    {
      id: '2',
      slug: 'admin',
      description: _('Administrator of the organization. Can create and delete departments. Can do everything else.')
    },
    {
      id: '3',
      slug: 'editor',
      description: _('Editor of the organization. Can view, edit and delete most of the content. Can send invitations and create collaborations. Cannot edit company settings. Can attest.')
    },
    {
      id: '4',
      slug: 'author',
      description: _('Author of the organization. Can view its content. Can send invitations and create collaborations. Cannot edit company settings. Cannot attest.')
    },
    {
      id: '5',
      slug: 'contributor',
      description: _('Contributor of the organization. Can view its content. Can invite or delete giggers.')
    },
    {
      id: '6',
      slug: 'subscriber',
      description: _('Subscriber of the organization. Can view it\'s own content. Can create and edit time reports and expenses.')
    }]

  public getRolesData(): ShoutlyRolesData[] {
    return this.rolesData
  }
}
