import { Injectable } from '@angular/core'
import { Observable, map, switchMap, tap } from 'rxjs'
import { ShoutlyEidAddTransactionRequest, ShoutlyEidAddTransactionResponse, ShoutlyEidSubmitSMSOtpErrorResponse, ShoutlyEidTransactionResponse } from '../../auth/models/auth.model'
import { ApiService } from '../api/api.service'
import { HttpHeaders } from '@angular/common/http'
import { ReCaptchaV3Service } from 'ng-recaptcha-2'

@Injectable({
  providedIn: 'root'
})
export class ScriveEidService {
  constructor(
    private apiService: ApiService,
    private recaptchaV3Service: ReCaptchaV3Service
  ) { }

  public last_transaction_id: string

  public getToken(body: ShoutlyEidAddTransactionRequest): Observable<ShoutlyEidAddTransactionResponse> {
    return this.recaptchaV3Service.execute('authEidAddToken')
      .pipe(
        switchMap(recaptchaToken => {
          const headers = new HttpHeaders()
            .set('g-recaptcha-response', recaptchaToken)
          return this.apiService.eidAddWithRefferalAndProvider(body, headers)
        }),
        tap(data => { this.last_transaction_id = data.id })
      )
  }

  /* change transaction.status into "started" */
  public eidStart(personal_number: string | null = null): Observable<ShoutlyEidTransactionResponse> {
    const eid = this.last_transaction_id
    let body = {}

    if (personal_number) {
      body = { personal_number }
    }

    return this.recaptchaV3Service.execute('authEidStart')
      .pipe(
        switchMap(recaptchaToken => {
          const headers = new HttpHeaders()
            .set('g-recaptcha-response', recaptchaToken)
          return this.apiService.eidStart(eid, body, headers)
        })
      )
  }

  /* get transaction and save it into observable */
  public getStatus(transaction_id: string, fakeSSN: string | null = null): Observable<ShoutlyEidTransactionResponse> {
    this.last_transaction_id = transaction_id

    let headers = new HttpHeaders()

    if (fakeSSN) {
      headers = headers.append('fakeSSN', fakeSSN.toString())
    }

    return this.recaptchaV3Service.execute('authEidGetStatus')
      .pipe(
        switchMap(recaptchaToken => {
          headers = headers.append('g-recaptcha-response', recaptchaToken)
          return this.apiService.eidGet(transaction_id, headers)
        })
      )
  }

  /** Submit One-time password received by sms */
  public eidSubmitOTPCode(code: string, transaction_id: string): Observable<ShoutlyEidSubmitSMSOtpErrorResponse | ShoutlyEidTransactionResponse> {
    this.last_transaction_id = transaction_id
    let body = {}

    if (code) {
      body = { code }
    }

    return this.recaptchaV3Service.execute('authEidSubmit')
      .pipe(
        switchMap(recaptchaToken => {
          const headers = new HttpHeaders()
            .set('g-recaptcha-response', recaptchaToken)

          return this.apiService.eidSubmitOTP(transaction_id, body, headers)
            .pipe(
              /** handle code invalid as error */
              map(data => {
                if ((data as ShoutlyEidSubmitSMSOtpErrorResponse)?.oneTimeCodeValid === false) {
                  throw {
                    error: {
                      err_type: 'InvalidCode',
                      err_mess: `${(data as ShoutlyEidSubmitSMSOtpErrorResponse).attemptsLeft}`
                    }
                  }
                }
                return data
              })
            )
        })
      )
  }
}