import { Pipe, PipeTransform } from '@angular/core'

const DECIMAL_PLACES = 1
const SUFFIXES = ['K', 'M', 'B', 'T']

@Pipe({
  name: 'abbreviateNumber'
})
export class AbbreviateNumberPipe implements PipeTransform {
  /**
     * @param {?} value
     * @param {?=} decPlaces
     * @return {?}
     */
  private abbreviateNumber (value, decPlaces = 0) {
    /** @type {?} */
    let abbreviationNumber = Math.round(value).toString()
    /** @type {?} */
    const suffixesCount = SUFFIXES.length
    decPlaces = Math.pow(10, decPlaces)
    for (let i = suffixesCount - 1; i >= 0; i--) {
      /** @type {?} */
      const size = Math.pow(10, (i + 1) * 3)
      if (size <= value) {
        abbreviationNumber = (Math.round(value * decPlaces / size) / decPlaces).toString()
        abbreviationNumber += SUFFIXES[i]
        break
      }
    }
    return abbreviationNumber
  }

  /**
     * @param {?} value
     * @param {?=} decimalPlaces
     * @return {?}
     */
  transform (value, decimalPlaces = DECIMAL_PLACES) {
    /** @type {?} */
    let sign = ''
    /** @type {?} */
    const absoluteValue = Math.abs(+value)
    if (value < 0) {
      sign = '-'
    }
    return `${sign}${this.abbreviateNumber(absoluteValue, +decimalPlaces || DECIMAL_PLACES)}`
  }
}
