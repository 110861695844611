import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

export interface themeItem{
  name: string;
  colors: string[];
}

@Injectable({
  providedIn: 'root'
})
export class ThemingService {
  constructor () {}

  /* Each theme has a name and a list of colors. */
  private themes: themeItem[] = [{
    name: 'default-theme',
    colors: ['#E05741', '#184178', '#F1C5BF', '#90CAF9', '#BBDEFB', '#E3F2FD'] // [1] '#2E5CB0'
  },
  {
    name: 'dark-theme',
    colors: ['#1c283f', '#1976D2', '#1565C0', '#0D47A1']
  },
  {
    name: 'marginalen-theme',
    colors: ['#D0343E', '#FF9800', '#FFEB3B', '#8BC34A', '#4CAF50', '#2196F3', '#1976D2']
  },
  {
    name: 'turnconsulting-theme',
    colors: ['#8acec9', '#4a5d70', '#FF9800', '#FF5722']
  }]

  public themeName$ = new BehaviorSubject<string>('default-theme')

  /* Get a list of all available themes */
  public getAvailableThemes (): themeItem[] {
    return this.themes
  }

  /* Get a list of current theme */
  public getCurrentTheme (): themeItem {
    return this.themes.find(theme => theme.name === this.themeName$.value)
  }

  /* update the current theme */
  public setTheme (themeName: string) {
    if (!themeName) return
    return this.themeName$.next(themeName)
  }
}
