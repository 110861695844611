import { ValidationErrors } from '@angular/forms'
import { Component, EventEmitter, Input, Output } from '@angular/core'
import { FileUploadService } from 'projects/shared-lib/src/lib/services/file-upload/file-upload.service'
import { filter, tap } from 'rxjs'
import { HttpEventType } from '@angular/common/http'
import { MatSnackBar } from '@angular/material/snack-bar'
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker'

export interface ProgressUploadFile extends File {
  progress: number,
  id?: number,
  file: string
}

@Component({
  selector: 'shared-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent {
  @Input() validationErrors: ValidationErrors = null
  @Input() model: 'collab' | 'expense' | 'collab_import' = 'collab'
  @Input() maxFiles: number = 10
  @Input() accept: string[] = ['jpg', 'jpeg', 'png', 'pdf', 'doc', 'docx', 'xls', 'xlsx', 'csv', 'txt', 'tar', 'zip']
  @Output() response = new EventEmitter<number[]>()
  @Output() _response = new EventEmitter<any[]>()

  constructor (
    private fileUploadService: FileUploadService,
    private snackBar: MatSnackBar
  ) { }

  files: ProgressUploadFile[] = []

  maxSizePerFile = 2 // 2mb

  get validExtensionsComma () {
    return this.accept.join(', ')
  }

  /**
   * on file drop handler
   */
  onFileDropped ($event) {
    this.prepareFilesList($event)
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler (target) {
    const files = target.files
    this.prepareFilesList(files)
  }

  uploadFile (file: ProgressUploadFile) {
    if(!file) return
    
    const model = this.model
    const index = this.files.indexOf(file)

    return this.fileUploadService.uploadFile(file, model)
      .pipe(
        tap(data => {
          if (data.type === HttpEventType.UploadProgress) {
            this.handleFileUploadProgess(data, index)
          }
          if (data.type === HttpEventType.Response) {
            this.handleFileResponse(data, index)
          }

          this.files[index].progress = 100
        }),
        filter(data => data.type === HttpEventType.Response)
      )
      .subscribe({
        next: res => {
          this.emitResponse()
        },
        error: () => {
          const message = _('Error uploading file')
          this.snackBar.open(message, null, { panelClass: ['shoutly-snack-bar', 'error'] })
          this.files.splice(index, 1)
        }
      })
  }

  handleFileUploadProgess (data, index) {
    const percentDone = Math.round(100 * data.loaded / data.total) // data.loaded / (data.total || data.loaded) * 100
    this.files[index].progress = percentDone
  }

  handleFileResponse (data, index) {
    this.files[index].id = data.body.id
    this.files[index].file = data.body.file
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList (files: ProgressUploadFile[]) {
    for (const item of files) {
      item.progress = 0
      this.files.push(item)
      this.uploadFile(item)
    }
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes (bytes, decimals = 2) {
    if (bytes === 0) {
      return '0 Bytes'
    }
    const k = 1024
    const dm = decimals <= 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
  }

  downloadFile (index: number) {
    const fileUrl = this.files[index].file
    window.open(fileUrl, '_blank')
  }

  deleteFile (index: number) {
    this.fileUploadService.deleteFile(this.files[index].id)
      .subscribe({
        next: () => {
          this.files.splice(index, 1)
          this.emitResponse()
        },
        error: () => {
          const message = _('Error removing file')
          this.snackBar.open(message, null, { panelClass: ['shoutly-snack-bar', 'error'] })
        }
      })
  }

  emitResponse () {
    const res = this.files.map(file => file.id)
    this.response.emit(res)

    const _res = this.files
    this._response.emit(_res)
  }
}
