<form>
    <h3 mat-dialog-title>{{data.title | titlecase}}</h3>
    <div mat-dialog-content>
        <mat-form-field>
          <mat-label>{{data.title}}</mat-label>
          <input matInput [formControl]="input">
        </mat-form-field>
      </div>
      <div mat-dialog-actions>
        <button mat-stroked-button color="primary" [mat-dialog-close]="false">{{'cancel' | translate | uppercase}}</button>
        <button mat-flat-button type="submit" color="primary" [mat-dialog-close]="input.value" cdkFocusInitial>{{'submit' | translate | uppercase}}</button>
      </div>
</form>
