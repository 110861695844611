import { Injectable } from '@angular/core'
import { MatIconRegistry } from '@angular/material/icon'
import { DomSanitizer } from '@angular/platform-browser'

@Injectable({
  providedIn: 'root'
})
export class IconsService {

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) { }

  public registerIcons(): void {
    this.matIconRegistry.addSvgIcon(
      'auth_bank_id',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/logos/bankid_vector_rgb.svg')
    )

    this.matIconRegistry.addSvgIcon(
      'auth_email',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/UI/auth-provider-email.svg')
    )

    this.matIconRegistry.addSvgIcon(
      'auth_otp',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/UI/auth-provider-otp.svg')
    )

    this.matIconRegistry.addSvgIcon(
      'ui_adjust',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/UI/filters-icon.svg')
    )

    this.matIconRegistry.addSvgIcon(
      'person_outline',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/UI/dashboard-active-collaborations.svg')
    )

    this.matIconRegistry.addSvgIcon(
      'person_plus_person',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/UI/dashboard-total-collaborations.svg')
    )

    this.matIconRegistry.addSvgIcon(
      'ui_wallet',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/UI/dashboard-total-saving.svg')
    )

    this.matIconRegistry.addSvgIcon(
      'sidebar-dashboard',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/UI/sidebar/dashboard-icon.svg')
    )

    this.matIconRegistry.addSvgIcon(
      'sidebar-analytics',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/UI/sidebar/analytics.svg')
    )

    this.matIconRegistry.addSvgIcon(
      'sidebar-collaborations',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/UI/sidebar/collaborations-icon.svg')
    )

    this.matIconRegistry.addSvgIcon(
      'sidebar-spreadsheets-upload',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/UI/sidebar/collaborations-icon.svg')
    )

    this.matIconRegistry.addSvgIcon(
      'sidebar-reporting',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/UI/sidebar/attestation-icon.svg')
    )

    this.matIconRegistry.addSvgIcon(
      'sidebar-counterpart',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/UI/sidebar/organization-icon.svg')
    )

    this.matIconRegistry.addSvgIcon(
      'sidebar-team',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/UI/sidebar/departments-icon.svg')
    )

    this.matIconRegistry.addSvgIcon(
      'sidebar-transactions',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/UI/sidebar/billing-icon.svg')
    )

    this.matIconRegistry.addSvgIcon(
      'sidebar-bills',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/UI/sidebar/bills-icon.svg')
    )

    this.matIconRegistry.addSvgIcon(
      'sidebar-balance',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/UI/sidebar/payouts-icon.svg')
    )

    this.matIconRegistry.addSvgIcon(
      'sidebar-self-invoices',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/UI/sidebar/bills-icon.svg')
    )

    this.matIconRegistry.addSvgIcon(
      'sidebar-timesheets',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/UI/sidebar/timesheets-icon.svg')
    )
  }
}
