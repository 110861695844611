import { catchError, map, switchMap, take } from 'rxjs/operators'
import { Injectable } from '@angular/core'
import { Observable, of, throwError } from 'rxjs'
import { MatDialog } from '@angular/material/dialog'
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker'
import { ValidationErrors } from '@angular/forms'
import { HttpErrorResponse, HttpParams } from '@angular/common/http'
import { MatSnackBar } from '@angular/material/snack-bar'
import { StoreService } from '../store/store.service'
import { ApiService } from '../api/api.service'
import { AgreementCreateRequest, AgreementResponse, AgreementType } from '../../models/agreements.model'
import { AgreementDialogComponent } from '../../dialogs/agreement-dialog/agreement-dialog.component'

export interface ParseData {
  [key: string]: string
}

export interface ShoutlyAgreementTemplateConfig {
  slug: string
  name: string
  language?: string
  parsingData?: ParseData // Be careful when using this, uses eval()
}

export interface ShoutlyAgreementDocument {
  id: number
  name: string
  slug: string
  type: string
  provider: string
  status?: string
}

export interface AgreementDialogData {
  title: string
  text?: string
  agreeButtonText?: string
  rejectButtonText?: string
}

const AGREEMENT_TEMPLATES: ShoutlyAgreementTemplateConfig[] = [
  {
    name: 'Employment agreement for swedish giggers',
    slug: 'employment-org-se',
    language: 'sv'
  },
  {
    name: 'User agreement in english',
    slug: 'signup-user',
    language: 'en'
  },
  {
    name: 'User agreement in swedish',
    slug: 'signup-user',
    language: 'sv'
  }
]

@Injectable({
  providedIn: 'root'
})
export class UserAgreementsService {
  constructor (
    private apiService: ApiService,
    private dialog: MatDialog,
    private storeService: StoreService,
    private snackBar: MatSnackBar
  ) { }

  public acceptEmploymentAgreement (country: string = 'se', lang: string = 'sv'): Observable<boolean> {
    return this.displayAgreement(`employment-org-${country}`, lang)
      .pipe(
        // RETURN ERROR IF USER DID NOT ACCEPT AGREEMENT
        switchMap(accepted => !accepted ? this.generateFieldError(['form']) : of(accepted)),
        switchMap(() => this.postAgreement({type: AgreementType.OrganizationAgreement, version: 1, sign: true})),
        map((data: AgreementResponse) => !!data.signed_at)
      )
  }

  private buildAgreementDialogDataFromTemplateConfigSlug (
    template: string,
    slug: string
  ): Observable<AgreementDialogData> {
    const templateConfig = AGREEMENT_TEMPLATES.find(t => t.slug === slug)

    if (!templateConfig) {
      console.error('Template config not found for slug:', slug)
      return throwError(() => 'Template config not found')
    }

    if (typeof template !== 'string') {
      console.error('Expected template to be a string, but received:', typeof template)
      return throwError(() => 'Invalid template type')
    }

    return this.interpolateParsedData(template)
      .pipe(
        map(agreementText => {
          const dialogData: AgreementDialogData = {
            title: templateConfig.name ?? 'Default Title',
            text: agreementText
          }
          return dialogData
        })
      )
  }

  /* Opens a dialog with the agreement text and returns a boolean observable */
  private handleAgreementDialog (data: AgreementDialogData): Observable<boolean> {
    const dialogRef = this.dialog.open(AgreementDialogComponent, {
      disableClose: true,
      data
    })

    return dialogRef.afterClosed()
  }

  public displayAgreement (slug = 'signup-user', lang = 'en'): Observable<boolean> {
    const templateConfig = AGREEMENT_TEMPLATES.find(template => template.slug === slug)

    return this.getAgreementTemplate(templateConfig?.slug, lang)
      .pipe(
        switchMap(agreementTemplate => {
          return this.buildAgreementDialogDataFromTemplateConfigSlug(agreementTemplate, slug)
        }),
        switchMap(dialogData => this.handleAgreementDialog(dialogData)),
        catchError(err => {
          throw err
        }),
        take(1)
      )
  }

  public getAgreementTemplate (type: string, language: string): Observable<string> {
    const name = `${type}-${language}`

    return this.apiService.getAgreementTemplate(name)
  }

  private interpolateParsedData (input: string): Observable<string> {
    if (typeof input !== 'string') {
      console.error('Expected input to be a string, but received:', typeof input)
      return of('') // or choose another default value
    }

    return this.storeService.sessionData$
      .pipe(
        take(1),
        map(data => {
          if (!data) {
            console.error('Data is undefined or null')
            return input // Return the original input as a fallback
          }
      
          const interpolateData = {
            '%ORG_NAME%': data.org?.name ?? '-',
            '%ORG_ADDRESS%': data.org?.address1 ?? '-',
            '%USER_PERSONALNUMBER%': data.user?.personal_number ?? '-'
          }
      
          let result = input
          Object.keys(interpolateData).forEach(key => {
            result = result.replace(key, interpolateData[key])
          })

          return result
        })
      )
  }

  private generateFieldError (fieldNames: string[], cause: string = _('The field was invalid')): Observable<never> {
    // eslint-disable-next-line prefer-const
    let values = {}

    fieldNames.forEach(fieldName => {
      values[fieldName] = [cause]
    })

    const errors: ValidationErrors = { ...values }

    this.snackBar.open(cause, null, { panelClass: ['shoutly-snack-bar', 'error'] })

    return throwError(() => new HttpErrorResponse({ status: 422, error: { errors } }))
  }

  /** Get agreements signature from current org and user */
  public getAgreements({type = null, version = null, target = null}) {
    let params = new HttpParams()

    params = params.append('type', type)
    params = params.append('version', version)
    params = params.append('target', target)

    return this.apiService.getAgreements(params)
  }

  /** Post an agreement signature from current org and user */
  public postAgreement(req: AgreementCreateRequest) {
    return this.apiService.postAgreement(req)
  }
}
