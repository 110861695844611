import { HttpParams } from '@angular/common/http'
import { ApiService } from 'projects/shared-lib/src/lib/services/api/api.service'
import { Injectable } from '@angular/core'
import { PaginatedResponse } from '../../models/paginated.model'
import { map, take, Observable } from 'rxjs'
import { ToDoTaskAPI, ToDoTaskSlugDescription, ToDoTaskTable } from '../../models/to-do-tasks.model'
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker'

const slugMap: ToDoTaskSlugDescription = {
  'collab-report-not-attested': {
    name: _('Collab report not attested'),
    next_action: _('Must attest collab report'),
    route: '/collaborations/details/:id'
  },
  'collab-report-declined': {
    name: _('Collab report declined'),
    next_action: _('Must attest collab report'),
    route: '/collaborations/details/:id'
  },
  'collab-hourly-to-be-reported': {
    name: _('Collab hourly to be reported'),
    next_action: _('Must report collab'),
    route: '/collaborations/details/:id'
  },
  'collab-acceptance-required': {
    name: _('Collab acceptance required'),
    next_action: _('Must accept collab'),
    route: '/collaborations/details/:id'
  },
  'collab-cancellation-required': {
    name: _('Collab cancellation required'),
    next_action: _('Must cancel collab'),
    route: '/collaborations/details/:id'
  },
  'collab-ends-soon': {
    name: _('Collab ends soon'),
    next_action: '',
    route: ''
  },
  'invoice-not-paid': {
    name: _('Invoice not paid'),
    next_action: _('Must pay invoice'),
    route: '/billing/bills'
  },
  'expense-not-attested': {
    name: _('Expense not attested'),
    next_action: _('Must attest expense'),
    route: '/collab-report/expenses'
  }
}

@Injectable({
  providedIn: 'root'
})
export class ToDoTasksService {
  constructor (
    private apiService: ApiService
  ) { }

  getToDoTasks (): Observable<ToDoTaskTable[]> {
    const params = new HttpParams()

    return this.apiService.getToDoTasks(params)
      .pipe(
        take(1),
        // add data from the slug to the response
        map((page: PaginatedResponse<ToDoTaskAPI[]>) => {
          const data: ToDoTaskTable[] = page.data.map((task: ToDoTaskAPI) => {
            return {
              ...task,
              name: slugMap[task.slug].name,
              next_action: slugMap[task.slug].next_action,
              route: slugMap[task.slug].route.replace(':id', task.collab_id)
            }
          })

          return { ...page, data }
        }),
        // hide entries that have no route
        map((page: PaginatedResponse<ToDoTaskTable[]>) => {
          const data: ToDoTaskTable[] = page.data.filter((task: ToDoTaskTable) => task.route !== '')
          return { ...page, data }
        }),
        map(page => page.data)
      )
  }
}
