import { UntypedFormGroup, ValidationErrors } from '@angular/forms'
import { Injectable } from '@angular/core'
import { interval, map, Observable, takeWhile } from 'rxjs'
import { HttpParams } from '@angular/common/http'
import { Params } from '@angular/router'
import { DatePipe } from '@angular/common'
import { DataService } from '../data/data.service'
import * as lodash from 'lodash'

export interface shoutlyButtonConfig{
  label?: string
  lastUpdate?: Date
  isDisabled?: boolean
  disabledSecondsRemaining?: number
  isLoading?: boolean
  isSuccess?: boolean
  isError?: boolean
  isHidden?: boolean
}

export interface AllValidationErrors {
  user?: ValidationErrors
  org?: ValidationErrors
  org_payout?: ValidationErrors
  org_billing?: ValidationErrors
}

@Injectable({
  providedIn: 'root'
})
export class HelpersService {
  constructor (
    private datePipe: DatePipe,
    private dataService: DataService
  ) { }

  public getButtonConfig (label = 'submit'): shoutlyButtonConfig {
    return {
      label,
      lastUpdate: new Date(),
      disabledSecondsRemaining: null,
      isDisabled: false,
      isLoading: false,
      isSuccess: false,
      isError: false,
      isHidden: false
    }
  }

  public setCountDown (delay: number = 60): Observable<number> {
    return interval(1000)
      .pipe(
        map(val => delay - val),
        takeWhile(x => x >= 0)
      )
  }

  patchValues (form: UntypedFormGroup, data) {
    return form.patchValue(data)
  }

  /** TODO TRY THIS OUT */
  public showErrorsOnForm (errors: ValidationErrors, form: UntypedFormGroup): any {
    return Object.keys(errors).forEach(prop => {
      const formControl = form.get(prop)
      if (formControl) {
        formControl.setErrors({
          serverError: errors[prop]
        })
      }
    })
  }

  /* Patch for laravel. Some values as "org_id[]" instead an array */
  private convertStringIntoArrayString (input: string): string {
    return input + '[]'
  }

  /**
   * Helper function to create the HttpParams object for the API call
   * @param requestParams params to be append to the HttpParams object
   * @param paramsToBeArray forces the params to be an array
   * @returns
   * @TODO set an standard type for the requestParams
   */
  public convertParamsIntoHttpParams (requestParams: Params, paramsToBeArray: string[] = []): HttpParams {
    let params = new HttpParams()

    Object.keys(requestParams).forEach(key => {
      if (paramsToBeArray.includes(key) || Array.isArray(requestParams[key])) {
        if (Array.isArray(requestParams[key])) {
          requestParams[key].forEach(value => {
            params = params.append(this.convertStringIntoArrayString(key), value)
          })
        } else {
          params = params.append(this.convertStringIntoArrayString(key), requestParams[key])
        }
      } else {
        params = params.append(key, requestParams[key])
      }
    })

    return params
  }

  /**
   * From a period of four digits number, returns the date in the format YYYY-MM
   * @param period
   * @returns
   */
  public getCollabReportPeriod (period: string): string {
    const locale = this.dataService.getLocale()
    const date = new Date()

    const month = parseInt(period.substring(0, 2))
    const year = parseInt(period.substring(2, 4))

    date.setFullYear(year)
    date.setMonth(month - 1)

    return this.datePipe.transform(date, 'MMM y', undefined, locale)
  }

  public isEquivalent(a: any, b: any): boolean {
    // Special handling for null and empty string equivalency
    if ((a === null && b === '') || (a === '' && b === null)) {
      return true
    }

    // Handle non-object types (including null)
    if ((a === null || typeof a !== 'object') || (b === null || typeof b !== 'object')) {
      return a === b
    }

    function checkAndLogDifferences(obj1: any, obj2: any, prefix = ''): boolean {
      // Handle null objects to prevent TypeError
      if (obj1 === null || obj2 === null) {
        return obj1 === obj2
      }

      for (const key in obj1) {
        if (!obj2.hasOwnProperty(key)) {
          console.log(`Missing property "${key}" in second object.`)
          // console.log(obj1, obj2)
          return false
        }

        const path = prefix + key
        // Continue if the property values are null and empty string equivalency
        if ((obj1[key] === null && obj2[key] === '') || (obj1[key] === '' && obj2[key] === null)) {
          continue
        }

        // Check for nested objects, excluding null for objects
        if (typeof obj1[key] === 'object' && typeof obj2[key] === 'object') {
          if (!checkAndLogDifferences(obj1[key], obj2[key], path + '.')) {
            return false
          }
          continue
        }

        // Use lodash's isEqual for deep comparison of other types
        if (!lodash.isEqual(obj1[key], obj2[key])) {
          console.log(`Difference found at path "${path}":`, obj1[key], '/', obj2[key])
          return false
        }
      }
      return true
    }

    // Compare both ways to catch any unique properties in either object
    return checkAndLogDifferences(a, b) && checkAndLogDifferences(b, a)
  }

}

