
export enum AgreementType {
    UserAgreement = 'user_agreement',
    OrganizationAgreement = 'organization_agreement'
  }

export interface AgreementsListRequest {
    orderby: string // (id organization for now)
    sort: 'asc' | 'desc'
    type: string // the enums we need to define
    version: string // filters by the version you set
    target: string // id of user or organization
}

export interface AgreementCreateRequest {
    type?: AgreementType // some enum that we need to define yet
    version?: number 
    sign?: boolean // either it's sign or not
}

export interface AgreementResponse {
    id: string
    org_id: string
    user_id: string
    type: AgreementType
    version: number
    signed_at: string
    created_at: string
    updated_at: string
}