<form [formGroup]="form" data-testid="bank_settings_form">
    <div formGroupName="wise">
        <div *ngIf="currency">
            <mat-form-field *ngIf="form.get('wise').get('bank_country')">
                <mat-label translate>Bank country</mat-label>
                <mat-select 
                [attr.data-testid]="'org_payout_bank_country'"
                id="org_payout_bank_country" formControlName="bank_country"
                    (selectionChange)="onBankCountryChange($event.value)">
                    <ng-container *ngIf="!isFetching">
                        <mat-option *ngFor="let country of countries"
                        [attr.data-testid]="'org_payout_bank_country_' + country.value?.value"
                        [id]="'org_payout_bank_country_' + country.value?.value"
                        [value]="country.value?.value">
                        {{country.value?.name | translate}}
                    </mat-option>
                    </ng-container>
                </mat-select>
                <mat-error>{{form.get('wise').get('bank_country')?.errors?.serverError}}</mat-error>
            </mat-form-field>

            <mat-progress-bar data-testid="isfetching" mode="buffer" *ngIf="isFetching"
                style="margin-bottom: 15px;"></mat-progress-bar>

            <mat-form-field *ngIf="form.get('wise').get('transfer_type')">
                <mat-label translate>Type of transfer</mat-label>
                <mat-select formControlName="transfer_type" id="org_payout_transfer_type"
                    data-testid="org_payout_transfer_type" (selectionChange)="transferTypeChanges($event.value)">
                    <mat-option *ngFor="let field of settingsFields" [id]="'org_payout_transfer_type_' + field.name"
                        [value]="field.name">
                        {{ field.name.replace('_', ' ') | titlecase }}
                    </mat-option>
                </mat-select>
                <mat-hint *ngIf="!currency || !form.get('wise').value.bank_country" translate>Please choose Currency and
                    Bank Country before</mat-hint>
                <mat-error translate>The currency is invalid for country</mat-error>
            </mat-form-field>
        </div>

        <div
            *ngIf="settingsFields.length && form.get('wise').get('transfer_type')?.value && form.get('wise').get('bank_country')?.value">
            <mat-form-field *ngIf="form.get('wise').get('email')">
                <mat-label>{{'email' | translate | titlecase}}</mat-label>
                <input matInput
                [attr.data-testid]="'org_payout_email'"
                id="org_payout_email" type="email" formControlName="email" placeholder="me@email.com">
                <mat-error>{{form.get('wise').get('email')?.errors?.serverError}}</mat-error>
            </mat-form-field>

            <mat-form-field *ngIf="form.get('wise').get('legal_type')">
                <mat-label translate>{{currentTransferTypeFields.legal_type?.name}}</mat-label>
                <mat-select data-testid="org_payout_legal_type" id="org_payout_legal_type" formControlName="legal_type">
                    <mat-option *ngFor="let legalType of currentTransferTypeFields?.legal_type?.valuesAllowed"
                        [value]="legalType.key">
                        {{legalType?.name}}
                    </mat-option>
                </mat-select>
                <mat-error>{{form.get('wise').get('legal_type')?.errors?.serverError}}</mat-error>
            </mat-form-field>

            <mat-form-field *ngIf="form.get('wise').get('account_holder')">
                <mat-label>{{currentTransferTypeFields.account_holder?.name || 'Account holder name' | translate}}</mat-label>
                <input matInput id="org_payout_account_holder" data-testid="org_payout_account_holder" type="text"
                    formControlName="account_holder" [placeholder]="currentTransferTypeFields.account_holder?.example || 'Account holder name' | translate">
                <mat-error>{{form.get('wise').get('account_holder')?.errors?.serverError}}</mat-error>
            </mat-form-field>

            <mat-form-field *ngIf="form.get('wise').get('account')">
                <mat-label translate>Account Number</mat-label>
                <input matInput id="org_payout_account" data-testid="org_payout_account" type="text"
                    formControlName="account" placeholder="SE7746918820146164399325">
                <mat-error>{{form.get('wise').get('account')?.errors?.serverError}}</mat-error>
            </mat-form-field>

            <mat-form-field *ngIf="form.get('wise').get('clearing')">
                <mat-label translate>Clearing Number</mat-label>
                <input matInput type="text" formControlName="clearing" id="org_payout_clearing"
                    data-testid="org_payout_clearing" placeholder="clearing">
                <mat-error>{{form.get('wise').get('clearing')?.errors?.serverError}}</mat-error>
            </mat-form-field>

            <mat-form-field *ngIf="form.get('wise').get('iban')">
                <mat-label>{{currentTransferTypeFields.iban?.name}}</mat-label>
                <input matInput type="text" formControlName="iban"
                    [placeholder]="currentTransferTypeFields.iban?.example || 'DE89370400440532013000'"
                    id="org_payout_iban" data-testid="org_payout_iban">
                <mat-icon matSuffix
                    matTooltip="{{ 'You can find your IBAN number by logging into your online banking platform or by checking your paper bank statement' | translate }}"
                    aria-label="Displays a tooltip when focused or hovered over">
                    help_outline</mat-icon>
                <mat-error>{{form.get('wise').get('iban')?.errors?.serverError || 'This value looks incorrect'}}</mat-error>
            </mat-form-field>

            <mat-form-field *ngIf="form.get('wise').get('bic')">
                <mat-label translate>BIC number</mat-label>
                <input matInput type="text" formControlName="bic" placeholder="CTBAAU2S" id="org_payout_bic"
                    data-testid="org_payout_bic">
                <mat-icon matSuffix
                    matTooltip="{{ 'You can find your BIC number by logging into your online banking platform or by checking your paper bank statement' | translate }}"
                    aria-label="Displays a tooltip when focused or hovered over">
                    help_outline</mat-icon>
                <mat-error>{{form.get('wise').get('bic')?.errors?.serverError || 'This value looks incorrect'}}</mat-error>
            </mat-form-field>

            <mat-form-field *ngIf="form.get('wise').get('uk_sort_code')">
                <mat-label translate>Sort Code (UK)</mat-label>
                <input matInput type="text" formControlName="uk_sort_code" placeholder="Sort code"
                    id="org_payout_uk_sort_code" data-testid="org_payout_uk_sort_code">
                <mat-error>{{form.get('wise').get('uk_sort_code')?.errors?.serverError || 'This value looks incorrect'}}</mat-error>
            </mat-form-field>

            <mat-form-field *ngIf="form.get('wise').get('us_routing_number')">
                <mat-label>{{currentTransferTypeFields.us_routing_number?.name}}</mat-label>
                <input matInput type="text" formControlName="us_routing_number"
                    [placeholder]="currentTransferTypeFields.us_routing_number?.example || 'Routing number'"
                    id="org_payout_us_routing_number" data-testid="org_payout_us_routing_number">
                <mat-error>{{form.get('wise').get('us_routing_number')?.errors?.serverError || 'This value looks incorrect'}}</mat-error>
            </mat-form-field>

            <mat-form-field *ngIf="form.get('wise').get('account_type')">
                <mat-label>{{currentTransferTypeFields.account_type?.name}}</mat-label>
                <mat-select id="org_payout_account_type" data-testid="org_payout_account_type"
                    formControlName="account_type">
                    <ng-container
                        *ngIf="form.get('wise').get('account_type').value && !currentTransferTypeFields?.account_type?.valuesAllowed?.length">
                        <mat-option [value]="form.get('wise').get('account_type').value">
                            {{form.get('wise').get('account_type').value}}
                        </mat-option>
                    </ng-container>
                    <ng-container *ngIf="currentTransferTypeFields?.account_type?.valuesAllowed as valuesAllowed">
                        <mat-option *ngFor="let opt of valuesAllowed" [id]="'org_payout_account_type_' + opt.key"
                            [value]="opt.key">{{opt.name}}</mat-option>
                    </ng-container>
                </mat-select>
                <mat-error>{{form.get('wise').get('account_type')?.errors?.serverError}}</mat-error>
            </mat-form-field>

            <mat-form-field *ngIf="form.get('wise').get('bank_code')">
                <mat-label>{{currentTransferTypeFields.bank_code?.name}}</mat-label>
                <input matInput type="text" formControlName="bank_code" id="org_payout_bank_code"
                    data-testid="org_payout_bank_code" placeholder="Bank code">
                <mat-error>{{form.get('wise').get('bank_code')?.errors?.serverError}}</mat-error>
            </mat-form-field>

            <mat-form-field *ngIf="form.get('wise').get('institution_number')">
                <mat-label translate>Institution number</mat-label>
                <input matInput type="text" formControlName="institution_number" id="org_payout_institution_number"
                    data-testid="org_payout_institution_number" placeholder="Institution number">
                <mat-error>{{form.get('wise').get('institution_number')?.errors?.serverError}}</mat-error>
            </mat-form-field>

            <mat-form-field *ngIf="form.get('wise').get('transit_number')">
                <mat-label translate>Transit number</mat-label>
                <input matInput type="text" formControlName="transit_number" id="org_payout_transit_number"
                    data-testid="org_payout_transit_number" placeholder="Transit number">
                <mat-error>{{form.get('wise').get('transit_number')?.errors?.serverError}}</mat-error>
            </mat-form-field>

            <mat-form-field *ngIf="form.get('wise').get('state')">
                <mat-label translate>State</mat-label>
                <mat-select data-testid="org_payout_state" id="org_payout_state" formControlName="state">
                    <mat-option *ngFor="let state of currentTransferTypeFields?.state?.valuesAllowed"
                        [id]="'org_payout_state_' + state.key" [value]="state.key">
                        {{ state.name }}
                    </mat-option>
                </mat-select>
                <mat-error>{{form.get('wise').get('state')?.errors?.serverError}}</mat-error>
            </mat-form-field>

            <mat-form-field *ngIf="form.get('wise').get('city')">
                <mat-label>{{ 'city' | translate | titlecase }}</mat-label>
                <input matInput type="text" formControlName="city" id="org_payout_city" data-testid="org_payout_city">

                <!-- *ngIf="org?.country !== form.get('wise').value.bank_country"  -->
                <button 
                class="getGeoLocationButton"
                type="button"
                mat-icon-button matSuffix
                (click)="getGeoLocation($event)" 
                [matTooltip]="'get address' | translate | titlecase">
                    <span class="material-icons-outlined">
                        my_location
                    </span>
                </button>
                <mat-error>{{form.get('wise').get('city')?.errors?.serverError}}</mat-error>
            </mat-form-field>

            <mat-form-field *ngIf="form.get('wise').get('address1')">
                <mat-label>{{ 'address' | translate | titlecase }}</mat-label>
                <input matInput type="text" formControlName="address1" id="org_payout_address1"
                    data-testid="org_payout_address1">
                <mat-error>{{form.get('wise').get('address1')?.errors?.serverError}}</mat-error>
            </mat-form-field>

            <mat-form-field *ngIf="form.get('wise').get('postcode')">
                <mat-label>{{ 'postcode' | translate | titlecase }}</mat-label>
                <input matInput type="text" formControlName="postcode" id="org_payout_postcode"
                    data-testid="org_payout_postcode">
                <mat-error>{{form.get('wise').get('postcode')?.errors?.serverError}}</mat-error>
            </mat-form-field>

            <mat-form-field *ngIf="form.get('wise').get('ifsc')">
                <mat-label translate>IFSC code</mat-label>
                <input matInput type="text" formControlName="ifsc" id="org_payout_ifsc" data-testid="org_payout_ifsc">
                <mat-icon matSuffix
                    matTooltip="{{ 'YIFSC codes are 11 characters, comprised like this: XXXX0YYYYYY.' | translate }}"
                    aria-label="Displays a tooltip when focused or hovered over">
                    help_outline</mat-icon>
                <mat-error>{{form.get('wise').get('ifsc')?.errors?.serverError}}</mat-error>
            </mat-form-field>

            <mat-form-field *ngIf="form.get('wise').get('clabe')">
                <mat-label translate>CLABE code</mat-label>
                <input matInput type="text" formControlName="clabe" id="org_payout_clabe"
                    data-testid="org_payout_clabe">
                <mat-icon matSuffix
                    matTooltip="{{ 'CLABE numbers have 18 digits, divided into 4 sections like this: AAABBBCCCCCCCCCCCD.' | translate }}"
                    aria-label="Displays a tooltip when focused or hovered over">
                    help_outline</mat-icon>
                <mat-error>{{form.get('wise').get('clabe')?.errors?.serverError}}</mat-error>
            </mat-form-field>

            <mat-form-field *ngIf="form.get('wise').get('id_number')">
                <mat-label translate>ID NUMBER code</mat-label>
                <input matInput type="text" formControlName="id_number" id="org_payout_id_number"
                    data-testid="org_payout_id_number">
                <mat-error>{{form.get('wise').get('id_number')?.errors?.serverError}}</mat-error>
            </mat-form-field>
        </div>
    </div>
</form>