import { BankidDialogComponent } from '../../dialogs/bankid-dialog/bankid-dialog.component'
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker'
import { Subject, Observable, tap, takeUntil, startWith } from 'rxjs'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { HelpersService } from '../../services/helpers/helpers.service'
import { User } from '../../models/user.model'
import { ShoutlyEidTransactionResponse } from '../../auth/models/auth.model'
import { UserService } from '../../services/user/user.service'
import { StoreService } from '../../services/store/store.service'
import { OrganizationsService } from '../../services/organizations/organizations.service'
import { Organization } from '../../models/organization.model'
import { ActivatedRoute } from '@angular/router'

@Component({
  selector: 'shared-ssn-form',
  templateUrl: './ssn-form.component.html',
  styleUrls: ['./ssn-form.component.scss']
})
export class SsnFormComponent implements OnInit, OnDestroy {
  @Input() validatableEntity: 'user' | 'org' = 'user'
  @Output() valueChange = new EventEmitter<FormGroup>()

  constructor(
    private helpersService: HelpersService,
    private userService: UserService,
    private orgService: OrganizationsService,
    private dialog: MatDialog,
    private storeService: StoreService,
    private activatedRoute: ActivatedRoute
  ) {
    this.fakeSSN = this.activatedRoute.snapshot.queryParams['fakeSSN']
  }

  public sendOTPButtonConfig = this.helpersService.getButtonConfig(_('Validate'))
  private entity$: Observable<User | Organization>
  public entity: User | Organization
  private destroy$: Subject<void> = new Subject()
  public get isButtonDisabled () {
    return false
  }

  private ssnVisible = false
  private fakeSSN = null

  public toggleVisibility () {
    this.ssnVisible  =!this.ssnVisible
  }

  public get displayablePersonalNumber() {
    const personal_number = this.form.get('personal_number').value;
  
    // Check if personal_number exists and has the correct length
    if (personal_number && personal_number.length === 12) {
      if (this.ssnVisible) {
        return `${personal_number.slice(0, 8)}-${personal_number.slice(8)}`;
      } else {
        return `${personal_number.slice(0, 8)}-****`;
      }
    }
  
    // Return the original value if it doesn't meet the expected format
    return personal_number;
  }

  ngOnInit(): void {
    if (this.validatableEntity === 'user') {
      this.entity$ = this.storeService.user$
        .pipe(
          takeUntil(this.destroy$),
          tap(user => this.helpersService.patchValues(this.form, user))
        )
    }
    if (this.validatableEntity === 'org') {
      this.entity$ = this.storeService.organization$
        .pipe(
          takeUntil(this.destroy$),
          tap(org => this.helpersService.patchValues(this.form, org))
        )
    }

    this.entity$
      .pipe()
      .subscribe({
        next: (entity) => {
          this.entity = entity
        }
      })

    this.form.valueChanges
      .pipe(
        startWith(this.form),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        console.log('form ssn', this.form)
        this.valueChange.emit(this.form)})
  }

  ngOnDestroy() {
    this.destroy$.next()
    this.destroy$.complete()
  }

  public form = new FormGroup({
    personal_number: new FormControl('', [Validators.required]) // , [Validators.required, Validators.minLength(12), Validators.maxLength(12)]
  })

  // open dialog with qr code to be authorized that closes when authorized
  private openDialog(): MatDialogRef<BankidDialogComponent> {
    return this.dialog.open(BankidDialogComponent, {
      data: {}
    })
  }

  public validate() {
    let fakeSSN = this.fakeSSN

    const dialogRef = this.openDialog()

    this.sendOTPButtonConfig.isLoading = true
    this.sendOTPButtonConfig.isDisabled = true

    const service = this.validatableEntity === 'user' ? this.userService : this.orgService
    
    service.eidSSNStartAndCheckTransaction(fakeSSN)
      .pipe(
        takeUntil(dialogRef.afterClosed())
      )
      .subscribe({
        next: (response: ShoutlyEidTransactionResponse) => {
          dialogRef.componentInstance.data.eidTransaction = response
          if(response.status === 'failed'){
            this.form.get('personal_number').setErrors({ serverError:' Transaction failed' })
          }
        },
        error: (err) => {
          dialogRef.close()
          this.form.get('personal_number').setErrors({ serverError: err.error?.message })
          this.sendOTPButtonConfig.isLoading = false
          this.sendOTPButtonConfig.isDisabled = false
        },
        complete: () => {
          dialogRef.close()
          this.validatableEntity === 'user' ? this.getUser() : this.getOrg()
          this.form.get('personal_number').setErrors(null)
          this.sendOTPButtonConfig.isLoading = false
          this.sendOTPButtonConfig.isDisabled = false
        }
      })
  }

  private getUser() {
    return this.userService.getUser()
  }

  private getOrg() {
    return this.orgService.getOrganization()
      .subscribe()
  }

}
