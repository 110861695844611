import { Component, Inject, } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

@Component({
  selector: 'shared-privacy-preferences-dialog',
  templateUrl: './privacy-preferences-dialog.component.html',
  styleUrl: './privacy-preferences-dialog.component.scss'
})
export class PrivacyPreferencesDialogComponent {
  preferences: any;

  constructor(
    public dialogRef: MatDialogRef<PrivacyPreferencesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.preferences = { ...data.preferences }; // Clone the preferences to avoid direct mutation
  }

  onAllowAll() {
    this.preferences = {
      marketing: true,
      personalization: true,
      analytics: true
    };
    this.dialogRef.close({ action: 'allow', preferences: this.preferences });
  }

  onDenyAll() {
    this.preferences = {
      marketing: false,
      personalization: false,
      analytics: false
    };
    this.dialogRef.close({ action: 'deny', preferences: this.preferences });
  }

  onSubmitPreferences() {
    this.dialogRef.close({ action: 'submit', preferences: this.preferences });
  }
}