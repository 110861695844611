<form [formGroup]="phoneNumberForm">
    <div class="phone-input-row">
        <div class="phone-input-prefix">
            <mat-form-field>
                <mat-label>{{'prefix' | translate | titlecase}}</mat-label>
                <mat-select
                #prefixMenu="matSelect"
                formControlName="prefixPhone" placeholder="select" #singleSelect>
                    <mat-option>
                        <ngx-mat-select-search 
                            [formControl]="prefixPhoneFilterCtrl"
                            [placeholderLabel]="'Search' | translate"
                            [noEntriesFoundLabel]="'No results' | translate"
                            type='text'
                        ></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let option of filteredPrefix$ | async" [value]="option.key">
                        <ng-container *ngIf="prefixMenu.panelOpen">
                            (+{{option.key}}) {{option.value?.emoji}} {{option.value?.name}}
                        </ng-container>
                        <ng-container *ngIf="!prefixMenu.panelOpen">
                            + {{option.value?.phone}}
                        </ng-container>
                    </mat-option>
                </mat-select>
                <mat-error></mat-error>
               
            </mat-form-field>
        </div>
        
        <div class="phone-input-suffix">
            <mat-form-field>
                <mat-label>{{'phone' | translate | titlecase}}</mat-label>
                <input 
                    type="text" 
                    [maxlength]="(numberPlaceholder?.length - phoneNumberForm.value.prefixPhone?.length) + 3"
                    [minlength]="numberPlaceholder?.length - 5" 
                    matInput
                    (keyup.enter)="onEnter.emit()"
                    formControlName="suffixPhone" 
                    [placeholder]="numberPlaceholder?.slice(2, numberPlaceholder?.length)"
                    oninput="value=value.replace(/[^\d]/,'')"
                >
                <mat-icon matSuffix *ngIf="isVerified">
                    <span 
                    class="material-icons-outlined" 
                    [matTooltip]="'Your phone has been validated' | translate">
                        verified
                    </span>
                </mat-icon>
                <mat-error>{{validationErrors?.serverError}}</mat-error>
            </mat-form-field>
        </div>
    </div>
</form>