import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'dashtail'
})
export class DashtailPipe implements PipeTransform {
  transform (value: string): any {
    const array: string[] = value.split('/')
    const last: string = array[array.length - 1]
    return last
  }
}
