import { Inject, Injectable } from '@angular/core'
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http'
import { Observable } from 'rxjs'
import { CookieService } from 'ngx-cookie-service'
import { TokenService } from '../services/token/token.service'

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor (
    @Inject('ENVIRONMENT') private environment: any,
    private tokenService: TokenService,
    private cookieService: CookieService
  ) { }

  intercept (request: HttpRequest<JSON>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = this.addToken(request)
    return next.handle(request)
  }

  private getMsClarityId () : string | null {
    const msClarityCookieData = this.cookieService.get('_clck')
    if (!msClarityCookieData) {
      return null
    }
    const res = msClarityCookieData.split('|')[0]
    return res || null
  }

  private addToken (request: HttpRequest<JSON>) {
    const apiUrl = this.environment.apiUrl

    // inject the token into the request header
    // ONLY if the request is to the API

    if (request.url.includes(apiUrl)) {
      const token = this.tokenService.getToken()

      if (token) {
        const headers = {
          Authorization: `Bearer ${token}`
        }

        const msClarityId = this.getMsClarityId()
        if (msClarityId) {
          headers['clarity-user-id'] = msClarityId
        }

        request = request.clone({
          setHeaders: headers
        })
      }
    }

    return request
  }
}
