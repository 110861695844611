import { Component, ElementRef, Inject, ViewChild } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { AgreementDialogData } from '../../services/user-agreements/user-agreements.service'

@Component({
  selector: 'app-agreement-dialog',
  templateUrl: './agreement-dialog.component.html',
  styleUrls: ['./agreement-dialog.component.scss']
})
export class AgreementDialogComponent {
  @ViewChild('scroll', { read: ElementRef }) public scroll: ElementRef<any>

  constructor (
    public dialogRef: MatDialogRef<AgreementDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public agreement: AgreementDialogData
  ) {}
}
