<form [formGroup]="form" *ngIf="(user$ | async) as user">
    <mat-form-field>
        <mat-label>{{'email' | translate | titlecase}}</mat-label>
        <input type="text" id="user_email" matInput formControlName="email" placeholder="me@shoutly.com">
        <mat-icon matSuffix *ngIf="form.get('email').value === user.email">
            <span
                *ngIf="!!user.email_verified_at"
                class="material-icons-outlined"
                matTooltip="{{ 'Your email has been validated'| translate }}">
                verified
            </span>
            <span
                *ngIf="!user.email_verified_at"
                class="material-icons-outlined"
                matTooltip="{{ 'Your email is not validated'| translate }}">
                warning
            </span>
        </mat-icon>
        <mat-error>{{form.get('email').errors?.serverError}}</mat-error>
    </mat-form-field>
    <ng-container *ngIf="form.get('email').value !== user.email">
        <button
        style="width: 100%;"
        type="submit" 
        mat-stroked-button
        color="primary"
        (click)="saveEmail()">
            {{ 'save'}}
        </button>
    </ng-container>
    <ng-container *ngIf="!user.email_verified_at && form.get('email').value === user.email">
        <button
        style="width: 100%;"
        *ngIf="verifyEmailButtonConfig as conf"
        type="submit" 
        mat-stroked-button
        color="primary"
        [disabled]="conf.isDisabled"
        (click)="verifyEmail()">
            <ng-container *ngIf="conf.isLoading">
                {{ ('loading' | translate | titlecase) + '...' }}
            </ng-container>
            <ng-container *ngIf="!conf.isLoading">
                {{ conf.disabledSecondsRemaining ? '(' + conf.disabledSecondsRemaining + 's)' : null }} {{ conf.label }}
            </ng-container>
        </button>
    </ng-container>
    <div style="text-align: center; padding-top: 10px;">
        <a (click)="resetPassword()" style="cursor: pointer; text-decoration: underline;">{{ 'reset password' | translate | titlecase }}</a>
    </div>
</form>