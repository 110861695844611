import { Injectable } from '@angular/core'
import { ApiService } from '../api/api.service'
import { Observable, catchError, switchMap, take, tap } from 'rxjs'
import { OrganizationsService } from '../organizations/organizations.service'
import { Organization } from '../../models/organization.model'
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker'
import { MatSnackBar } from '@angular/material/snack-bar'

@Injectable({
  providedIn: 'root'
})
export class ExternalFortnoxService {
  constructor (
    private apiService: ApiService,
    private organizationsService: OrganizationsService,
    private snackBar: MatSnackBar
  ) { }

  public pushFortnoxAuthCode (code, redirectUri): Observable<Organization> {
    return this.apiService.pushFortnoxAuthCode(code, redirectUri)
      .pipe(
        catchError(err => {
          console.log('the error is: ', err)
          throw new Error(err)
        }),
        switchMap(() => this.organizationsService.getOrganization()
        // tap(() => this.snackBar.open(_('Fortnox integration success'), null, { panelClass: ['shoutly-snack-bar', 'success'] })),
      ))
  }
}
