<ng-container *ngIf="providers?.length > 0">
    <div class="provider-select" *ngFor="let provider of providers" [ngClass]="provider?.viewName | lowercase"
        (click)="emitProvider(provider?.name)">
        <div class="item-icon" [inlineSVG]="provider?.icon" [setSVGAttributes]="changeAttrs">
        </div>
        <div class="item-content">
            <h3>{{provider?.viewName | translate}}</h3>
            <span class="secondary-text">{{provider?.description | translate}}</span>
        </div>
        <div class="next-arrow">
            <button mat-icon-button color="primary">
                <mat-icon>
                    navigate_next
                </mat-icon>
            </button>
        </div>
    </div>
</ng-container>