import { DataService, ShoutlyRolesData } from '../../services/data/data.service'
import { DatePipe } from '@angular/common'
import { Component, Inject, OnInit } from '@angular/core'
import { FormControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { Observable, debounceTime, map, of, switchMap, take, tap } from 'rxjs'
import * as lodash from 'lodash'
import { TableFilterTag } from '../../models/filters.model'
import { TableFiltersService } from '../../services/table-filters/table-filters.service'
import { DepartmentsService } from '../../services/departments/departments.service'
import { Department } from '../../models/departments.model'
import { PaginatedResponse } from '../../models/paginated.model'

export interface DialogFiltersData {
  filterTags: TableFilterTag[]
  filterTypes: string[]
  orderByValues?: string[],
  context: string
}

@Component({
  selector: 'shared-dialog-filters-for-tables',
  templateUrl: './dialog-filters-for-tables.component.html',
  styleUrls: ['./dialog-filters-for-tables.component.scss']
})
export class DialogFiltersForTablesComponent implements OnInit {
  constructor (
    public dialogRef: MatDialogRef<DialogFiltersForTablesComponent>,
    private datepipe: DatePipe,
    public tableFiltersService: TableFiltersService,
    private departmentsService: DepartmentsService,
    private dataService: DataService,
    @Inject(MAT_DIALOG_DATA) public data: DialogFiltersData
  ) { }

  public monthAndYearControl = new FormControl<string>('')

  public fromAndToControl = new UntypedFormGroup({
    from: new FormControl(null),
    to: new FormControl(null)
  })

  public filterTags: TableFilterTag[] = []

  public filtersForm = new UntypedFormGroup({
    org_id: new UntypedFormControl(null),
    department_id: new UntypedFormControl(null),
    role: new UntypedFormControl(null),
    period: new UntypedFormControl(null),
    orderby: new UntypedFormControl(null),
    sort: new UntypedFormControl(null),
    month: new UntypedFormControl(null),
    year: new UntypedFormControl(null),
    currency: new UntypedFormControl(null),
    type: new UntypedFormControl(null),
    from: new UntypedFormControl(null),
    to: new UntypedFormControl(null),
    collab_status: new UntypedFormControl(null)
  })

  departmentsList: Department[] = null
  rolesList: ShoutlyRolesData[] = []

  collab_statuses = ['sent', 'accepted', 'declined', 'cancelled', 'ongoing', 'finished'] // ['all', 'pending', 'active', 'action_required', 'finished']

  ngOnInit (): void {
    // this.setDefaults()
    // When filtersForm change, update filterTags
    this.filtersForm.valueChanges
      .pipe(
        // Remove undefined values
        map(filterOptions => lodash.omitBy(filterOptions, lodash.isNil)),
        // Convert filterOptions to filterTags
        map(filterOptions => {
          const filters = []

          // Convert filterOptions to filterTags
          Object.entries(filterOptions).forEach(([key, value]) => {
            // value it's array because it's a multi-select
            if (Array.isArray(value)) {
              for (const v of value) {
                const partial: TableFilterTag = {
                  id: v,
                  type: key
                }
                filters.push(partial)
              }
            } else {
              const partial: TableFilterTag = {
                id: value,
                type: key
              }
              filters.push(partial)
            }
          })

          return filters
        })
      )
      .subscribe((filterTags: TableFilterTag[]) => {
        this.filterTags = filterTags
      })

    this.getElements()
  }

  private getElements () {
    return of({})
      .pipe(
        switchMap(() => this.getDepartments()),
        switchMap(() => this.getRoles())
      )
      .subscribe({
        next: () => {
          this.patchValues()
        }
      })
  }

  private patchValues () {
    this.tableFiltersService.filtersObservable
      .pipe(
        debounceTime(0),
        take(1),
        map((filters: TableFilterTag[]) => filters || []),
        map((filters: TableFilterTag[]) => {
          const filtersSeparated = this.separateOrgIdFilters(filters)
          return this.groupOrgIdFilters(filtersSeparated)
        })
      )
      .subscribe(filters => {
        // for each filter, patch the value of "type" on the form
        filters.forEach(filter => {
          if (this.filtersForm.get(filter.type)) {
            this.filtersForm.get(filter.type).patchValue(filter.id)
          }
        })
      })
  }

  private separateOrgIdFilters (filters: TableFilterTag[]): TableFilterTag[] {
    return filters.filter(filter => filter.type !== 'org_id')
  }

  private groupOrgIdFilters (filters: TableFilterTag[]): TableFilterTag[] {
    // Get the filters that are org_id and group values in format {type: 'org_id', id: [1,2,3]}
    const typeOrgId = filters.filter(filter => filter.type === 'org_id')
    const typeOrgIdGrouped: TableFilterTag = {
      type: 'org_id',
      id: typeOrgId.map(filter => filter.id).flat() // Flatten the array here
    }
    const typeOrgIdSeparated = filters.filter(filter => filter.type !== 'org_id')
    let res: TableFilterTag[] = []
    // If there are typeOrgId, group their values in an array
    if (typeOrgId.length > 0) {
      // Remove the type org_id from the filters
      filters = filters.filter(filter => filter.type !== 'org_id')
      // Group org_id values in an array and push it to the filters
      res.push(typeOrgIdGrouped)
    }
    // Add the rest of the filters
    res = res.concat(typeOrgIdSeparated)
    return res
  }

  /** Set the first element on the orderby values */
  private setDefaultOrderByValue () {
    if (this.data.filterTypes && this.data.filterTypes.includes('orderby')) {
      this.filtersForm.patchValue({
        orderby: this.data.orderByValues && this.data.orderByValues[0]
      })
    }
  }

  private setDefaultSortValue () {
    if (this.data.filterTypes && this.data.filterTypes.includes('sort')) {
      this.filtersForm.patchValue({
        sort: 'asc'
      })
    }
  }

  public onSortByChange () {
    // if orderby is not set, assign the default value
    if (!this.filtersForm.get('orderby').value) {
      this.setDefaultOrderByValue()
    }
  }

  public onOrderByChange () {
    // if sort is not set, assign the default value
    if (!this.filtersForm.get('sort').value) {
      this.setDefaultSortValue()
    }
  }

  public setMonthAndYear (event: string, dp) {
    const monthValue = this.datepipe.transform(event, 'M')
    const yearValue = this.datepipe.transform(event, 'yyyy')

    if (monthValue && yearValue) {
      this.filtersForm.patchValue({
        month: monthValue,
        year: yearValue
      })

      this.monthAndYearControl.setValue(`${monthValue}/${yearValue}`)
    } else {
      this.monthAndYearControl.setValue('')
    }

    dp.close()
  }

  public setFromAndToDates () {
    const filters = this.fromAndToControl.value

    const date_from = new Date(filters.from)
    const date_to = new Date(filters.to)

    if (isNaN(date_from.getTime()) || isNaN(date_to.getTime())) {
      return
    }

    const from = this.datepipe.transform(date_from, 'yyyy-MM-dd')
    const to = this.datepipe.transform(date_to, 'yyyy-MM-dd')
    this.filtersForm.patchValue({ from, to })
  }

  private getDepartments (): Observable<PaginatedResponse<Department[]>> {
    const page: string = '1'
    return this.departmentsService.getDepartments({ page })
      .pipe(
        tap((res: PaginatedResponse<Department[]>) => {
          this.departmentsList = res.data
        })
      )
  }

  public getRoles () {
    const data = this.dataService.getRolesData()

    return of(data)
      .pipe(
        tap((res: ShoutlyRolesData[]) => {
          this.rolesList = res
        }
        ))
  }
}
