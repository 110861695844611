import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { CommonConfirmDialogBoxComponent } from './common-confirm-dialog-box/common-confirm-dialog-box.component'
import { ConfirmActionDialogComponent } from '../ui/components/confirm-action-dialog/confirm-action-dialog.component'
import { BankidDialogComponent } from './bankid-dialog/bankid-dialog.component'
import { PrivacyPreferencesDialogComponent } from './privacy-preferences-dialog/privacy-preferences-dialog.component'
import { MaterialModule } from '../material.module'
import { TranslateModule } from '@ngx-translate/core'
import { NgPipesModule } from 'ngx-pipes'
import { QrCodeModule } from 'ng-qrcode'
import { CustomScrollDirective } from '../directives/custom-scroll.directive'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { DatePickerDialogComponent } from './date-picker-dialog/date-picker-dialog.component'
import { SingleInputDialogComponent } from './single-input-dialog/single-input-dialog.component'
import { AgreementDialogComponent } from './agreement-dialog/agreement-dialog.component'

@NgModule({
  declarations: [
    CommonConfirmDialogBoxComponent,
    ConfirmActionDialogComponent,
    AgreementDialogComponent,
    BankidDialogComponent,
    PrivacyPreferencesDialogComponent,
    CustomScrollDirective,
    DatePickerDialogComponent,
    SingleInputDialogComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    TranslateModule.forChild(),
    NgPipesModule,
    QrCodeModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    CommonConfirmDialogBoxComponent,
    ConfirmActionDialogComponent,
    AgreementDialogComponent,
    BankidDialogComponent,
    PrivacyPreferencesDialogComponent,
    DatePickerDialogComponent,
    SingleInputDialogComponent
  ]
})
export class DialogsModule { }
