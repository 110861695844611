export interface UiState {
  viewportType: ViewportType
}

export enum SidebarState {
  Opened = 'opened',
  Collapsed = 'collapsed',
  Closed = 'closed'
}

export enum ViewportType {
  Mobile = 'mobile',
  Tablet = 'tablet',
  Desktop = 'desktop'
}

