<h2 mat-dialog-title>
   {{ data.context | translate | uppercase }} {{ 'filters' | translate | uppercase }}
</h2>
<mat-dialog-content>
    <mat-form-field 
        *ngIf="data.filterTypes?.includes('month')"
       >
        <mat-label translate>Month and Year</mat-label>
            <input matInput [formControl]="monthAndYearControl">
            <input matInput [hidden]="true" [matDatepicker]="dp">
            <mat-hint *ngIf="monthAndYearControl.invalid">MM/YYYY</mat-hint>
            <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
            <mat-datepicker #dp
                            min="2020"
                            startView="year"
                            (monthSelected)="setMonthAndYear($event, dp)">
            </mat-datepicker>
    </mat-form-field>

    <mat-form-field 
        *ngIf="data.filterTypes?.includes('from') && data.filterTypes?.includes('to')"
       >
        <mat-label translate>From and To</mat-label>
        <mat-date-range-input [formGroup]="fromAndToControl" [rangePicker]="fromAndToPicker">
            <input matStartDate formControlName="from">
            <input matEndDate formControlName="to">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="fromAndToPicker"></mat-datepicker-toggle>
        <mat-date-range-picker 
            #fromAndToPicker
            (closed)="setFromAndToDates()">
        </mat-date-range-picker>
    </mat-form-field>

    <form [formGroup]="filtersForm">
        <ng-container *ngIf="tableFiltersService.filtrableOrgsObservable | async as ORGS">
            <mat-form-field 
            *ngIf="data.filterTypes?.includes('org_id') && ORGS.length > 1">
                <mat-label>{{ 'organization' | translate | titlecase }}</mat-label>
                <mat-select formControlName="org_id" multiple>
                    <mat-option *ngFor="let org of ORGS" [value]="org.id">
                        {{ org.id }}) {{ org.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </ng-container>

        <mat-form-field
            *ngIf="data.filterTypes?.includes('department_id') && departmentsList?.length">
            <mat-label>{{ 'department' | translate | titlecase }}</mat-label>
            <mat-select formControlName="department_id">                
                <mat-option *ngFor="let dpt of departmentsList" [value]="dpt.id">
                    {{ dpt.id }}) {{ dpt.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field
            *ngIf="data.filterTypes?.includes('role')">
            <mat-label>{{ 'role' | translate | titlecase }}</mat-label>
            <mat-select formControlName="role" (opened)="getRoles()">
                <mat-option *ngIf="!rolesList?.length">
                    -
                    <!-- <mat-progress-bar color="primary" mode="indeterminate"></mat-progress-bar> -->
                </mat-option>
                
                <mat-option *ngFor="let role of rolesList" [value]="role.slug">
                    {{ role.slug | titlecase }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field
            *ngIf="data.filterTypes?.includes('orderby')" 
           >
            <mat-label>{{ 'order by' | translate | titlecase }}</mat-label>
            <mat-select formControlName="orderby" (selectionChange)="onOrderByChange()">
                <mat-option *ngFor="let order of data.orderByValues" [value]="order">
                    {{ order.replace('_', ' ') | titlecase }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field *ngIf="data.filterTypes?.includes('sort')">
            <mat-label>{{ 'sort by' | translate | titlecase }}</mat-label>
            <mat-select formControlName="sort" (selectionChange)="onSortByChange()">
                <mat-option [value]="'asc'">{{ 'ascending' | translate | titlecase }}</mat-option>
                <mat-option [value]="'desc'">{{ 'descending' | translate | titlecase }}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field *ngIf="data.filterTypes?.includes('collab_status')">
            <mat-label>{{ 'State' | translate | titlecase }}</mat-label>
            <mat-select formControlName="collab_status">
                <mat-option *ngFor="let state of collab_statuses" [value]="state">{{ state.replace('_', ' ') | translate | titlecase }}</mat-option>
            </mat-select>
        </mat-form-field>
    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-stroked-button color="primary" [mat-dialog-close]="false">{{ 'cancel' | translate | uppercase }}</button>
    <button mat-flat-button color="primary" [mat-dialog-close]="filterTags" cdkFocusInitial>{{ 'apply' | translate | uppercase }}</button>
</mat-dialog-actions>