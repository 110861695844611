import { DatePipe } from '@angular/common'
import { Injectable } from '@angular/core'
import { ActivatedRoute, Params, Router } from '@angular/router'
import { BehaviorSubject, map, Observable, tap, mergeMap, filter } from 'rxjs'
import { TableFilterTag } from '../../models/filters.model'
import * as lodash from 'lodash'
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker'
import { MatDialog } from '@angular/material/dialog'
import { DataService } from '../data/data.service'
import { DialogFiltersForTablesComponent } from '../../components/dialog-filters-for-tables/dialog-filters-for-tables.component'

// Query Params to take from URL
const ALLOWED_QUERY_PARAMS = ['org_id', 'month', 'year', 'from', 'to', 'department_id', 'role', 'orderby', 'sort', 'collab_status', 'search']

interface FilterConfiguration {
  context: string
  filterTypes: string[]
  orderByValues?: string[]
  searchableTerms?: string[]
}

export const FILTER_CONFIGURATIONS: FilterConfiguration[] = [
  {
    context: 'collab',
    filterTypes: ['proposals', 'filter', 'sort', 'orderby', 'status', 'department_id', 'date_from', 'date_to', 'org_id', 'search'],
    orderByValues: ['id', 'created_at', 'date_end', 'date_start'],
    searchableTerms: [_('id'), _('title'), _('counterpart name'), _('counterpart id')],
  },
  {
    context: 'invoices',
    filterTypes: ['orderby', 'sort', 'from', 'to', 'search'],
    orderByValues: ['id', 'amount', 'currency', 'paid_at', 'created_at', 'updated_at', 'due_date'],
    searchableTerms: [_('id'), _('fortnox id')]
  },
  {
    context: 'time-sheets',
    filterTypes: ['orderby', 'sort', 'gigger_id', 'employer_id', 'org_id', 'limit', 'page', 'month', 'year', 'search'],
    orderByValues: ['id', 'month', 'year', 'created_at', 'updated_at', 'state', 'hours'],
    searchableTerms: [_('collab title')]
  },
  {
    context: 'departments',
    filterTypes: ['orderby', 'sort', 'search'],
    orderByValues: ['id', 'name', 'created_at'],
    searchableTerms: [_('id'), _('name')]
  },
  {
    context: 'expenses',
    filterTypes: ['orderby', 'sort', 'gigger_id', 'org_id', 'currency', 'state', 'search'],
    orderByValues: ['id', 'amount', 'vat', 'currency', 'date', 'merchant', 'collab_id', 'description', 'created_at', 'updated_at', 'state'],
    searchableTerms: [_('id'), _('description'), _('merchant'), _('collab id'), _('collab title')]
  },
  {
    context: 'transactions',
    filterTypes: ['orderby', 'sort', 'from', 'to', 'selfinvoice_id', 'org_id', 'from_balance', 'cost_center_id', 'search'],
    orderByValues: ['id', 'org_id', 'collab_id', 'type', 'amount', 'netto', 'currency', 'paid_at', 'cancelled'],
    searchableTerms: [_('id'), _('collab id'), _('collab title')]
  },
  {
    context: 'team-members',
    filterTypes: ['role', 'department_id', 'orderby', 'sort', 'search'],
    orderByValues: ['id', 'first_name', 'last_name', 'created_at', 'updated_at']
  },
  {
    context: 'invited-members',
    filterTypes: ['orderby', 'sort', 'search'],
    orderByValues: ['id', 'email', 'first_name', 'last_name', 'position', 'status']
  },
  {
    context: 'self-invoices',
    filterTypes: ['orderby', 'sort', 'from', 'to', 'search'],
    orderByValues: ['total', 'subtotal', 'updated_at', 'paid_at', 'created_at'],
    searchableTerms: [_('id')]
  },
  {
    context: 'partners',
    filterTypes: ['orderby', 'sort', 'search'],
    orderByValues: ['id', 'name', 'created_at', 'updated_at'],
    searchableTerms: [_('name')]
  }
]

@Injectable({
  providedIn: 'root'
})
export class TableFiltersService {

  private filtersSubject = new BehaviorSubject<TableFilterTag[]>(this.extractUrlFilterParams())
  public filtrableElementsSubject = new BehaviorSubject<TableFilterTag[]>([])

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private datepipe: DatePipe,
    private dataService: DataService,
    private dialog: MatDialog
  ) { }

  /**
   * Public Observables
   */
  public get filtersObservable(): Observable<TableFilterTag[]> {
    return this.filtersSubject.asObservable()
      .pipe(
        mergeMap(filters => this.filtrableOrgsObservable.pipe(
          map(elements => filters.map(filter => this.enrichFilter(filter, elements)))
        )),
        map(filters => filters.map(filter => this.assignFilterName(filter))),
        tap(tags => this.applyFiltersToUrlParams(tags))
      )
  }

  public get filterParamsObservable(): Observable<Params> {
    return this.filtersSubject.asObservable()
      .pipe(
        map(filters => this.formatFiltersForCollabReport(filters)),
        tap(data => console.log(data))
      )
  }

  public get filtrableOrgsObservable() {
    return this.filtrableElementsSubject.pipe(
      map(tags => tags.filter(tag => tag && tag.type === 'org_id'))
    )
  }

  public get addFiltrableOrg(): TableFilterTag[] {
    return this.filtrableElementsSubject.value.filter(tag => tag.type === 'org_id')
  }

  public set addFiltrableOrg(tags: TableFilterTag[]) {
    if (!tags) return
    const uniqueTags: TableFilterTag[] = lodash.uniqBy([...this.filtrableElementsSubject.value, ...tags], 'id')
    const filteredTags: TableFilterTag[] = uniqueTags.filter((tag) => tag.id !== 'all')
    this.filtrableElementsSubject.next(filteredTags)
  }

  /**
   * Public Methods
   */
  public updateTags(tags: TableFilterTag[]) {
    this.applyFiltersToUrlParams(tags)
      .then(() => this.filtersSubject.next(tags))
  }

  public insertSearchTag(tag: TableFilterTag): void {
    const tags = this.filtersSubject.value
    const index = tags.findIndex(t => t.type === 'search')
    if (index !== -1) tags.splice(index, 1)
    this.insertTag(tag)
  }

  public insertTag(tag: TableFilterTag): void {
    const tags = this.filtersSubject.value
    if (!tags.some(t => t.id === tag.id)) tags.push(tag)
    this.filtersSubject.next(tags)
  }

  public deleteTagByType(type: string): void {
    const tags = this.filtersSubject.value
    const index = tags.findIndex(t => t.type === type)
    if (index !== -1) tags.splice(index, 1)
    this.filtersSubject.next(tags)
  }

  public broadcastFilters() {
    this.filtersSubject.next(this.filtersSubject.value)
  }

  public isContextValid(context: string): boolean {
    const filterContext = FILTER_CONFIGURATIONS.find(f => f.context === context)
    if (!filterContext) throw new Error(`No context found for: ${context}. Please check the FILTER_CONFIGURATIONS constant.`)
    return filterContext.filterTypes.length >= 1
  }

  public showFiltersDialog(context: string): void {
    const filterContext = FILTER_CONFIGURATIONS.find(f => f.context === context)
    if (!filterContext) throw new Error(`No context found for: ${context}. Please check the FILTER_CONFIGURATIONS constant.`)
    const data = {
      filterTypes: filterContext.filterTypes,
      filterTags: this.filtersSubject.value,
      orderByValues: filterContext.orderByValues,
      context: filterContext.context
    }
    const dialogRef = this.dialog.open(DialogFiltersForTablesComponent, {
      width: '510px',
      data
    })
    dialogRef.afterClosed()
      .pipe(filter((filterOptions: TableFilterTag[]) => !!filterOptions))
      .subscribe((filterOptions: TableFilterTag[]) => this.updateTags(filterOptions))
  }

  /**
   * Private Methods
   */

  private extractUrlFilterParams(): TableFilterTag[] {
    const params = this.retrieveFiltersFromUrl()
    return this.mapParamsToFilterTags(params)
  }

  private applyFiltersToUrlParams(tags: TableFilterTag[]): Promise<boolean> {
    const currentTags = this.extractUrlFilterParams()
    const newQueryParams = this.formatFiltersForCollabReport(tags)
    const removedParams = this.identifyRemovedTags(tags, currentTags)
    removedParams.forEach(tag => newQueryParams[tag.type] = null)
    const urlTree = this.router.createUrlTree([], {
      queryParams: newQueryParams,
      queryParamsHandling: 'merge',
      preserveFragment: true
    })
    return this.router.navigateByUrl(urlTree)
  }

  private identifyRemovedTags(tags: TableFilterTag[], currentTags: TableFilterTag[]): TableFilterTag[] {
    return currentTags.filter(currentTag => !tags.some(tag => tag.id === currentTag.id && tag.type === currentTag.type))
  }

  public fetchSearchableTerms(type: string): string[] | undefined {
    const filterContext = FILTER_CONFIGURATIONS.find(context => context.context === type)
    return filterContext?.searchableTerms
  }

  private deriveFilterTagName(tag: TableFilterTag): string {
    const locale = this.getLocale()

    let res: string = ''

    const id = Array.isArray(tag.id) ? tag.id[0] : tag.id

    if (typeof id === 'string' || typeof id === 'number') {
      switch (tag.type) {
        case 'org_id':
          res = tag.name ? tag.name : 'Org ' + id
          break
        case 'month':
          res = this.datepipe.transform(new Date().setMonth(parseInt(id as string) - 1), 'LLLL', undefined, locale)
          break
        case 'year':
          res = this.datepipe.transform(new Date().setFullYear(parseInt(id as string)), 'yyyy')
          break
        case 'from':
          res = _('from') + ' ' + this.datepipe.transform(new Date(id as string), 'shortDate', undefined, locale)
          break
        case 'to':
          res = _('to') + ' ' + this.datepipe.transform(new Date(id as string), 'shortDate', undefined, locale)
          break
        case 'collab_status':
          res = _('state') + ': ' + id
          break
        case 'search':
          res = _('search') + ': ' + id
          break
        default:
          res = tag.type + ': ' + id
          break
      }
    }

    return res.toString()
  }


  private retrieveFiltersFromUrl(): Params {
    const queryParams = this.route.snapshot.queryParams
    return Object.keys(queryParams).reduce((res, key) => {
      if (ALLOWED_QUERY_PARAMS.includes(key)) res[key] = queryParams[key]
      return res
    }, {} as Params)
  }

  private mapParamsToFilterTags(params: Params): TableFilterTag[] {
    return Object.entries(params).flatMap(([key, value], i) =>
      Array.isArray(value) ? value.map((v, k) => ({ type: key, id: v || `filter${k}` }))
        : [{ type: key, id: value || `filter${i}` }]
    )
  }

  private formatFiltersForCollabReport(tags: TableFilterTag[]): Params {
    return this.groupParamsByKey(tags.map(tag => ({ [tag.type]: tag.id })))
  }

  /* This function takes an array of params and converts it into a more easily readable and usable format.
  It takes an array of params, and returns a single object with the keys of the params grouped together.
  If there are multiple values for a key, it creates an array of the values.
  If there is only one value for a key, it puts the value in the array. */
  private groupParamsByKey(params: Params[]): Params {
    const result: Params = {}
    const keys = this.extractKeysFromParams(params)
    const uniqueKeys = this.removeDuplicateStrings(keys)
    uniqueKeys.forEach(key => {
      const values = params.flatMap(param => param[key] ? [param[key]] : [])
      result[key] = values.length > 1 ? values : values[0]
    })
    return result
  }

  private extractKeysFromParams(params: Params[]): string[] {
    return params.flatMap(Object.keys)
  }

  private removeDuplicateStrings(strings: string[]): string[] {
    return lodash.uniq(strings)
  }

  private getLocale() {
    return this.dataService.getLocale()
  }

  private assignFilterName(filter: TableFilterTag): TableFilterTag {
    if (!filter.name) filter.name = this.deriveFilterTagName(filter)
    return filter
  }

  private enrichFilter(filter: TableFilterTag, elements: any[]): TableFilterTag {
    const element = elements.find(e => e.id === filter.id && e.type === filter.type)
    return { ...filter, name: element?.name ?? filter.name, avatar: element?.avatar ?? filter?.avatar }
  }

}
