<div *ngIf="!org_payout_settings" class="empty-slate">
    <div>
        <app-blank-slate-icon [svgPath]="'assets/UI/empty-screen/empty-transaction.svg'"></app-blank-slate-icon>
        <h3 translate>{{'Error' | translate }}</h3>
        <div>
            <span translate>{{'Failed to load settings. Try signing out and login again.'}}</span>
        </div>
    </div>
</div>


<div *ngIf="org_payout_settings">
    <form [formGroup]="form">
        <div class="table-center">
            <span class="title-with-yellow">
                <h1 class="inline">
                    {{'Payout in' | translate}}
                </h1>
            </span>
    
            <h1 class="inline">
                <mat-select
                class="payout-currency-dropdown"
                id="org_payout_currency"
                data-testid="org_payout_currency"
                [value]="form.value?.currency"
                formControlName="currency"
                (selectionChange)="onCurrencyChange($event.value)"
                >
                    <mat-option 
                    *ngIf="form.value?.currency && !currencies"
                    [value]="form.value?.currency">
                        {{form.value?.currency | uppercase}}
                    </mat-option>
                    <ng-container *ngIf="currencies">
                        <mat-option
                            *ngFor="let currency of currencies"
                            [attr.data-testid]="'org_payout_currency_' + currency.value" 
                            [id]="'org_payout_currency_' + currency.value" 
                            [value]="currency.value">
                            {{(currency.viewValue | uppercase) + ' (' + currency.value + ')'}}
                        </mat-option>
                    </ng-container>
                </mat-select>
            </h1>
        </div>
    </form>

    <!-- {{org_payout.type }} -->
    <mat-accordion
        class="payment-accordion shoutly-accordion-1"
        data-testid="org_payout_payment_accordion"
    >
        <div *ngIf="isPayoutMethodLoading">
            <mat-progress-bar
            data-testid="org_payout_method_loading"
            [mode]="'indeterminate'"></mat-progress-bar>
            <br>
        </div>
    
        <!-- [expanded]="form.get('type').value === 'Swish'" -->
        <mat-expansion-panel
            *ngIf="allowedPaymentMethods.includes('Swish')"
            hideToggle
            class="mat-elevation-z0 swish"
            data-testid="org_payout_method_swish"
            [class.selected]="org_payout_settings?.type === 'Swish'"
            [expanded]="form.get('type').value === 'Swish'"
            #swishPanel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <div class="panel-title-flex">
                        <span>Swish</span>
                        <shared-preferred-chip *ngIf="org_payout_settings?.type === 'Swish'"></shared-preferred-chip>
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <div class="mat-expansion-panel-content">
                    <div *ngIf="swishPanel.expanded">
                        <shared-swish-form
                            [personalNumber]="ssn"
                            [swish]="org_payout_settings.swish"
                            [validationErrors]="validationErrors"
                            (output_form)="updateForm($event, 'Swish')"
                        ></shared-swish-form>
                        <mat-divider style="margin: 20px;"></mat-divider>
                        <div class="slide-toggle-container">
                            <mat-slide-toggle
                            class="custom-slide-toggle"
                            [color]="'primary'"
                            [checked]="org_payout.type === 'Swish'"
                            (change)="selectPayoutMethod('Swish')"
                            name="preferredMethodSwish"
                            labelPosition="before"
                            required>{{'Preferred method' | translate}}</mat-slide-toggle>
                        </div>
                        
                    </div>
                </div>
            </ng-template>
        </mat-expansion-panel>

        <!-- [expanded]="form.get('type').value === 'Bank transfer'" -->
        <mat-expansion-panel
        *ngIf="allowedPaymentMethods.includes('Bank transfer')"
        hideToggle 
        data-testid="org_payout_method_bank"
        class="mat-elevation-z0 bank"
        [class.selected]="org_payout_settings?.type === 'Bank transfer'"
        [expanded]="org_payout_settings?.type === 'Bank transfer'"
        #bankPanel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <div class="panel-title-flex">
                        <span translate>Bank account</span>
                        <shared-preferred-chip *ngIf="org_payout_settings?.type === 'Bank transfer'"></shared-preferred-chip>
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <div class="mat-expansion-panel-content">

                    <!-- [org]="sessionData?.org" -->
                    <shared-bank-settings-form
                        *ngIf="bankPanel.expanded"
                        [wise]="org_payout_settings?.wise"
                        [currency]="form.value?.currency"
                        [validationErrors]="validationErrors"
                        (output_form)="updateForm($event, 'Bank transfer')"    
                    ></shared-bank-settings-form>
                    <mat-divider style="margin: 20px;"></mat-divider>
                    <div class="slide-toggle-container">
                        <mat-slide-toggle
                        class="custom-slide-toggle"
                        [color]="'primary'" 
                        [checked]="org_payout.type === 'Bank transfer'"
                        (change)="selectPayoutMethod('Bank transfer')"
                        name="preferredMethodBankTransfer"
                        labelPosition="before"
                        required>{{'Preferred method' | translate}}</mat-slide-toggle>
                    </div>
                </div>
            </ng-template>
        </mat-expansion-panel>

        <!-- [expanded]="form.get('type').value === 'Paypal'" -->
        <mat-expansion-panel
            *ngIf="allowedPaymentMethods.includes('Paypal')"
            hideToggle
            data-testid="org_payout_method_paypal"
            class="mat-elevation-z0 paypal"
            [ngClass]="{'validationError': validationErrors?.payout?.paypal}"
            [class.selected]="org_payout_settings?.type === 'Paypal'"
            [expanded]="org_payout_settings?.type === 'Paypal'"
            #paypalPanel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <div class="panel-title-flex">
                        <span>Paypal</span>
                        <shared-preferred-chip *ngIf="org_payout_settings?.type === 'Paypal'"></shared-preferred-chip>

                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <div class="mat-expansion-panel-content">
                    <shared-paypal-form
                        *ngIf="paypalPanel.expanded"
                        [payout]="org_payout"
                        [oauthEnabled]="isCurrentEntity"
                        [validationErrors]="validationErrors"
                        (output_form)="updateForm($event, 'Paypal')"    
                    ></shared-paypal-form>
                    <div class="secondary-text">
                        <b translate>Note: </b>
                        <span translate>This payment method involves extra fee: </span>
                        <b>2%</b>
                    </div>
                    <div class="secondary-text">
                        <b translate>Note: </b>
                        <span translate>This payment method generate a transaction in SEK (Swedish Kronor).</span>
                    </div>
                    <mat-divider style="margin: 20px;"></mat-divider>
                    <div class="slide-toggle-container">
                        <mat-slide-toggle
                        class="custom-slide-toggle"
                        [color]="'primary'" 
                        [checked]="org_payout.type === 'Paypal'"
                        (change)="selectPayoutMethod('Paypal')"
                        name="preferredMethodPaypal"
                        labelPosition="before"
                        required>{{'Preferred method' | translate}}</mat-slide-toggle>
                    </div>


                </div>
            </ng-template>
        </mat-expansion-panel>
    
    </mat-accordion>
    
    <ng-template #addPersonalNumberText>
        <span class="secondary-text">
            <b translate>Note: </b>
            <span translate>To use this payment method, </span>
            <span translate>please verify </span>
            <span translate>your personal number.</span>
            <span translate>(You can find it in User TAB)</span>
        </span>
    </ng-template>
</div>
