import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { Observable } from 'rxjs'
import { CommonConfirmDialogBoxComponent } from '../../dialogs/common-confirm-dialog-box/common-confirm-dialog-box.component'

@Injectable({
  providedIn: 'root'
})
export class DialogBoxService {
  constructor (
    private dialog: MatDialog
  ) {}

  confirm (message?: string): Observable<boolean> {
    const dialogRef = this.dialog.open(CommonConfirmDialogBoxComponent, {
      width: '450px',
      data: {
        message
      }
    })

    return dialogRef.afterClosed()
  }
}
