import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core'
import { FormControl, FormGroup, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms'
import { OrganizationPayoutApiResponseV2Swish } from '../../models/organization.model'

@Component({
  selector: 'shared-swish-form',
  templateUrl: './swish-form.component.html',
  styleUrls: ['./swish-form.component.scss']
})
export class SwishFormComponent implements OnInit, OnChanges {
  @Input() swish: OrganizationPayoutApiResponseV2Swish
  @Input() validationErrors: ValidationErrors = { serverError: null }
  @Input() personalNumber: string = null
  @Output() output_form = new EventEmitter<UntypedFormGroup>()

  constructor () { }

  public form = new FormGroup({
    swish: new FormGroup({
      swish_number: new FormControl('', [Validators.required]),
      ssn: new FormControl(this.personalNumber, [Validators.required])
    }),
    // type: new FormControl('Swish')
  })

  ngOnInit (): void {

    // if(!this.personalNumber) {
    //   throw new Error("personal_number missing")
    // }

    this.patchInitValues()

    this.form.valueChanges
      .subscribe(
        () => this.emitForm(this.form)
      )
  }

  ngOnChanges (changes: SimpleChanges): void {
    if (changes.validationErrors) return this.showErrorsOnForm(changes.validationErrors.currentValue)
  }

  public updatePhoneNumber (number: string) {
    const form = this.form

    form.get('swish').get('swish_number')
      .patchValue(number)
  }

  private emitForm (form: UntypedFormGroup) {
    return this.output_form.emit(form)
  }

  private patchInitValues () {
    const swish: OrganizationPayoutApiResponseV2Swish = this.swish
    
    if (swish) {
      this.form.get('swish')
        .patchValue({
          ssn: swish.ssn,
          swish_number: swish.swish_number
        })
    } else {
      this.form.get('swish')
        .patchValue({
          ssn: this.personalNumber,
          swish_number: null
        })
    }

    this.emitForm(this.form)
  }

  private showErrorsOnForm (validationErrors: ValidationErrors) {
    if (!validationErrors) return

    const form = this.form

    form.markAllAsTouched()

    Object.keys(validationErrors).forEach(prop => {
      const formControl = form.get(prop)
      if (formControl) {
        formControl.setErrors({
          serverError: validationErrors[prop]
        })
      }
    })
  }
}
