import { Injectable } from '@angular/core'
import * as Sentry from '@sentry/browser'
import { User } from '../../models/user.model'
import { StoreService } from '../store/store.service'

@Injectable({
  providedIn: 'root'
})
export class SentryService {

  constructor(
    private storeService: StoreService
  ){
    this.storeService.user$
      .subscribe({
        next: (user: User) => this.addDataToSentry(user)
      })
  }

  /**
   * Reports an error to Sentry with a specified severity level.
   * @param error The error object to report.
   * @param level The severity level of the error (e.g., 'fatal', 'error', 'warning', 'log', 'info', 'debug').
   */
  public reportError(error: any, level: Sentry.SeverityLevel | string = 'error'): void {
    Sentry.withScope(scope => {
      scope.setTag("error-type", "network issue")  // Tagging as network issue
      scope.setLevel(level as Sentry.SeverityLevel)  // Setting the severity level

      // Adding extra context
      scope.setExtra("errorStatus", error.status)
      scope.setExtra("errorMessage", error.message)
      scope.setExtra("errorType", error.error?.err_type)
      scope.setExtra("url", error.url)

      // Capture the exception with the modified scope
      Sentry.captureException(error)
    })
  }

  private addDataToSentry(data: User) {
    if (!data || !data.id || !data.name || !data.email) {
      return Sentry.setUser(null)
    }

    Sentry.setUser({
      id: data.id.toString(),
      username: data.name,
      email: data.email
    })
  }
}
