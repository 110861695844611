<form [formGroup]="form">
    <mat-radio-group class="option-group" formControlName="selectOption" [class.error]="isError">
      <mat-radio-button
        *ngFor="let option of formList; trackBy: trackByFn"
        class="option"
        color="primary"
        [value]="option.key"
        [disabled]="disableOptions[option.key]">
        <div class="label-wrapper">
          <mat-icon class="select-icon" matPrefix fontSet="material-icons-outlined">
            {{ option.icon }}
          </mat-icon>
          <span translate>{{ option.value }}</span>
        </div>
      </mat-radio-button>
    </mat-radio-group>
</form>