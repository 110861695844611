import { Injectable } from '@angular/core'
import { WindowRefService } from '../window-ref/window-ref.service'

interface HubSpotConversations {
  widget: {
    load: () => void,
    remove: () => void
  }
}

interface Window extends globalThis.Window {
  hsConversationsSettings?: {
    loadImmediately: boolean
  }
  HubSpotConversations?: HubSpotConversations
}

@Injectable({
  providedIn: 'root'
})
export class HubspotService {
  constructor(
    private windowService: WindowRefService
  ) {}

  public async initHubspotSettings() {
    console.log('try to init HUBSPOT')
    try {
      const _window = this.windowService.nativeWindow as Window
      _window.hsConversationsSettings = { loadImmediately: true }
    }
    catch (error) {
      console.error('Failed to load HubSpot widget', error)
    }
  }

  public async loadHubspotWidget() {
    try {
      const _window = this.windowService.nativeWindow as Window
  
      if (_window.HubSpotConversations?.widget) {
        _window.HubSpotConversations.widget.load()
      } else {
        console.warn('HubSpot widget is not initialized or already loaded.')
      }
    } catch (error) {
      console.error('Failed to remove HubSpot widget', error)
    }
  }

  public async removeHubspotWidget() {
    try {
      const _window = this.windowService.nativeWindow as Window
  
      if (_window.HubSpotConversations?.widget) {
        _window.HubSpotConversations.widget.remove()
      } else {
        console.warn('HubSpot widget is not initialized or already removed.')
      }
    } catch (error) {
      console.error('Failed to remove HubSpot widget', error)
    }
  }
}
